import { PreloadingStrategy, Route } from '@angular/router';

import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

export interface RouteToPreload {
  routePath: string;
  route: Route;
  load: () => Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
export class AppRoutingPreloaderService implements PreloadingStrategy {
  private routesToPreload: RouteToPreload[] = [];

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route?.data?.preload) {
      this.routesToPreload.push({
        routePath: route.path ?? '',
        route,
        load
      });
    }

    return of(null);
  }

  async preloadRoute(path: string): Promise<void> {
    if (this.routesToPreload && this.routesToPreload.length > 0) {
      const routeToPreload = this.routesToPreload.find(
        (filterRouteToPreload: RouteToPreload) => {
          return filterRouteToPreload.routePath === path;
        }
      );

      if (routeToPreload) {
        await routeToPreload.load();
      }
    }
    return;
  }
}
