import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  computeScrollRight(scrollContainerEl: HTMLElement): number {
    return (
      scrollContainerEl.scrollWidth -
      scrollContainerEl.clientWidth -
      scrollContainerEl.scrollLeft
    );
  }
}
