import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TotalsUiModel } from '../ui-models/totals.ui-model';
import { RxUtil } from 'utility/rx.util';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Invoice2Store {
  private _invoiceTotals$ = new BehaviorSubject<Readonly<TotalsUiModel> | null>(
    null
  );

  readonly invoiceTotals$ = this._invoiceTotals$.pipe(
    filter(RxUtil.inputIsNotNullOrUndefined)
  );

  setInvoiceTotals(v: TotalsUiModel) {
    this._invoiceTotals$.next(v);
  }

  clearInvoiceTotals() {
    this._invoiceTotals$.next(null);
  }
}
