import { Pipe, PipeTransform } from '@angular/core';
import { DiscountTypes } from '../enums/discount-types.enum';

@Pipe({
  name: 'discountTypeShorthand'
})
export class DiscountTypeShorthandPipe implements PipeTransform {
  transform(discountLonghand: string): string {
    let value: string;
    switch (discountLonghand) {
      case DiscountTypes.PERCENT:
        value = '%';
        break;
      case DiscountTypes.DOLLAR:
        value = '$';
        break;
      default:
        value = discountLonghand;
        break;
    }
    return value;
  }
}
