import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { IOrderItemModifier } from '../models/order-item';

@Pipe({
    name: 'orderModifierPrice'
})
export class OrderModifierPricePipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {
    }

    transform(value: IOrderItemModifier, ...args: any[]): string {
        if (!value) return '';
        return this.getModifierPriceString(value);
    }

    getModifierPriceString(modifier: IOrderItemModifier): string {
        let price = null;
        if (typeof modifier.item.additionalCost === 'number') {
            price = modifier.item.additionalCost;
        }
        else if (typeof modifier.item.price === 'number') {
            price = modifier.item.price;
        }

        if (typeof price === 'number') {
            return `+${this.currencyPipe.transform(price / 100, 'USD', 'symbol-narrow')}`;
        }
        return '';
    }
}
