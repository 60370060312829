import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GuestPresenceStore {
  private readonly _targetScanCompletionEvent$ = new Subject<boolean>();

  readonly targetScanCompletionEvent$ =
    this._targetScanCompletionEvent$.asObservable();

  emitTargetScanCompletionEvent(v: boolean) {
    this._targetScanCompletionEvent$.next(v);
  }
}
