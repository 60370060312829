import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ILocation } from '../models';
import { filter, map } from 'rxjs/operators';
import { IPosOptions } from '../models/pos-options';
import { FeedbackProvider } from '../models/feedback-provider.model';
import { RxUtil } from 'utility/rx.util';
import { Location2Store } from 'stores/location2.store';

@Injectable({
  providedIn: 'root'
})
export class LocationStore {
  constructor(private location2Store: Location2Store) {}

  private _currentLocation$ = new BehaviorSubject<ILocation | null>(null);
  readonly currentLocation$ = this._currentLocation$.pipe(
    filter(RxUtil.inputIsNotNullOrUndefined)
  );

  readonly posOptions$: Observable<IPosOptions | null> =
    this.currentLocation$.pipe(
      map((location) => location?.systemType?.options ?? null)
    );

  get currentLocation(): ILocation | null {
    return this._currentLocation$.getValue();
  }

  setCurrentLocation(loc: ILocation) {
    this._currentLocation$.next(loc);
  }

  clear() {
    this._currentLocation$.next(null);
  }

  readonly isPayEnabled$: Observable<boolean> =
    this.location2Store.currentLocation$.pipe(
      map((location) => location.payEnabled)
    );

  readonly isOrderingEnabled$: Observable<boolean> = this.currentLocation$.pipe(
    map(
      (location) => location.orderPickupEnabled || location.orderDeliveryEnabled
    )
  );

  readonly isDigitalMenuEnabledAndValid$: Observable<boolean> =
    this.currentLocation$.pipe(
      map((location) => {
        if (!location.digitalMenu || !location.digitalMenu.enabled) {
          return false;
        }

        if (
          !location.digitalMenu.menuMode ||
          location.digitalMenu.menuMode === 'image'
        ) {
          return (
            typeof location.digitalMenu.imageUrl === 'string' &&
            location.digitalMenu.imageUrl !== ''
          );
        } else if (location.digitalMenu.menuMode === 'iframe') {
          return (
            typeof location.digitalMenu.iframeUrl === 'string' &&
            location.digitalMenu.iframeUrl !== ''
          );
        }

        return false;
      })
    );

  readonly digitalMenuMode$: Observable<'image' | 'iframe' | null> =
    combineLatest([
      this.isDigitalMenuEnabledAndValid$,
      this.currentLocation$
    ]).pipe(
      map(([isDigitalMenuEnabled, location]) =>
        isDigitalMenuEnabled ? location.digitalMenu?.menuMode || 'image' : null
      )
    );

  readonly feedbackProvider$: Observable<FeedbackProvider> =
    this.currentLocation$.pipe(
      map((location) =>
        location.feedbackOptions && location.feedbackOptions.provider
          ? location.feedbackOptions.provider
          : 'ready'
      )
    );

  readonly isPreDiscountTipEnabled$: Observable<boolean> =
    this.currentLocation$.pipe(
      map(
        (location) =>
          !!location.tipSelector && !!location.tipSelector.preDiscountTip
      )
    );

  readonly isPreItemDiscountTipEnabled$: Observable<boolean> =
    this.currentLocation$.pipe(
      map(
        (location) =>
          !!location.tipSelector && !!location.tipSelector.preItemDiscountTip
      )
    );

  readonly isPreNonReadyPaymentsTipEnabled$: Observable<boolean> =
    this.currentLocation$.pipe(
      map(
        (location) =>
          !!location.tipSelector &&
          !!location.tipSelector.preNonReadyPaymentsTip
      )
    );

  readonly tipSelectorBottomText$: Observable<string | null> =
    this.currentLocation$.pipe(
      map((location) =>
        location.tipSelector?.showBottomText && location.tipSelector.bottomText
          ? location.tipSelector.bottomText
          : null
      )
    );

  readonly isAlcoholOrderingEnabled$: Observable<boolean> =
    this.currentLocation$.pipe(
      map((location) => location.alcoholOrderingEnabled)
    );

  readonly isMenuReadonly$: Observable<boolean> = this.currentLocation$.pipe(
    map((location) => !!location.menuReadOnly)
  );
}
