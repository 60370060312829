import { ChangeDetectionStrategy, Component } from '@angular/core';
import { V2Flex } from '../helpers/v2-flex.extendable';

@Component({
  selector: 'v2-row',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: row;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Row extends V2Flex {}
