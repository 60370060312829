import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'v2-checkbox',
  templateUrl: 'v2-checkbox.component.html',
  styleUrls: ['v2-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Checkbox {
  @Input() @HostBinding('class.checked') checked: boolean = false;
  @Input() @HostBinding('class.disabled') disabled: boolean = false;
  @Input() shape: 'circle' | 'square' = 'circle';
  @Input() fillMode: 'check' | 'fadedCheck' | 'solid' = 'check';
  @Input() disabledVariation?: 'line';

  @HostBinding('class') private get class() {
    return `fill-${this.fillMode} shape-${this.shape} disabled-variation-${this.disabledVariation}`;
  }

  showCheckmark(): boolean {
    return (
      (this.fillMode === 'check' || this.fillMode === 'fadedCheck') &&
      this.checked &&
      !this.disabled
    );
  }
}
