const fetchTransaction = `query transaction($invoiceId:ID!) {
  transaction(invoiceId:$invoiceId){
    id
   locationName
   posTicketNumber
   paidDate
   employeeName
   tipAmount
   amountPaid
   location{
     name
     streetAddress
     city
     stateCode
     zip
     phoneNumber
     customEmailHeaderMessage
     theme {
       colors {
         colorPrimary
       }
       images {
         imageHeaderSmall
       }
     }
   }
   cardDetails {
     cardBrand
     last4
   }
   items {
    id
    name
    externalId
    price
    quantity
    originalPrice
      discounts {
        name
        value
      }
    modifiers {
      name
      externalId
      pricePerUnit
      quantity
      isOverridden
      excludeFromReceipt
    }
  }
  totals {
    discounts
    due
    otherCharges
    serviceCharge
    subtotal
    tax
    total
  }
  }
   }`;

const abandonInvoice = `mutation abandonInvoice($input:AbandonInvoiceInput!) {
    abandonInvoice(input:$input){
      error {
        message
        code
      }
      accepted 
    }
  }`;

const renewInvoice = `mutation renewInvoice($input:RenewInvoiceInput!) {
    renewInvoice(input:$input){
      error {
        message
        code
      }
      invoice {
        id
        timeToLiveInSeconds
      } 
    }
  }`;

const fetchInvoices = `query {
    invoices(first:50){
      nodes {
        id
        paid
        paidDate
        posTicketNumber
        employeeName
        customerName
        tipAmountPaid
        amountPaid
        locationName
        items {
          id
          name
          externalId
          price
          quantity
          modifiers {
            name
            externalId
            pricePerUnit
            quantity
          }
        }
        totals {
          discounts
          due
          otherCharges
          serviceCharge
          subtotal
          tax
          total
        }
      }
    }
     }`;

const requestInvoice = `mutation requestInvoice($input:RequestInvoiceInput!) {
  requestInvoice(input:$input){
    error {
      message 
      code
    }
    exceedsPaymentLimits
    invoice {
      id 
      totals {
        discounts
        due
        otherPayments
        otherCharges
        serviceCharge
        subtotal
        tax
        total
      }
      items {
        id
        name
        externalId
        price
        quantity
        modifiers {
          name
          externalId
          pricePerUnit
          quantity
        }
        discounts {
          value
        }
      }
      discounts {
        posId
        value
        open
        loyaltyDiscount
        id
        name
      }
      loyaltyDiscount
    }
  }
}`;

export {
  fetchInvoices,
  fetchTransaction,
  abandonInvoice,
  requestInvoice,
  renewInvoice
};
