import { Component } from '@angular/core';
import { V2TextDisplay } from '../helpers/v2-text-display.extendable';

@Component({
  selector: 'v2-label-text',
  template: `
    <v2-text [color]="color" [lineClamp]="lineClamp">
      <ng-content></ng-content>
    </v2-text>
  `,
  styles: [
    `
      :host {
        display: block;
        font-family: Lato, sans-serif;
        font-size: 12px;
        font-weight: 900;
        line-height: 1.25;
        letter-spacing: 1.2px;
      }
    `
  ]
})
export class V2LabelText extends V2TextDisplay {}
