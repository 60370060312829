import { Observable, Subject } from 'rxjs';

export class V2BottomSheetRef<T = void> {
  constructor(public closeOnBackdropClick: boolean) {}

  close(result?: T) {
    this._onClose$.next(result);
    this._onClose$.complete();
  }

  // TODO reconsider making this a promise
  private readonly _onClose$ = new Subject<T | void>();
  // this represents the start of a close, not the end
  readonly onClose$ = this._onClose$.asObservable();
}

export class V2ScrollableContentBottomSheetRef<
  T = void
> extends V2BottomSheetRef<T> {
  constructor(public closeOnBackdropClick: boolean) {
    super(closeOnBackdropClick);
  }

  private readonly _onContentScroll$ = new Subject<number>();
  readonly onContentScroll$: Observable<number> =
    this._onContentScroll$.asObservable();

  registerContentScroll(contentScrollTop: number) {
    this._onContentScroll$.next(contentScrollTop);
  }
}
