import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { nil } from '../helpers/nil.helper';

// converts from cents to dollars then formats with the angular currency pipe
@Pipe({
  name: 'v2Currency'
})
export class V2CurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: number | nil): string {
    return value
      ? this.currencyPipe.transform(value / 100, 'USD', 'symbol-narrow') ??
          '$0.00'
      : '$0.00';
  }
}
