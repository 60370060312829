import { Component } from '@angular/core';
import {NavController} from "@ionic/angular";

@Component({
  selector: 'account-menu-icon',
  templateUrl: 'account-menu-icon.html',
  styleUrls: ['account-menu-icon.scss']
})
export class AccountMenuIconComponent {
  constructor(private navCtrl: NavController) {}

  public goToMyPage() {
    this.navCtrl.navigateForward('account');
  }
}
