import { IItem, IPosPayment } from '../../models';
import { ICheckDiscounts } from '../../models';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { IOtherPayment } from '../../models/other-payment';
import { IModifier } from 'src/app/models/item-modifier';

@Component({
  selector: 'check-items',
  templateUrl: 'check-items.html',
  styleUrls: ['check-items.scss']
})
export class CheckItemsComponent implements OnChanges {
  @Input() items: IItem[] = [];
  @Input() paidItems: IItem[] = [];
  @Input() totals: any;
  @Input() discounts: ICheckDiscounts[] = [];
  @Input() isLast: boolean | undefined;
  @Input() currencyUnit: string | undefined;
  @Input() isPaid: boolean = false;
  @Input() isClaimed: boolean = false;
  @Input() isReceipt: boolean = false;
  @Input() billIsPicked: boolean = false;
  @Input() selectedItems: any[] = [];
  @Input() payByItem: boolean = false;
  @Input() amountPaid: number | undefined;
  @Input() tipAmountPaid: number | undefined;
  @Input() payments: IPosPayment[] = [];
  @Input() otherPayments: IOtherPayment[] = [];
  @Output() selectItem: EventEmitter<any> = new EventEmitter<any>();

  allPaid: boolean = false;
  totalPaid: number = 0;

  chooseItem(item: IItem) {
    if (this.canSelectItem(item)) {
      this.selectItem.emit(item);
    }
  }

  canSelectItem(item: IItem) {
    return (
      (!this.discounts || this.discounts.length === 0) &&
      this.payByItem &&
      item.price !== 0 &&
      !item.isPaid &&
      item.claimedNumerator === 0
    );
  }

  isItemClaimed(item: IItem) {
    return this.payByItem && !item.isPaid && item.claimedNumerator !== 0;
  }

  ngOnChanges() {
    this.paidItems = this.paidItems ? this.paidItems : [];
    this.otherPayments = this.otherPayments ? this.otherPayments : [];
    this.allPaid = this.items.every((item) => item.isPaid);
    this.totalPaid =
      this.paidItems.reduce((acc, elem) => {
        return acc + elem.price;
      }, 0) +
      this.otherPayments.reduce((acc, elem) => {
        return acc + elem.dueAmountPaid;
      }, 0);
  }

  itemSelected(item: IItem) {
    return this.selectedItems.find(
      (selectedItem) => selectedItem.posId === item.posId
    );
  }

  getItemPrice(item: IItem): number {
    return this.getOriginalItemPriceWithModifiersApplied(item);
  }

  /**
   * Boolean to display the pay by item checkbox.
   * @param item
   */
  canDisplayCheckbox(item: IItem): boolean {
    return this.canSelectItem(item) && !this.isReceipt;
  }

  shouldShowModifier(modifier: IModifier): boolean {
    return !modifier.excludeFromReceipt && !modifier.isOverridden;
  }

  /**
   * Used to determine if an item level discount was applied
   * and to show full pre discount price.
   * @param item
   */
  private getOriginalItemPriceWithModifiersApplied(item: IItem): number {
    if (!item || !item.originalPrice) return 0;

    return (
      item.originalPrice +
      item.modifiers.reduce((a, v) => a + v.pricePerUnit * v.quantity, 0)
    );
  }
}
