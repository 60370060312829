import { firstValueFrom, Observable, Subscription } from 'rxjs';

export class RxUtil {
  static cleanupSubscription(subs?: Subscription) {
    subs?.unsubscribe();
  }

  /**
   * Filtering Null and Undefined with correct type inference.
   * https://medium.com/ngconf/filtering-types-with-correct-type-inference-in-rxjs-f4edf064880d
   */
  static inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
    return input !== null && input !== undefined;
  }

  static takeOne<T>(observable: Observable<T>): Promise<T> {
    return firstValueFrom(observable);
  }
}
