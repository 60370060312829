import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { IAnonInfoRes, ICreateAccountRes } from './contracts/auth.contracts';
import { lastValueFrom } from 'rxjs';

const readyConfig = environment.readyConfig;

@Injectable({
  providedIn: 'root'
})
export class ReadyAuthApi {
  constructor(public http: HttpClient) {}

  async getAnonymousInformation(): Promise<IAnonInfoRes> {
    function getRandomHex(size: number) {
      const arr = new Uint8Array(size);
      crypto.getRandomValues(arr);
      return Array.from(arr, byte => byte.toString(16).padStart(2, '0')).join('');
    }

    const name = `Guest${Math.floor(Math.random() * 100000) + 1}`;
    const email = `guest${getRandomHex(16)}@readytopay.net`;
    const pwd = getRandomHex(16);

    return { name, email, pwd };
  }

  async createAccount(
    email?: string,
    name?: string,
    password?: string
  ): Promise<ICreateAccountRes> {
    try {
      return await lastValueFrom(
        this.http.post<ICreateAccountRes>(
          `${readyConfig.serverUrl}/customers/register`,
          {
            email,
            password,
            name
          }
        )
      );
    } catch (_) {
      throw 'Could not create the account, please try again.';
    }
  }
}
