import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RxUtil } from 'utility/rx.util';
import {
  LocationUiModel,
  OverridableLocationProps
} from '../ui-models/location.ui-model';

// This will replace LocationStore. This uses a UI model with sensible defaults, where the
// legacy store exposes an API contract directly which opens us up to certain type issues
// as well as organizational inconsistencies.
@Injectable({
  providedIn: 'root'
})
export class Location2Store {
  private readonly _currentLocation$ =
    new BehaviorSubject<Readonly<LocationUiModel> | null>(null);

  private readonly _locationOverrides$ = new BehaviorSubject<
    Readonly<OverridableLocationProps>
  >({});

  readonly currentLocation$: Observable<Readonly<LocationUiModel>> =
    combineLatest([
      this._currentLocation$.pipe(filter(RxUtil.inputIsNotNullOrUndefined)),
      this._locationOverrides$
    ]).pipe(
      map(([currentLocation, locationOverrides]) =>
        this.applyLocationOverrides(currentLocation, locationOverrides)
      )
    );

  setCurrentLocation(v: LocationUiModel) {
    this._currentLocation$.next(v);
  }

  getCurrentLocation(): Promise<Readonly<LocationUiModel>> {
    return RxUtil.takeOne(this.currentLocation$);
  }

  registerOverrides(overrides: OverridableLocationProps) {
    this._locationOverrides$.next(overrides);
  }

  private applyLocationOverrides(
    location: Readonly<LocationUiModel>,
    overrideProps: OverridableLocationProps
  ): Readonly<LocationUiModel> {
    return {
      ...location,
      ...overrideProps
    };
  }
}
