import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationStore } from './location.store';
import { FeaturesStore } from './features.store';
import { detect } from 'detect-browser';
import { GatewayPaymentMethod } from '../pages/v2-order-pay/models/payment.model';
import { StripeStore } from './stripe.store';
import { OnAccountPaymentStore } from './on-account-payment.store';

@Injectable({
  providedIn: 'root'
})
export class PaymentStore {
  constructor(
    private locationStore: LocationStore,
    private featureStore: FeaturesStore,
    private stripeStore: StripeStore,
    private onAccountPaymentStore: OnAccountPaymentStore
  ) {}

  private readonly _visitingHostedFormPage$ = new BehaviorSubject(false);

  get visitingHostedFormPage(): boolean {
    return this._visitingHostedFormPage$.getValue();
  }

  set visitingHostedFormPage(value: boolean) {
    this._visitingHostedFormPage$.next(value);
  }

  readonly suppressCreditCard3DSAddress$: Observable<boolean> =
    this.locationStore.currentLocation$.pipe(
      map(
        (location) =>
          location.paymentProcessor?.options?.gateway
            ?.suppressCreditCard3DSAddress ?? false
      )
    );

  private _realexPaymentEmail$ = new BehaviorSubject<string | null>(null);

  get realexPaymentEmail(): string | null {
    return this._realexPaymentEmail$.getValue();
  }

  set realexPaymentEmail(v: string | null) {
    this._realexPaymentEmail$.next(v);
  }

  private readonly _uniquePaymentRequestNumber$ = new BehaviorSubject(0);

  // this can be used for payment providers that require unique payment request ids
  getUniquePaymentRequestNumber(): number {
    const uniquePaymentRequestNumber =
      this._uniquePaymentRequestNumber$.getValue();
    this._uniquePaymentRequestNumber$.next(uniquePaymentRequestNumber + 1);
    return uniquePaymentRequestNumber;
  }

  private readonly _isGooglePayAvailable$ = new BehaviorSubject(false);
  readonly isGooglePayAvailable$ = this._isGooglePayAvailable$.asObservable();

  set isGooglePayAvailable(value: boolean) {
    this._isGooglePayAvailable$.next(value);
  }

  private readonly _isApplePayAvailable$ = new BehaviorSubject(false);
  readonly isApplePayAvailable$ = this._isApplePayAvailable$.asObservable();

  set isApplePayAvailable(value: boolean) {
    this._isApplePayAvailable$.next(value);
  }

  private readonly _isGooglePayLoading$ = new BehaviorSubject(false);
  readonly isGooglePayLoading$ = this._isGooglePayLoading$.asObservable();

  get isGooglePayLoading(): boolean {
    return this._isGooglePayLoading$.getValue();
  }

  set isGooglePayLoading(value: boolean) {
    this._isGooglePayLoading$.next(value);
  }

  private readonly _isApplePayLoading$ = new BehaviorSubject(false);
  readonly isApplePayLoading$ = this._isApplePayLoading$.asObservable();

  get isApplePayLoading(): boolean {
    return this._isApplePayLoading$.getValue();
  }

  set isApplePayLoading(value: boolean) {
    this._isApplePayLoading$.next(value);
  }

  readonly gatewayPaymentMethods$: Observable<string[]> =
    this.locationStore.currentLocation$.pipe(
      map(
        (location) =>
          location.paymentProcessor?.options?.gateway?.payment_methods ?? []
      )
    );

  readonly isCreditCardPaymentAvailable$: Observable<boolean> = combineLatest([
    this.gatewayPaymentMethods$,
    this.featureStore.areCardPaymentsDisabled$
  ]).pipe(
    map(
      ([gatewayPaymentMethods, areCardPaymentsDisabled]) =>
        !areCardPaymentsDisabled &&
        gatewayPaymentMethods.includes(GatewayPaymentMethod.CreditCard)
    )
  );

  readonly isPayPalAvailable$: Observable<boolean> =
    this.gatewayPaymentMethods$.pipe(
      map((gatewayPaymentMethods) =>
        gatewayPaymentMethods.includes(GatewayPaymentMethod.PayPal)
      )
    );

  readonly availablePaymentMethods$: Observable<GatewayPaymentMethod[]> =
    combineLatest([
      this.isGooglePayAvailable$,
      this.isApplePayAvailable$,
      this.isPayPalAvailable$,
      this.isCreditCardPaymentAvailable$
    ]).pipe(
      map(
        ([
          isGooglePayAvailable,
          isApplePayAvailable,
          isPayPayAvailable,
          isCreditCardPaymentAvailable
        ]) => {
          const os = detect()?.os ?? '';
          const isAppleOS = ['iOS', 'Mac OS'].includes(os);
          const isApplePayPrimary = isAppleOS && isApplePayAvailable;
          const isGooglePayPrimary = !isAppleOS && isGooglePayAvailable;
          const isApplePaySecondary = isApplePayAvailable && !isAppleOS;
          const isGooglePaySecondary = isGooglePayAvailable && isAppleOS;
          return [
            ...(isApplePayPrimary ? [GatewayPaymentMethod.ApplePay] : []),
            ...(isGooglePayPrimary ? [GatewayPaymentMethod.GooglePay] : []),
            ...(isApplePaySecondary ? [GatewayPaymentMethod.ApplePay] : []),
            ...(isGooglePaySecondary ? [GatewayPaymentMethod.GooglePay] : []),
            ...(isPayPayAvailable ? [GatewayPaymentMethod.PayPal] : []),
            ...(isCreditCardPaymentAvailable
              ? [GatewayPaymentMethod.CreditCard]
              : [])
          ];
        }
      )
    );

  readonly numAvailablePaymentMethods$: Observable<number> =
    this.availablePaymentMethods$.pipe(map((methods) => methods.length));

  readonly defaultPaymentMethod$: Observable<GatewayPaymentMethod | undefined> =
    this.availablePaymentMethods$.pipe(map((methods) => methods[0]));

  readonly hasAnyPaymentMethods$: Observable<boolean> =
    this.availablePaymentMethods$.pipe(map((methods) => methods.length > 0));

  readonly hasMultiplePaymentMethods$: Observable<boolean> = combineLatest([
    this.stripeStore.isStripePaymentAvailable$,
    this.numAvailablePaymentMethods$
  ]).pipe(
    map(
      ([isStripePaymentAvailable, numAvailablePaymentOptions]) =>
        isStripePaymentAvailable || numAvailablePaymentOptions > 1
    )
  );

  readonly hasAvailablePaymentOptions$: Observable<boolean> = combineLatest([
    this.stripeStore.isStripePaymentAvailable$,
    this.numAvailablePaymentMethods$
  ]).pipe(
    map(
      ([isStripePaymentAvailable, numAvailablePaymentOptions]) =>
        isStripePaymentAvailable || numAvailablePaymentOptions > 0
    )
  );

  readonly isLoadingDefaultPaymentMethod$: Observable<boolean> = combineLatest([
    this.isGooglePayLoading$,
    this.isApplePayLoading$,
    this.defaultPaymentMethod$
  ]).pipe(
    map(
      ([isGooglePayLoading, isApplePayLoading, defaultPaymentMethod]) =>
        (isGooglePayLoading &&
          defaultPaymentMethod === GatewayPaymentMethod.GooglePay) ||
        (isApplePayLoading &&
          defaultPaymentMethod === GatewayPaymentMethod.ApplePay)
    )
  );

  private readonly isOnlyApplePayConfigured$: Observable<boolean> =
    this.gatewayPaymentMethods$.pipe(
      map(
        (paymentMethods) =>
          paymentMethods.length === 1 &&
          paymentMethods[0] === GatewayPaymentMethod.ApplePay
      )
    );

  readonly showNoPaymentsSupportedOnDeviceFooter$: Observable<boolean> =
    combineLatest([
      this.isApplePayAvailable$,
      this.isOnlyApplePayConfigured$
    ]).pipe(
      map(([isApplePayAvailable, isOnlyApplePayConfigured]) => {
        const os = detect()?.os ?? '';
        const isAppleOS = ['iOS', 'Mac OS'].includes(os);
        const isApplePaySupportedOnDevice = isApplePayAvailable && isAppleOS;

        return isOnlyApplePayConfigured && !isApplePaySupportedOnDevice;
      })
    );

  readonly showNoPaymentsConfiguredFooter$: Observable<boolean> = combineLatest(
    [
      this.showNoPaymentsSupportedOnDeviceFooter$,
      this.hasAvailablePaymentOptions$,
      this.onAccountPaymentStore.areOnAccountPaymentsEnabled$
    ]
  ).pipe(
    map(
      ([
        showNoPaymentsSupportedOnDeviceFooter,
        hasAvailablePaymentOptions,
        areOnAccountPaymentsEnabled
      ]) =>
        !showNoPaymentsSupportedOnDeviceFooter &&
        !hasAvailablePaymentOptions &&
        !areOnAccountPaymentsEnabled
    )
  );
}
