import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { nil } from '../../helpers/nil.helper';

@Component({
  selector: 'v2-alert',
  templateUrl: 'v2-alert.component.html',
  styleUrls: ['v2-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Alert {
  @Input() color: 'discount-green' | 'service-blue' | nil;
  @Input() title: string = '';
  @Input() @HostBinding('class.has-subtitle') subtitle: string = '';
}
