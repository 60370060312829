import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feature } from '../enums/feature.enum';

@Injectable({
  providedIn: 'root'
})
export class FeaturesStore {
  private _features$ = new BehaviorSubject<Feature[]>([]);
  public readonly features$ = this._features$.asObservable();

  // Feature Streams
  public readonly isPretaxTipEnabled$: Observable<boolean> =
    this.features$.pipe(
      map((features) => features.includes(Feature.PreTaxTip))
    );

  /**
   * @deprecated use paymentProcessor.options.gateway.payment_methods credit_card
   */
  public readonly areCardPaymentsDisabled$: Observable<boolean> =
    this.features$.pipe(
      map((features) => features.includes(Feature.DisableCardPayments))
    );

  public readonly isOrderingAheadEnabled$: Observable<boolean> =
    this.features$.pipe(
      map((features) => features.includes(Feature.EnableOrderAhead))
    );

  public readonly isSeparateSeatsEnabled$: Observable<boolean> =
    this.features$.pipe(
      map((features) => features.includes(Feature.SeparateSeats))
    );

  readonly arePromoCodesEnabled$: Observable<boolean> = this.features$.pipe(
    map((features) => features.includes(Feature.EnablePromoCodes))
  );

  isEnabled(feature: Feature): boolean {
    return this._features$.getValue().includes(feature);
  }

  public setFeatures(features: Feature[]) {
    this._features$.next(features);
  }

  public clear() {
    this._features$.next([]);
  }
}
