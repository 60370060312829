import {
  ChangeDetectionStrategy,
  Component,
  HostListener
} from '@angular/core';
import { V2ToastRef } from '../../../helpers/v2-toast-ref';

@Component({
  selector: 'v2-toast-error',
  templateUrl: 'v2-toast-error.component.html',
  styleUrls: ['v2-toast-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2ToastError {
  constructor(public toastRef: V2ToastRef) {}

  @HostListener('click') onClick() {
    this.toastRef.close();
  }
}
