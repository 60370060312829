import { Injectable } from '@angular/core';
import { PaymentMethodOverrides } from '../pages/v2-order-pay/models/payment.model';
import { ReadyHttpClient } from './ready-http-client';
import { TableStore } from '../stores/table.store';
import {
  GetHostedIframeContract,
  GetHostedIframeProcessors
} from './contracts/get-hosted-iframe.contract';
import { InvoiceStore } from '../stores/invoice.store';
import {
  FinalizePaymentRequestContract,
  FinalizePaymentRequestProcessors
} from './contracts/finalize-payment-request.contract';
import { IInvoice } from '../models/invoice';
import { GraphQLApi } from './graphql.api';
import { RequestInvoicePayloadCheck } from './contracts/request-invoice-payload-check.contract';
import { LoyaltyStore } from 'stores/loyalty.store';
import { RateLimitStore } from 'stores/rate-limit.store';
import {
  abandonInvoice,
  fetchInvoices,
  fetchTransaction,
  renewInvoice,
  requestInvoice
} from './mutations/invoice.mutations';
import { IPaymentResponse } from './contracts/paymentresponse.contracts';
import { IPaymentMethodsResponse } from './contracts/payment-method-response.contracts';

@Injectable({
  providedIn: 'root'
})
export class InvoicesApi {
  constructor(
    private httpClient: ReadyHttpClient,
    private tableStore: TableStore,
    private invoiceStore: InvoiceStore,
    private loyaltyStore: LoyaltyStore,
    private rateLimitStore: RateLimitStore,
    private graphQLApi: GraphQLApi
  ) {}

  getHostedIframe<T extends GetHostedIframeProcessors>(
    amountInCents?: number
  ): Promise<GetHostedIframeContract<T>> {
    const data: { requestType: string; amount?: number } = {
      requestType: 'getIframeUrl'
    };
    if (typeof amountInCents === 'number') {
      data.amount = Math.round(amountInCents);
    }
    return this.httpClient.post<GetHostedIframeContract<T>>(
      `/tables/${this.tableStore.currentTable?.beaconId}/tickets/xxx/invoices/${this.invoiceStore.currentInvoice?.id}/request/gethostediframe`,
      // TODO remove body
      { data }
    );
  }

  finalizePaymentRequest<T extends FinalizePaymentRequestProcessors>(
    sessionId: string
  ): Promise<FinalizePaymentRequestContract<T>> {
    return this.httpClient.post<FinalizePaymentRequestContract<T>>(
      `/tables/${this.tableStore.currentTable?.beaconId}/tickets/xxx/invoices/${this.invoiceStore.currentInvoice?.id}/request/finalize`,
      {
        data: {
          requestType: 'getTokenFromSession',
          SessionId: sessionId
        }
      }
    );
  }

  getPaymentRequest(
    amount: number,
    providerOverride?: PaymentMethodOverrides
  ): Promise<any> {
    return this.httpClient.post(
      `/tables/${this.tableStore.currentTable?.beaconId}/tickets/xxx/invoices/${this.invoiceStore.currentInvoice?.id}/request/paymentrequest`,
      { amount, providerOverride: providerOverride ? providerOverride : null }
    );
  }

  getPaymentMethods(
    amount: number,
    providerOverride?: PaymentMethodOverrides
  ): Promise<IPaymentMethodsResponse> {
    if (!amount) {
      throw new Error('Missing amount for Ready API call');
    }
    return this.httpClient.post(
      `/tables/${this.tableStore.currentTable?.beaconId}/tickets/xxx/invoices/${this.invoiceStore.currentInvoice?.id}/request/paymentmethods`,
      { amount, providerOverride: providerOverride ? providerOverride : null }
    );
  }

  getGooglePayIframeData<T extends GetHostedIframeProcessors>(
    amount: number
  ): Promise<GetHostedIframeContract<T>> {
    return this.httpClient.post<GetHostedIframeContract<T>>(
      `/tables/${this.tableStore.currentTable?.beaconId}/tickets/xxx/invoices/${this.invoiceStore.currentInvoice?.id}/request/getgooglepayiframe`,
      { amount }
    );
  }

  getApplePayIframeData<T extends GetHostedIframeProcessors>(
    amount: number
  ): Promise<GetHostedIframeContract<T>> {
    return this.httpClient.post<GetHostedIframeContract<T>>(
      `/tables/${this.tableStore.currentTable?.beaconId}/tickets/xxx/invoices/${this.invoiceStore.currentInvoice?.id}/request/getapplepayiframe`,
      { amount }
    );
  }

  submitPayment(
    beaconId: string | undefined,
    ticketId: string,
    invoiceId: string,
    payload: {
      tip: number;
      tipType: string;
      paymentCard: any;
      useCredits: boolean;
      saveCard: boolean;
      giftCardAuth: {
        accountNumber: string;
        pinNumber: string;
      } | null;
      providerOverride?: PaymentMethodOverrides;
    }
  ): Promise<IPaymentResponse> {
    return this.httpClient.post<IPaymentResponse>(
      `/tables/${beaconId}/tickets/${ticketId}/invoices/${invoiceId}/payments`,
      payload
    );
  }

  emailReceipt(
    receiptId: string,
    emailAddress: string,
    subscribeToNewsletter: boolean
  ): Promise<unknown> {
    return this.httpClient.post<unknown>(
      `/invoices/${receiptId}/emailreceipt`,
      {
        email: emailAddress,
        subscribeToNewsletter
      }
    );
  }

  getCardHashRequest(
    beaconId: string,
    ticketId: string,
    invoiceId: string,
    body: any
  ): Promise<any> {
    return this.httpClient.post<any>(
      `/tables/${beaconId}/tickets/${ticketId}/invoices/${invoiceId}/request/cardHash`,
      body
    );
  }

  getInvoicesHistory(): Promise<IInvoice[]> {
    return this.graphQLApi
      .post(fetchInvoices)
      .then((res) => {
        return res.data.invoices.nodes;
      })
      .catch((error) => {
        throw error;
      });
  }

  getTransaction(invoiceId: string): Promise<IInvoice> {
    return this.graphQLApi
      .post(fetchTransaction, { invoiceId })
      .then((res) => {
        return res.data.transaction;
      })
      .catch((error) => {
        throw error;
      });
  }

  async abandonInvoice(): Promise<void> {
    await this.graphQLApi.post(abandonInvoice, {
      input: {
        invoiceId: this.invoiceStore.currentInvoice
          ? this.invoiceStore.currentInvoice.id
          : null
      }
    });
  }

  async abandonInvoiceWithKeepalive(): Promise<void> {
    await this.graphQLApi.postKeepalive(abandonInvoice, {
      input: {
        invoiceId: this.invoiceStore.currentInvoice
          ? this.invoiceStore.currentInvoice.id
          : null
      }
    });
  }

  async renewInvoice(duration: number): Promise<void> {
    const res = await this.graphQLApi.post(renewInvoice, {
      input: {
        invoiceId: this.invoiceStore.currentInvoice
          ? this.invoiceStore.currentInvoice.id ||
            this.invoiceStore.currentInvoice._id
          : null,
        amount: duration,
        unit: 'milliseconds'
      }
    });

    const error = res.data.renewInvoice.error;
    if (error) {
      throw new Error(error.message);
    }
  }

  public async requestInvoice(
    tickets: RequestInvoicePayloadCheck[]
  ): Promise<IInvoice> {
    const res = await this.graphQLApi.post(requestInvoice, {
      input: {
        tickets,
        selectedReward:
          this.loyaltyStore.appliedOffer &&
          !this.loyaltyStore.appliedOffer.isInvalid
            ? this.loyaltyStore.appliedOffer.id
            : null
      }
    });
    const unhandledError = res.errors ? res.errors[0] : null;

    if (unhandledError) {
      throw new Error(unhandledError.message);
    }

    const error =
      res.data && res.data.requestInvoice && res.data.requestInvoice.error
        ? res.data.requestInvoice.error
        : null;

    if (error) {
      throw new Error(error.message);
    }

    if (!(res.data && res.data.requestInvoice)) {
      throw new Error('Could not load invoice.  Please try again.');
    }

    const invoice: IInvoice = res.data.requestInvoice.invoice;
    this.invoiceStore.setCurrentInvoice(invoice);
    this.rateLimitStore.setExceedsPaymentLimits(
      res.data.requestInvoice.exceedsPaymentLimits
    );
    return invoice;
  }
}
