import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  HostListener,
  Injector,
  OnDestroy,
  Type,
  ViewChild
} from '@angular/core';
import { ComponentInsertionDirective } from '../../directives/component-insertion.directive';
import { V2BottomSheetRef } from '../../helpers/v2-bottom-sheet-ref';
import { V2BottomSheetContainer } from '../../helpers/v2-bottom-sheet-container';
import { AsyncUtil } from 'utility/async.util';

@Component({
  selector: 'v2-scrollable-bottom-sheet-variation-footer',
  templateUrl: 'v2-scrollable-bottom-sheet-variation-footer.component.html',
  styleUrls: ['v2-scrollable-bottom-sheet-variation-footer.component.scss']
})
export class V2ScrollableBottomSheetVariationFooter
  extends V2BottomSheetContainer<unknown>
  implements AfterViewInit, OnDestroy
{
  @ViewChild('contentInsertionPoint', {
    static: true,
    read: ComponentInsertionDirective
  })
  private contentInsertionPoint?: ComponentInsertionDirective;
  @ViewChild('footerInsertionPoint', {
    static: true,
    read: ComponentInsertionDirective
  })
  private footerInsertionPoint?: ComponentInsertionDirective;
  private footerComponentRef?: ComponentRef<unknown>;

  footerComponentType?: Type<unknown>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    public bottomSheetRef: V2BottomSheetRef
  ) {
    super();
  }

  ngAfterViewInit() {
    this.componentRef = this.loadChildComponent(
      this.contentInsertionPoint!,
      this.childComponentType!
    );
    this.footerComponentRef = this.loadChildComponent(
      this.footerInsertionPoint!,
      this.footerComponentType!
    );
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.footerComponentRef) {
      this.footerComponentRef.destroy();
    }
  }

  @HostListener('click') onClick() {
    if (this.bottomSheetRef.closeOnBackdropClick) {
      this.bottomSheetRef.close();
    }
  }

  private loadChildComponent(
    insertionPoint: ComponentInsertionDirective,
    componentType: Type<unknown>
  ): ComponentRef<unknown> {
    const viewContainerRef = insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    return viewContainerRef.createComponent<unknown>(
      this.componentFactoryResolver.resolveComponentFactory(componentType),
      undefined,
      Injector.create({
        providers: [
          { provide: V2BottomSheetRef, useValue: this.bottomSheetRef }
        ]
      })
    );
  }

  // The slide-in animation happens automatically, but the slide-out must be
  // called explicitly (this is managed by the bottom-sheet.service)
  async slideOut(): Promise<void> {
    this.slideOutCommenced = true;
    await AsyncUtil.sleep(300);
  }
}
