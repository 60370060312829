export enum LoyaltyVendor {
  Spendgo = 'spendgo',
  Bypass = 'bypass2',
  Square = 'square',
  Punchh = 'punchh'
}

export interface ILoyaltyOptions {
  provider: LoyaltyVendor;
  accountDetailsUrl?: string;
  skipPhoneVerification?: boolean;
  storeId?: string;
}

export interface ILoyaltyReward {
  id: string;
  label: string;
  expiration?: string;
  points?: number;
  quantity?: number;
  rewardType?: RewardType;
}

export enum RewardType {
  Reward = 'reward',
  Redeemable = 'redeemable'
}

export interface ILoyaltyMilestone {
  label: string;
  points: number;
}

export interface IAppliedOffer {
  id: string;
  isValid?: boolean;
  isInvalid?: boolean;
}

export enum LoyaltyMode {
  LandingPageFooter,
  OrderReview,
  Checkout,
  PaymentConfirmation
}

export interface ILoyaltyAccountResponse {
  milestoneLabel?: string;
  programType: LoyaltyProgramType;
  milestonePointsNeeded?: number;
  points: number;
  rewards: [ILoyaltyReward];
  upcomingRewards?: ILoyaltyReward[];
  bankedRewards?: number; // currently used specifically for Punchh loyalty.
}

export type LoyaltyProgramType = 'pointsbanking' | 'spending';
