import { Injectable } from '@angular/core';
import {
  ChatAssistantApi,
  SendMessageRequest
} from '../api/chat-assistant.api';
import { ChatAssistantStore } from '../stores/chat-assistant.store';
import {
  IAssistantConversationMessage,
  IAssistantConversationResponse
} from '../api/contracts/chat-assistant.contracts';
import {
  ChatMessage,
  ChatSession,
  ChatState
} from '../models/chat-assistant.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ChatAssistantService {
  constructor(
    private chatAssistantApi: ChatAssistantApi,
    private chatAssistantStore: ChatAssistantStore,
    private storageService: StorageService
  ) {}
  private readonly chatAssistantStorageKey = 'active-chat-assistant-session';

  private updateChatSessionInLocalStorage(
    chatSessionId: string | boolean
  ): void {
    this.storageService.set(this.chatAssistantStorageKey, chatSessionId, {
      hours: 3
    });
  }

  private hasActiveChatSession(): boolean {
    return !!this.storageService.get(this.chatAssistantStorageKey);
  }

  private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private async showTypingIndicator(): Promise<void> {
    this.chatAssistantStore.setSendingMessage(true);
    await this.delay(1500);
    this.chatAssistantStore.setSendingMessage(false);
  }

  private async showHardCodedMessage(message: string): Promise<void> {
    this.chatAssistantStore.addMessage({
      role: 'assistant',
      text: message,
      timestamp: Date.now()
    });
    await this.delay(1500);
  }

  private async showLoadingSequence(): Promise<void> {
    await this.delay(1500);
    this.chatAssistantStore.setLoading(false);

    await this.showTypingIndicator();
    await this.showHardCodedMessage('Welcome to the Ready Virtual Assistant!');

    await this.showTypingIndicator();
    await this.showHardCodedMessage(
      'I hope you are enjoying your dining experience so far!'
    );

    await this.showTypingIndicator();
  }

  async startConversation(beaconId: string): Promise<void> {
    if (
      this.hasActiveChatSession() &&
      this.chatAssistantStore.chatState.chatSession.messages.length > 0
    ) {
      return;
    }

    if (this.hasActiveChatSession()) {
      await this.getConversationById(beaconId);
      return;
    }

    this.chatAssistantStore.setLoading(true);
    const apiCall = this.chatAssistantApi.startConversation(beaconId);

    try {
      // Show staged loading sequence because api call is taking too long (7 seconds)
      await this.showLoadingSequence();
      const response = await apiCall;
      const mappedResponse = this.mapChatSessionContractToModel(response);
      this.chatAssistantStore.setChatInfo(mappedResponse.externalId);
      this.chatAssistantStore.addMessage({
        ...mappedResponse.messages[0],
        timestamp: Date.now()
      });
      this.updateChatSessionInLocalStorage(response.externalId);
    } catch (error) {
      this.chatAssistantStore.setError(`Failed to start conversation ${error}`);
      this.chatAssistantStore.addMessage({
        timestamp: Date.now(),
        text: 'Something went wrong with starting conversation',
        role: 'assistant'
      });
    } finally {
      this.chatAssistantStore.setSendingMessage(false);
      this.chatAssistantStore.setLoading(false);
    }
  }

  async getConversationById(beaconId: string): Promise<void> {
    this.chatAssistantStore.setLoading(true);

    try {
      const response = await this.chatAssistantApi.getConversationById(
        beaconId,
        this.storageService.get(this.chatAssistantStorageKey) as string
      );
      const mappedResponse = this.mapChatSessionContractToModel(response);
      this.chatAssistantStore.setChatInfo(mappedResponse.externalId);
      this.chatAssistantStore.loadMessages(mappedResponse.messages);
    } catch (error) {
      this.chatAssistantStore.addMessage({
        timestamp: Date.now(),
        text: 'Something went wrong with starting conversation',
        role: 'assistant'
      });
      this.chatAssistantStore.setError(`Failed to get conversation ${error}`);
    } finally {
      this.chatAssistantStore.setLoading(false);
    }
  }

  async sendMessage(beaconId: string, message: string): Promise<void> {
    const messageRequestPayload: SendMessageRequest = {
      externalId: this.chatAssistantStore.chatState.chatSession.externalId,
      message
    };

    this.chatAssistantStore.setSendingMessage(true);

    try {
      const response = await this.chatAssistantApi.sendMessage(
        beaconId,
        messageRequestPayload
      );
      const mappedResponse = this.mapChatSessionContractToModel(response);

      if (response.messages.length === 0) return;
      const nonHardCodedMessages =
        this.chatAssistantStore.chatState.chatSession.messages.slice(0, -2);
      const numberOfNewMessages =
        mappedResponse.messages.length - nonHardCodedMessages.length;
      // the maximum number of messages we get from the api is 20,
      // if we get 20 messages we just replace the current messages state.
      // Otherwise we add the new messages to the current state
      if (mappedResponse.messages.length === 20) {
        this.chatAssistantStore.loadMessages(mappedResponse.messages);
      } else {
        for (let i = numberOfNewMessages - 1; i >= 0; i--) {
          this.chatAssistantStore.addMessage({
            ...mappedResponse.messages[i],
            // this is necessary for sorting
            timestamp: Date.now()
          });
        }
      }
    } catch (error) {
      this.chatAssistantStore.addMessage({
        timestamp: Date.now(),
        text: 'Failed to send a message',
        role: 'assistant',
        nextAvailablePrompts:
          this.chatAssistantStore.chatState.chatSession.messages[0]
            .nextAvailablePrompts
      });
      this.chatAssistantStore.setError(
        `Something went wrong with sending a message ${error}`
      );
    } finally {
      this.chatAssistantStore.setSendingMessage(false);
    }
  }

  private mapChatMessageContractToModel(
    contract: IAssistantConversationMessage
  ): ChatMessage {
    const role = this.isValidRole(contract.role) ? contract.role : 'assistant';

    return {
      role,
      text: contract.text,
      timestamp: contract.timestamp,
      nextAvailablePrompts: contract.nextAvailablePrompts ?? []
    };
  }

  private mapChatSessionContractToModel(
    contract: IAssistantConversationResponse
  ): ChatSession {
    const messages: ChatMessage[] = contract.messages.map((message) =>
      this.mapChatMessageContractToModel(message)
    );
    return {
      externalId: contract.externalId,
      messages
    };
  }

  private isValidRole(role: string): role is 'user' | 'assistant' {
    return role === 'user' || role === 'assistant';
  }
  public mapContractResponseToModel(
    contract: IAssistantConversationResponse
  ): ChatState {
    return {
      chatSession: this.mapChatSessionContractToModel(contract),
      loading: false,
      sendingMessage: false,
      error: null,
      chatModalIsOpen: false
    };
  }
}
