export enum Feature {
  PayByItem = 'pay_by_item',
  PayBySeat = 'enable_pay_by_seat',
  PreTaxTip = 'pre_tax_tip',
  DisableCardPayments = 'disable_card_payments',
  OneClickOrdering = 'one_click_ordering',
  EnableOrderAhead = 'enable_order_ahead',
  MultiSelect = 'multiselect',
  SeparateSeats = 'separate_seats',
  EnablePromoCodes = 'enable_promo_codes'
}
