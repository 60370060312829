import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl
} from '@angular/platform-browser';

@Pipe({
  name: 'trust'
})
export class TrustPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(
    value: string | null | undefined,
    type: 'html' | 'style' | 'script' | 'url' | 'resourceUrl'
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl | void {
    if (value) {
      switch (type) {
        case 'html':
          return this.sanitizer.bypassSecurityTrustHtml(value);
        case 'style':
          return this.sanitizer.bypassSecurityTrustStyle(`url(${value})`);
        case 'script':
          return this.sanitizer.bypassSecurityTrustScript(value);
        case 'url':
          return this.sanitizer.bypassSecurityTrustUrl(value);
        case 'resourceUrl':
          return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        default:
          throw new Error(
            `Unable to bypass security for invalid type: ${type}`
          );
      }
    }
  }
}
