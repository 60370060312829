import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { V2ButtonColors } from '../../models/v2-button-colors.model';
import { V2ButtonTypes } from '../../models/v2-button-types.model';

@Component({
  selector: 'v2-button',
  templateUrl: 'v2-button.component.html',
  styleUrls: ['v2-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Button {
  @Input() color: V2ButtonColors = 'transparent';
  @Input() type: V2ButtonTypes = 'button';
  @Input() @HostBinding('class.disabled') disabled: boolean = false;
  @Input() @HostBinding('class.outline') outline: boolean = false;
  // TODO support circular button
}
