import { Component, OnInit } from '@angular/core';
import { V2BottomSheetRef } from '../../../../v2-components/helpers/v2-bottom-sheet-ref';
import { NavController } from '@ionic/angular';
import { GuestPresenceService } from 'services/guest-presence.service';
import { unawaited } from '../../../../utility/unawaited';
import { AppRoutingPreloaderService } from 'services/app-routing-preloader.service';
import { Location2Store } from 'stores/location2.store';

@Component({
  selector: 'guest-presence-qr-scan-sheet',
  templateUrl: 'guest-presence-qr-scan.sheet.html',
  styleUrls: ['guest-presence-qr-scan.sheet.scss']
})
export class GuestPresenceQrScanSheet implements OnInit {
  constructor(
    private bottomSheetRef: V2BottomSheetRef<boolean>,
    private navController: NavController,
    private guestPresenceService: GuestPresenceService,
    private routingService: AppRoutingPreloaderService,
    public location2Store: Location2Store
  ) {}

  ngOnInit(): void {
    unawaited(this.routingService.preloadRoute('scan-barcode'));
  }

  async goBack() {
    this.bottomSheetRef.close();
  }

  async goToScan() {
    await this.navController.navigateForward('scan-barcode', {
      queryParams: {
        [this.guestPresenceService.targetScanVerificationOnlyQueryParam]: true
      }
    });
    this.bottomSheetRef.close(true);
  }
}
