import { Component, Input } from '@angular/core';
import { ITotals } from '../../models';

@Component({
  selector: 'totals',
  templateUrl: 'totals.html',
  styleUrls: ['totals.scss']
})
export class TotalsComponent {
  @Input() totals: ITotals | undefined;
  @Input() amountPaid: number | undefined;
  @Input() tipAmountPaid: number | undefined;
  @Input() billIsPicked: boolean | undefined;
  @Input() currencyUnit: string | undefined;
  @Input() isPaid: boolean | undefined;
  @Input() isClaimed: boolean | undefined;
}
