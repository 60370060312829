export enum GatewayPaymentMethod {
  CreditCard = 'credit_card',
  GooglePay = 'google_pay',
  ApplePay = 'apple_pay',
  PayPal = 'paypal',
  OnAccount = 'accountpayment'
}

export enum GatewayPaymentMethodKeys {
  credit_card = 'CreditCard',
  google_pay = 'GooglePay',
  apple_pay = 'ApplePay',
  paypal = 'PayPal'
}

export enum StandardPaymentMethod {
  CreditCard = 'Credit Card',
  GooglePay = 'Google Pay',
  ApplePay = 'Apple Pay',
  GiftCard = 'Gift Card',
  PayPal = 'PayPal',
  OnAccount = 'On Account'
}

export enum MonerisPaymentMethod {
  CreditCard = 'credit-card',
  GooglePay = 'google-pay',
  ApplePay = 'apple-pay',
  PayPal = 'paypal'
}

export enum AdyenPaymentMethod {
  CreditCard = 'scheme',
  GooglePay = 'googlepay',
  ApplePay = 'applepay'
}

export type PaymentMethodOverrides =
  | GatewayPaymentMethod.PayPal
  | GatewayPaymentMethod.OnAccount;

export type DigitalWalletPaymentMethods =
  | GatewayPaymentMethod.GooglePay
  | GatewayPaymentMethod.ApplePay;

export enum PaymentProvider {
  IqPay = 'iqpay',
  Stripe = 'stripe',
  GlobalPay = 'realex',
  Moneris = 'moneris',
  Spreedly = 'spreedly',
  FreedomPay = 'freedompay',
  Bambora = 'bambora',
  JetPay = 'jetpay',
  Square = 'square',
  Adyen = 'adyen'
}
