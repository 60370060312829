import { Injectable } from '@angular/core';
import { InvoicesApi } from '../api/invoices.api';

@Injectable({
  providedIn: 'root'
})
export class ReceiptsService {
  constructor(private invoicesApi: InvoicesApi) {}

  public async emailReceipt(
    receiptId: string,
    emailAddress: string,
    subscribeToNewsletter: boolean = false
  ): Promise<any> {
    if (!emailAddress || emailAddress.endsWith('readytopay.net')) {
      return new Promise(function (_, reject) {
        reject('Cannot send an email to this address.');
      });
    }
    try {
      const res = await this.invoicesApi.emailReceipt(
        receiptId,
        emailAddress,
        subscribeToNewsletter
      );
      return res;
    } catch (error) {
      throw error;
    }
  }
}
