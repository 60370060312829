import { Component } from '@angular/core';
import { V2TextDisplay } from '../helpers/v2-text-display.extendable';

@Component({
  selector: 'v2-body-header-3-text',
  template: `
    <v2-text [color]="color" [lineClamp]="lineClamp">
      <ng-content></ng-content>
    </v2-text>
  `,
  styles: [
    `
      :host {
        display: block;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.35;
      }
    `
  ]
})
export class V2BodyHeader3Text extends V2TextDisplay {}
