import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'v2-card',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
      }

      :host.box-shadow {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);
      }

      :host.flat-bottom {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Card {
  @Input() @HostBinding('class.box-shadow') boxShadow: boolean = true;
  @Input() @HostBinding('class.flat-bottom') flatBottom: boolean = false;
}
