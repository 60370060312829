import { Component } from '@angular/core';

@Component({
  selector: 'full-screen-loading',
  template: `
    <object
      type="image/svg+xml"
      data="../../assets/images/Ready-spinner-white.svg"
      width="150px"
    ></object>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        background-color: rgba(0, 0, 0, 0.65);
        backdrop-filter: blur(1px);
      }
    `
  ]
})
export class FullScreenLoading {}
