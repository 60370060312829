import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Auth } from '@angular/fire/auth';
import { RaygunService } from 'services/raygun.service';
import { UserStore } from 'stores/user.store';
import { RxUtil } from 'utility/rx.util';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReadyHttpClient {
  constructor(
    private http: HttpClient,
    private auth: Auth,
    private raygunService: RaygunService,
    private userStore: UserStore
  ) {}

  async get<T>(path: string, options?: IRequestOptions): Promise<T> {
    return lastValueFrom(
      this.http.get<T>(
        `${environment.readyConfig.serverUrl}${path}`,
        await this.applyHeaders(options, path)
      )
    );
  }

  async post<T>(
    path: string,
    params?: Object,
    options?: IRequestOptions
  ): Promise<T> {
    return lastValueFrom(
      this.http.post<T>(
        `${environment.readyConfig.serverUrl}${path}`,
        params,
        await this.applyHeaders(options, path)
      )
    );
  }

  async patch<T>(
    path: string,
    params?: Object,
    options?: IRequestOptions
  ): Promise<T> {
    return lastValueFrom(
      this.http.patch<T>(
        `${environment.readyConfig.serverUrl}${path}`,
        params,
        await this.applyHeaders(options, path)
      )
    );
  }

  async delete<T>(path: string, options?: IRequestOptions): Promise<T> {
    return lastValueFrom(
      this.http.delete<T>(
        `${environment.readyConfig.serverUrl}${path}`,
        await this.applyHeaders(options, path)
      )
    );
  }

  private async applyHeaders(
    options: IRequestOptions = {},
    path: string
  ): Promise<IRequestOptions> {
    options.headers = new HttpHeaders({
      Authorization: `Bearer ${await this.getFirebaseAuthToken(path)}`,
      'X-Request-Id': uuid()
    });
    return options;
  }

  async postKeepalive<T>(path: string, params?: Object): Promise<T> {
    return fetch(`${environment.readyConfig.serverUrl}${path}`, {
      method: 'POST',
      headers: await this.applyFetchHeaders(path),
      keepalive: true,
      body: JSON.stringify(params)
    }).then((r) => r.json());
  }

  private async applyFetchHeaders(
    path: string
  ): Promise<Record<string, string>> {
    return {
      Authorization: `Bearer ${await this.getFirebaseAuthToken(path)}`,
      'X-Request-Id': uuid()
    };
  }

  private async getFirebaseAuthToken(path: string): Promise<string> {
    const firebaseUser = await RxUtil.takeOne(this.userStore.firebaseUser$);
    const authToken: string = await firebaseUser.getIdToken();
    if (!authToken) {
      this.raygunService.logToRayGun(
        new Error('authToken from getIdToken() is invalid for http request'),
        { path }
      );
    }
    return authToken;
  }
}

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}
