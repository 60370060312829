import { Injectable } from '@angular/core';
import {
  I18nPluralizationBundleRecordValue,
  I18nStore
} from 'stores/i18n.store';
import { TranslateService } from '@ngx-translate/core';
import { SupportedLocale } from '../ui-models/location.ui-model';
import { Location2Store } from 'stores/location2.store';

export enum I18nPluralizableWord {
  Item = 'Item',
  Option = 'Option',
  Check = 'Check',
  Seat = 'Seat'
}

interface pluralizationForms {
  singular: string;
  plural: string;
}

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private readonly pluralizableWordMap = new Map<
    I18nPluralizableWord,
    pluralizationForms
  >();

  private readonly pluralizationTranslationNotFoundMessage =
    'TRANSLATION NOT FOUND FOR PLURALIZATION';

  static readonly defaultLocale = SupportedLocale.EnUS;

  constructor(
    private i18nStore: I18nStore,
    private translateService: TranslateService,
    private location2Store: Location2Store
  ) {
    this.location2Store.currentLocation$.subscribe((currentLocation) =>
      this.translateService.use(currentLocation.locale)
    );
  }

  initPluralizationBundleRegistration() {
    this.i18nStore.pluralizationBundle$.subscribe((i18nBundle) => {
      for (const word in I18nPluralizableWord) {
        Object.keys(i18nBundle).forEach((pKey) => {
          if (pKey === word) {
            this.registerPluralizableWordTranslation(
              word as I18nPluralizableWord,
              i18nBundle[pKey]
            );
          }
        });
      }
    });
  }

  private registerPluralizableWordTranslation(
    word: I18nPluralizableWord,
    i18nRecordValue: I18nPluralizationBundleRecordValue
  ) {
    this.pluralizableWordMap.set(word, {
      singular: i18nRecordValue.Singular,
      plural: i18nRecordValue.Plural
    });
  }

  pluralize(
    word: I18nPluralizableWord,
    count: Array<unknown> | number
  ): string {
    if (Array.isArray(count)) {
      count = count.length;
    }

    const pluralizationForm = this.pluralizableWordMap.get(word);
    if (!pluralizationForm) {
      return this.pluralizationTranslationNotFoundMessage;
    }

    return count === 1 ? pluralizationForm.singular : pluralizationForm.plural;
  }

  isValidLocale(l?: string): l is SupportedLocale {
    return Object.values(SupportedLocale).includes(l as SupportedLocale);
  }
}
