import { Injectable } from '@angular/core';
import { FullScreenLoadingService } from './full-screen-loading.service';
import { InvoiceStore } from '../stores/invoice.store';
import { RequestInvoicePayloadCheck } from '../api/contracts/request-invoice-payload-check.contract';
import { IReceipt } from '../models/receipts';
import { RouterService } from './router.service';
import { unawaited } from '../utility/unawaited';
import { CustomerFlow } from '../pages/v2-order-pay/models/customer-flow.model';
import { ErrorService } from './error.service';
import { PaymentMethodOverrides } from '../pages/v2-order-pay/models/payment.model';
import { GiftCardStore } from '../stores/gift-card.store';
import { ChecksStore } from '../stores/checks.store';
import { InvoicesApi } from '../api/invoices.api';
import { RxUtil } from 'utility/rx.util';
import { environment } from 'src/environments/environment';
import { OrderPayStore } from 'stores/order-pay.store';
import { hasKey } from 'utility/unknown.util';
import { IPaymentResponse } from '../api/contracts/paymentresponse.contracts';
import { EmailSheetStore } from '../pages/v2-order-pay/components/v2-payment-options/common/email-sheet/email-sheet.store';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private timerStart: number | undefined;
  private timerId: number | undefined;
  private timerDuration: number = environment.readyConfig.leaseExpiryDuration;
  private cachedRequestInvoicePayloadTickets: RequestInvoicePayloadCheck[] = [];

  constructor(
    private errorService: ErrorService,
    private invoiceStore: InvoiceStore,
    private checksStore: ChecksStore,
    private giftCardStore: GiftCardStore,
    private orderPayStore: OrderPayStore,
    private invoicesApi: InvoicesApi,
    private emailSheetStore: EmailSheetStore
  ) {}

  async requestInvoice(
    tickets: IReceipt[],
    payloadTickets: RequestInvoicePayloadCheck[]
  ): Promise<void> {
    try {
      this.invoiceStore.setCurrentInvoiceChecks(tickets);
      this.cachedRequestInvoicePayloadTickets = payloadTickets;
      await this.invoicesApi.requestInvoice(payloadTickets);
    } catch (e) {
      unawaited(this.errorService.performDefaultErrorHandling(e));
    }
  }

  // we need to create a new invoice to apply loyalty rewards
  async requestInvoiceWithCachedPayloadForLoyalty(): Promise<void> {
    await this.invoicesApi.abandonInvoice();
    this.requestInvoiceWithCachedInvoicePayloadTickets();
  }

  async requestInvoiceWithCachedInvoicePayloadTickets(): Promise<void> {
    await this.invoicesApi.requestInvoice(
      this.cachedRequestInvoicePayloadTickets
    );
  }

  async abandonInvoice(requestKeepalive?: boolean): Promise<void> {
    if (this.invoiceStore.currentInvoice) {
      await (requestKeepalive
        ? this.invoicesApi.abandonInvoiceWithKeepalive()
        : this.invoicesApi.abandonInvoice());
      this.cancelExpiryTimer();

      this.invoiceStore.setAbandonedInvoice(this.invoiceStore.currentInvoice);
    }
  }

  async submitPaymentForInvoice(
    invoiceId: string,
    tip: number,
    useCredits: boolean,
    paymentCard: any,
    saveCard: boolean = false,
    providerOverride?: PaymentMethodOverrides
  ): Promise<IPaymentResponse> {
    let ticketID: string;

    const checks = await RxUtil.takeOne(this.checksStore.checks$);
    if (checks[0]) {
      ticketID = checks[0]._id;
    } else {
      ticketID = invoiceId;
    }

    const currentGiftCard = this.giftCardStore.currentGiftCard;
    const giftCardAuth = currentGiftCard
      ? {
          accountNumber: currentGiftCard.accountNumber,
          pinNumber: currentGiftCard.pinNumber
        }
      : null;

    const emailAddress = this.emailSheetStore.userEnteredEmail;

    const payload = {
      tip,
      tipType: 'amount',
      paymentCard,
      useCredits,
      saveCard,
      giftCardAuth,
      providerOverride,
      emailAddress
    };

    const result = await this.invoicesApi.submitPayment(
      this.orderPayStore.beaconId,
      ticketID,
      invoiceId,
      payload
    );

    if (result.success) {
      this.invoiceStore.setCurrentInvoice(result.data);
    }

    return result;
  }

  startExpiryTimer() {
    this.timerStart = Date.now();
    this.timerId = window.setTimeout(() => {
      unawaited(this.abandonInvoice());
      this.timerId = undefined;
    }, this.timerDuration);
  }

  cancelExpiryTimer() {
    window.clearTimeout(this.timerId);
  }

  async extendExpiryTimer(
    duration = environment.readyConfig.leaseRenewalDuration
  ) {
    if (this.timerId) {
      this.cancelExpiryTimer();
      const elapsedTime = Date.now() - (this.timerStart || 0);
      const remainingTime = this.timerDuration - elapsedTime;
      this.timerDuration = remainingTime + duration;
    } else {
      this.timerDuration = environment.readyConfig.leaseExpiryDuration;
    }

    this.startExpiryTimer();
    try {
      await this.invoicesApi.renewInvoice(duration);
    } catch (error) {
      throw new Error(
        (hasKey(error, 'message') && typeof error.message === 'string'
          ? error.message
          : null) ?? 'unknown error while renewing invoice'
      );
    }
  }

  public getCardHashRequest(
    tableId: string,
    invoiceId: string,
    ticketId: string,
    order: any
  ): Promise<unknown> {
    let beaconId = this.orderPayStore.beaconId
      ? this.orderPayStore.beaconId
      : tableId;
    let body = { ...order };

    return this.invoicesApi.getCardHashRequest(
      beaconId,
      ticketId,
      invoiceId,
      body
    );
  }
}
