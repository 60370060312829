import { Injectable } from '@angular/core';
import { StorageService } from 'services/storage.service';
import { V2BottomSheetRef } from '../v2-components/helpers/v2-bottom-sheet-ref';
import { PhoneVerification1Sheet } from '../pages/v2-order-pay/bottom-sheets/order-phone-verification-1-sheet/phone-verification-1.sheet';
import { UserStore } from 'stores/user.store';
import { BottomSheetService } from '../v2-components/services/bottom-sheet.service';
import { Location2Store } from 'stores/location2.store';
import { GuestPresenceValidationMethods } from '../ui-models/location.ui-model';
import { GuestPresenceQrScanSheet } from '../pages/v2-order-pay/bottom-sheets/guest-presence-qr-scan/guest-presence-qr-scan.sheet';
import { GuestPresenceStore } from 'stores/guest-presence.store';
import { RxUtil } from 'utility/rx.util';
import { AsyncUtil } from 'utility/async.util';

@Injectable({
  providedIn: 'root'
})
export class GuestPresenceService {
  readonly targetScanVerificationOnlyQueryParam = 'guestVerificationOnly';
  readonly targetScanVerificationSuccessPageTimeMs = 1500;

  private readonly targetScanStorageKey = 'gpv-targetScan';

  constructor(
    private storageService: StorageService,
    private userStore: UserStore,
    private bottomSheetService: BottomSheetService,
    private location2Store: Location2Store,
    private guestPresenceStore: GuestPresenceStore
  ) {}

  async registerValidatedGuestPresenceFromTargetScan(): Promise<void> {
    this.storageService.set(this.targetScanStorageKey, true, {
      minutes: (await this.location2Store.getCurrentLocation())
        .openTabGuestPresenceTargetScanDurationMinutes
    });
  }

  isGuestValidatedWithTargetScan(): boolean {
    return !!this.storageService.get(this.targetScanStorageKey);
  }

  async validateWithPhone(): Promise<boolean> {
    if (!(await this.requiresValidationMethod('phone'))) {
      return true;
    }

    if (
      this.userStore.currentUser?.phone &&
      this.userStore.currentUser.phoneVerified
    ) {
      return true;
    }

    const phoneSheet1Ref =
      this.bottomSheetService.showBottomSheet<V2BottomSheetRef<
        boolean | void
      > | void>(PhoneVerification1Sheet);

    const phoneSheet2Ref = await RxUtil.takeOne(phoneSheet1Ref.onClose$);

    if (phoneSheet2Ref) {
      return !!(await RxUtil.takeOne(phoneSheet2Ref.onClose$));
    }

    return false;
  }

  async validateWithTargetScan(): Promise<boolean> {
    if (!(await this.requiresValidationMethod('targetScan'))) {
      return true;
    }

    if (this.isGuestValidatedWithTargetScan()) {
      return true;
    }

    const sheetRef = this.bottomSheetService.showBottomSheet<boolean>(
      GuestPresenceQrScanSheet
    );
    const hasProceededThroughSheet = await RxUtil.takeOne(sheetRef.onClose$);
    if (!hasProceededThroughSheet) {
      return false;
    }
    const isScanSuccess = await RxUtil.takeOne(
      this.guestPresenceStore.targetScanCompletionEvent$
    );
    if (isScanSuccess) {
      await AsyncUtil.sleep(this.targetScanVerificationSuccessPageTimeMs);
    }

    return this.isGuestValidatedWithTargetScan();
  }

  async validateAllMethods(): Promise<boolean> {
    return (
      (await this.validateWithPhone()) && (await this.validateWithTargetScan())
    );
  }

  private async requiresValidationMethod(
    method: GuestPresenceValidationMethods
  ): Promise<boolean> {
    return (
      await this.location2Store.getCurrentLocation()
    ).openTabGuestPresenceValidationMethods.includes(method);
  }
}
