import { Injectable, Type } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { Location2Store } from 'stores/location2.store';
import { RxUtil } from 'utility/rx.util';

@Injectable({
  providedIn: 'root'
})
export class EmailSheetStore {
  private readonly _userEnteredEmail = new BehaviorSubject<string | undefined>(
    undefined
  );

  readonly userEnteredEmail$: Observable<string> = this._userEnteredEmail.pipe(
    filter(RxUtil.inputIsNotNullOrUndefined)
  );

  get userEnteredEmail(): string | undefined {
    return this._userEnteredEmail.getValue();
  }

  set userEnteredEmail(v: string | undefined) {
    this._userEnteredEmail.next(v);
  }

  private readonly _backButtonCallback = new BehaviorSubject<
    (() => void) | undefined
  >(undefined);

  readonly backButtonCallback$: Observable<() => void> =
    this._backButtonCallback.pipe(filter(RxUtil.inputIsNotNullOrUndefined));

  get backButtonCallback(): (() => void) | undefined {
    return this._backButtonCallback.getValue();
  }

  set backButtonCallback(callback: (() => void) | undefined) {
    this._backButtonCallback.next(callback);
  }
}
