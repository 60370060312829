import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Self,
  SimpleChanges
} from '@angular/core';
import { V2Color } from '../../models/v2-colors.model';
import {
  HostClass,
  hostClassProviders
} from '../../directives/host-class.directive';

@Component({
  selector: 'v2-card-header',
  templateUrl: 'v2-card-header.component.html',
  styleUrls: ['v2-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: hostClassProviders
})
export class V2CardHeader implements OnInit, OnChanges {
  @Input() subtitle: string = '';
  @Input() @HostBinding('class.opaque') opaque: boolean = false;
  @Input() opaqueColor: V2Color = 'off-white';

  constructor(@Self() private hostClass: HostClass) {}

  ngOnInit(): void {
    this.hostClass.apply(`opaque-color-${this.opaqueColor}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['opaqueColor']) {
      this.hostClass.apply(`opaque-color-${this.opaqueColor}`);
    }
  }
}
