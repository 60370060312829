import { Component, OnInit } from '@angular/core';
import { UiService } from '../../../../services/ui.service';
import { FormControl, Validators } from '@angular/forms';
import { V2BottomSheetRef } from '../../../../v2-components/helpers/v2-bottom-sheet-ref';
import { UserService } from '../../../../services/user.service';
import { RaygunService } from '../../../../services/raygun.service';
import { FullScreenLoadingService } from '../../../../services/full-screen-loading.service';
import { BottomSheetService } from '../../../../v2-components/services/bottom-sheet.service';
import { PhoneVerification2Sheet } from '../order-phone-verification-2-sheet/phone-verification-2.sheet';
import { AnalyticsService } from '../../../../services/analytics.service';

@Component({
  selector: 'phone-verification-1',
  templateUrl: 'phone-verification-1.sheet.html',
  styleUrls: ['phone-verification-1.sheet.scss']
})
export class PhoneVerification1Sheet implements OnInit {
  readonly phoneInputControl = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      this.uiService.phoneNumberValidator
    ])
  );

  isSubmitting = false;

  constructor(
    private uiService: UiService,
    public bottomSheetRef: V2BottomSheetRef<V2BottomSheetRef>,
    private userService: UserService,
    private raygunService: RaygunService,
    private fullScreenLoadingService: FullScreenLoadingService,
    private bottomSheetService: BottomSheetService,
    private analyticsProvider: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.analyticsProvider.log('Phone verification sheet 1 shown');
  }

  onPhoneInput(event: Event) {
    this.uiService.limitPhoneNumberLength(event.target);
    this.uiService.formatPhoneInputAsYouType(event.target);
  }

  async onSubmit(): Promise<void> {
    this.phoneInputControl.markAsTouched();
    if (!this.phoneInputControl.valid) return;

    this.isSubmitting = true;
    this.fullScreenLoadingService.showFullScreenLoader();
    this.phoneInputControl.setErrors(null);
    try {
      await this.userService.submitPhoneNumber(this.phoneInputControl.value);
      this.bottomSheetRef.close(
        this.bottomSheetService.showBottomSheet(PhoneVerification2Sheet)
      );
    } catch (e) {
      this.raygunService.logToRayGun(e);
      this.phoneInputControl.setErrors({ invalid: true });
    } finally {
      this.isSubmitting = false;
      this.fullScreenLoadingService.hideFullScreenLoader();
    }
  }
}
