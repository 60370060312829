import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppErrorHandler } from './app-error-handler';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { isProdEnvironment } from './utility';
import { IonicModule } from '@ionic/angular';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DiscountTypeShorthandPipe } from './pipes/discount-type-shorthand.pipe';
import { V2CurrencyPipe } from './v2-components/pipes/v2-currency.pipe';
import { OrderModifierPricePipe } from './pipes/order-modifier-price.pipe';
import { V2ComponentsModule } from './v2-components/v2-components.module';
import { NgxPayPalModule } from 'ngx-paypal';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { I18nService } from 'services/i18n.service';
import packageJson from '../../package.json';
import { ComponentsModule } from './components/components.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    HttpClientModule,
    IonicModule.forRoot({
      swipeBackEnabled: true,
      scrollPadding: true,
      scrollAssist: true,
      mode: 'md'
    }),
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    V2ComponentsModule,
    ComponentsModule,
    NgxPayPalModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 15 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:15000'
    }),
    TranslateModule.forRoot({
      defaultLanguage: I18nService.defaultLocale,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: ErrorHandler,
      useClass: isProdEnvironment() ? AppErrorHandler : ErrorHandler
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.RecaptchaConfig.siteKey
    },

    // injected pipes
    CurrencyPipe,
    DiscountTypeShorthandPipe,
    V2CurrencyPipe,
    OrderModifierPricePipe,
    DatePipe
  ]
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    `.json?v=${packageJson.version}`
  );
}
