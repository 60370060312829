import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { V2ChipSizes } from '../../models/v2-chip-sizes.model';
import { V2Color } from '../../models/v2-colors.model';

// TODO split v2-chip into 2 variations, one for text only and one for text + icon.
// TODO text should be passed via input instead of transcluded.
@Component({
  selector: 'v2-chip',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['v2-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Chip {
  @Input() size: V2ChipSizes = 'medium';
  @Input() color: V2Color = 'medium-grey';
  @Input() @HostBinding('class.outline') outline: boolean = false;

  @HostBinding('class') private get class() {
    return `size-${this.size} color-${this.color}`;
  }
}
