import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class V2InputErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return !!(
      control &&
      control.invalid &&
      (control.touched || (form && form.submitted))
    );
  }
}
