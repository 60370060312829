import {
  Component,
  Input,
  OnChanges,
  Self,
  SimpleChanges
} from '@angular/core';
import { V2Color } from '../../models/v2-colors.model';
import {
  HostClass,
  hostClassProviders
} from '../../directives/host-class.directive';
import { V2Icon as V2IconT } from '../../models/v2-icons.model';
import { nil } from '../../helpers/nil.helper';

// TODO reconsider adding a size atr
@Component({
  selector: 'v2-icon',
  template: ` <v2-text [color]="color"></v2-text> `,
  styleUrls: ['v2-icon.component.scss'],
  providers: hostClassProviders
})
export class V2Icon implements OnChanges {
  @Input() icon: V2IconT | nil;
  @Input() color: V2Color | nil = 'black';

  constructor(@Self() private hostClass: HostClass) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['icon']) {
      this.hostClass.apply(`icon-${this.icon}`);
    }
  }
}
