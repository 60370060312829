import { Component, Input } from '@angular/core';

@Component({
  selector: 'badge',
  templateUrl: 'badge.html',
  styleUrls: ['badge.scss']
})
export class BadgeComponent {
  @Input() type: 'paid' = 'paid';
  @Input() animate: boolean = true;
}
