import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenBrandName'
})
export class ShortenBrandNamePipe implements PipeTransform {
  public transform(brandName: string): string {
    if (brandName) {
      switch (brandName.toLowerCase()) {
        case 'american express':
          return 'Amex';
        case 'mastercard':
          return 'MC';
        case 'diners club':
          return 'DC';
        default:
          return brandName;
      }
    }
    return '';
  }
}
