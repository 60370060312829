import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { ILocationServerFlow, ITableData } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TableStore {
  private _currentTable$ = new BehaviorSubject<ITableData | null>(null);
  public readonly currentTable$ = this._currentTable$.asObservable();

  get currentTable(): ITableData | null {
    return this._currentTable$.getValue();
  }

  get serverFlowConfig$(): Observable<ILocationServerFlow | undefined> {
    return this._currentTable$.pipe(
      map((table) => table?.location?.serverFlow)
    );
  }

  setCurrentTable(table: ITableData) {
    this._currentTable$.next(table);
  }

  clear() {
    this._currentTable$.next(null);
  }

  private _areCatchAllPartialPaymentsAllowed = new BehaviorSubject<boolean>(
    false
  );

  /** This flag is used in the catch all table flow to enable partial payments.
   * Should only be enabled when a user uses the catch-all-table.page.ts flow.
   */
  get areCatchAllPartialPaymentsAllowed(): boolean {
    return this._areCatchAllPartialPaymentsAllowed.getValue();
  }

  set areCatchAllPartialPaymentsAllowed(
    areCatchAllPartialPaymentsAllowed: boolean
  ) {
    this._areCatchAllPartialPaymentsAllowed.next(
      areCatchAllPartialPaymentsAllowed
    );
  }
}
