import { HostBinding, Input, Directive } from '@angular/core';
import { V2Color } from '../models/v2-colors.model';
import { V2TextAlign } from '../models/v2-text-align.model';
import { nil } from './nil.helper';

@Directive()
export class V2TextDisplay {
  @Input() @HostBinding('style.text-align') textAlign: V2TextAlign = 'left';
  @Input() lineClamp: number | nil;
  @Input() color: V2Color | string | nil = 'black';
}
