import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  IOrderAheadTimes,
  IOrderAheadTimeSlot
} from '../models/order-ahead-times.model';
import { filter, map } from 'rxjs/operators';
import { TimeSlotConflictSuggestions } from '../models/time-slot-conflict-suggestions.model';
import {
  format,
  formatISO,
  isSameDay,
  isWithinInterval,
  parseISO,
  roundToNearestMinutes
} from 'date-fns';
import { LocationStore } from './location.store';
import { TimeService } from 'services/time.service';
import { IOrderAheadUiModel } from '../ui-models/order-ahead.ui-model';
import { RxUtil } from 'utility/rx.util';
import { FeaturesStore } from './features.store';
import { Location2Store } from './location2.store';
import {
  IOrderHoursUiModel,
  LocationUiModel
} from '../ui-models/location.ui-model';
import { zonedTimeToUtc } from 'date-fns-tz';

export interface ILocalOrderHours {
  day: number;
  startHourMin: [number, number];
  endHourMin: [number, number];
  passedMidnight: boolean;
  closedForDay: boolean;
}

export interface INextOrderHours {
  start: Date;
  end: Date;
}

@Injectable({
  providedIn: 'root'
})
export class OrderAheadStore {
  constructor(
    private locationStore: LocationStore,
    private timeService: TimeService,
    private featuresStore: FeaturesStore,
    private location2Store: Location2Store
  ) {}

  private readonly _orderAheadTimes$ =
    new BehaviorSubject<IOrderAheadTimes | null>(null);
  readonly orderAheadTimes$ = this._orderAheadTimes$.asObservable();

  setOrderAheadTimes(times: IOrderAheadTimes) {    
    this._orderAheadTimes$.next(times);
  }

  readonly timeSlots$: Observable<IOrderAheadTimeSlot[]> =
    this.orderAheadTimes$.pipe(
      map((orderAheadTimes) => orderAheadTimes?.timeSlots ?? [])
    );

  private readonly _timeSlotConflictSuggestions$: BehaviorSubject<TimeSlotConflictSuggestions> =
    new BehaviorSubject<TimeSlotConflictSuggestions>([null, null]);
  readonly timeSlotConflictSuggestions$: Observable<TimeSlotConflictSuggestions> =
    this._timeSlotConflictSuggestions$.asObservable();

  setTimeSlotConflictSuggestions(
    pastSlot: string | null,
    futureSlot: string | null
  ) {
    this._timeSlotConflictSuggestions$.next([pastSlot, futureSlot]);
  }

  private readonly _allowedToSendSelectedTimeToAPI$ = new BehaviorSubject(
    false
  );

  set allowedToSendSelectedTimeToAPI(v: boolean) {
    this._allowedToSendSelectedTimeToAPI$.next(v);
  }

  private readonly _selectedTime$ = new BehaviorSubject<Date | null>(null);

  setSelectedTime(time: Date | string | null) {
    this._selectedTime$.next(typeof time === 'string' ? parseISO(time) : time);
  }

  private readonly _selectedDay$ = new BehaviorSubject<Date | null>(null);
  readonly selectedDay$ = this._selectedDay$.asObservable();

  setSelectedDay(time: Date | string | null) {
    this._selectedDay$.next(typeof time === 'string' ? parseISO(time) : time);
  }

  readonly selectedDayISOString$: Observable<string | null> =
    this.selectedDay$.pipe(
      map((selectedDay) => (selectedDay ? formatISO(selectedDay) : null))
    );

  readonly minSelectableDay$: Observable<string | undefined> =
    this.timeSlots$.pipe(
      map((timeSlots) => this.renderSlotForMinMaxSelectableDay(timeSlots[0]))
    );

  readonly maxSelectableDay$: Observable<string | undefined> =
    this.timeSlots$.pipe(
      map((timeSlots) =>
        this.renderSlotForMinMaxSelectableDay(timeSlots[timeSlots.length - 1])
      )
    );

  private renderSlotForMinMaxSelectableDay(
    timeSlot: IOrderAheadTimeSlot | undefined
  ): string | undefined {
    return timeSlot
      ? format(parseISO(timeSlot.startTime), this.dayMonthYearDateFormat)
      : undefined;
  }

  private readonly dayMonthYearDateFormat = 'yyyy-MM-dd';

  readonly timeSlotForSelectedDay$: Observable<
    IOrderAheadTimeSlot | undefined
  > = combineLatest([this.timeSlots$, this.selectedDay$]).pipe(
    map(([timeSlots, selectedDay]) => {
      if (selectedDay) {
        return timeSlots.find((timeSlot) =>
          isSameDay(parseISO(timeSlot.startTime), selectedDay)
        );
      }
      return timeSlots[0];
    })
  );

  readonly selectedTime$: Observable<Date | null> = combineLatest([
    this._selectedTime$,
    this.timeSlotForSelectedDay$
  ]).pipe(
    map(([selectedTime, timeSlotForSelectedDay]) => {
      if (selectedTime) {
        return selectedTime;
      } else if (timeSlotForSelectedDay) {
        return parseISO(timeSlotForSelectedDay.startTime);
      }
      return null;
    })
  );

  readonly selectedTimeISOString$: Observable<string | null> =
    this.selectedTime$.pipe(
      map((selectedTime) => (selectedTime ? selectedTime.toISOString() : null))
    );

  readonly selectedDateStringToSendToAPI$: Observable<string | null> =
    combineLatest([
      this.selectedTimeISOString$,
      this._allowedToSendSelectedTimeToAPI$
    ]).pipe(
      map(([selectedTimeISOString, allowedToSendSelectedTimeToAPI]) =>
        allowedToSendSelectedTimeToAPI ? selectedTimeISOString : null
      )
    );

  readonly humanReadableSelectedTime$: Observable<string> =
    this.selectedTime$.pipe(
      map((selectedTime) => this.timeService.getReadableTime(selectedTime))
    );

  readonly isValidSelectedDay$: Observable<boolean> =
    this.timeSlotForSelectedDay$.pipe(
      map((timeSlotForSelectedDay) => {
        if (timeSlotForSelectedDay) {
          // days with matching start and end times are considered closed
          return (
            timeSlotForSelectedDay.startTime !== timeSlotForSelectedDay.endTime
          );
        }
        return true;
      })
    );

  // valid if between start and end time, or not selected
  readonly isValidSelectedTime$: Observable<boolean> = combineLatest([
    this.timeSlotForSelectedDay$,
    this.selectedTime$
  ]).pipe(
    map(([timeSlotForSelectedDay, selectedTime]) =>
      selectedTime
        ? isWithinInterval(selectedTime, {
            start: roundToNearestMinutes(
              parseISO(timeSlotForSelectedDay?.startTime ?? '')
            ),
            end: roundToNearestMinutes(
              parseISO(timeSlotForSelectedDay?.endTime ?? '')
            )
          })
        : true
    )
  );

  readonly hasDayOrTimeSelectionError$: Observable<boolean> = combineLatest([
    this.isValidSelectedDay$,
    this.isValidSelectedTime$
  ]).pipe(
    map(
      ([isValidSelectedDay, isValidSelectedTime]) =>
        !isValidSelectedDay || !isValidSelectedTime
    )
  );

  readonly timeMinuteInterval$: Observable<number> =
    this.locationStore.currentLocation$.pipe(
      map((location) => {
        if (
          location.rateLimits?.orderAhead?.enabled &&
          typeof location.rateLimits.orderAhead.timeIntervalMinutes === 'number'
        ) {
          return location.rateLimits.orderAhead.timeIntervalMinutes;
        }
        // default 1 minute interval
        return 1;
      })
    );

  private readonly _orderAheadConfigs$: BehaviorSubject<IOrderAheadUiModel> =
    new BehaviorSubject<IOrderAheadUiModel>({
      acceptOrdersFor: 'sameDayOnly',
      futureDaysLimit: 0
    });

  readonly orderAheadConfigs$: Observable<IOrderAheadUiModel> =
    this._orderAheadConfigs$.pipe(filter(RxUtil.inputIsNotNullOrUndefined));

  getOrderAheadConfig(): IOrderAheadUiModel {
    return this._orderAheadConfigs$.getValue();
  }

  setOrderAheadConfig(v: IOrderAheadUiModel) {
    this._orderAheadConfigs$.next(v);
  }

  readonly isOrderAheadForFutureDaysEnabled$: Observable<boolean> =
    combineLatest([
      this._orderAheadConfigs$,
      this.featuresStore.isOrderingAheadEnabled$
    ]).pipe(
      map(
        ([orderAheadConfigs, isOrderingAheadEnabled]) =>
          isOrderingAheadEnabled &&
          orderAheadConfigs?.acceptOrdersFor === 'futureDays'
      )
    );

  readonly localOrderHours$: Observable<ILocalOrderHours[]> =
    this.location2Store.currentLocation$.pipe(
      map((location) => this.getLocalHours(location))
    );

  private getLocalTimeZone(locationTimeZone: string) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimeZone || locationTimeZone;
  }

  private getLocalHours(location: LocationUiModel): ILocalOrderHours[] {
    const localOrderHours: ILocalOrderHours[] = [];
    const localNow = new Date();
    location.orderHours.forEach((oh) => {
      const zonedStart = new Date(localNow);
      zonedStart.setHours(oh.startHour, oh.startMinute, 0);
      const zonedEnd = new Date(localNow);
      zonedEnd.setHours(oh.endHour, oh.endMinute, 0);

      const hasDuration = zonedEnd.getTime() - zonedStart.getTime() > 0;
      const closedForDay = oh?.disabled === true || !hasDuration;

      const localizedStart =
        location.timezone !== ''
          ? zonedTimeToUtc(zonedStart, this.getLocalTimeZone(location.timezone))
          : zonedStart;

      const localizedEnd =
        location.timezone !== ''
          ? zonedTimeToUtc(zonedEnd, this.getLocalTimeZone(location.timezone))
          : zonedEnd;

      const localOrderHour: ILocalOrderHours = {
        day: oh.day,
        startHourMin: [localizedStart.getHours(), localizedStart.getMinutes()],
        endHourMin: [localizedEnd.getHours(), localizedEnd.getMinutes()],
        passedMidnight: localizedEnd.getHours() < localizedStart.getHours(),
        closedForDay
      };

      localOrderHours.push(localOrderHour);
    });

    return localOrderHours;
  }

  readonly isCurrentLocationOpenNow$: Observable<boolean> =
    this.localOrderHours$.pipe(
      map((localOrderHours: ILocalOrderHours[]) => {
        const localNow = new Date();
        const todaysHours = localOrderHours.find(
          (x) => x.day === localNow.getDay()
        );

        if (!todaysHours || todaysHours.closedForDay) {
          return false;
        }

        const [todayHoursStart, todayHoursEnd] = this.getHoursForDay(
          localNow,
          todaysHours
        );

        return isWithinInterval(localNow, {
          start: todayHoursStart,
          end: todayHoursEnd
        });
      })
    );

  readonly nextOrderHours$: Observable<INextOrderHours | null> =
    this.localOrderHours$.pipe(
      map((localOrderHours) => this.getNextOrderHours(localOrderHours))
    );

  private getNextOrderHours(localOrderHours: ILocalOrderHours[]): {
    start: Date;
    end: Date;
  } | null {
    const nowDate = new Date();
    const workingDate = new Date();
    let day = workingDate.getDay();

    for (let i = 0; i <= 7; i++) {
      const orderHoursForDay = localOrderHours.find((x) => x.day === day);

      if (orderHoursForDay && !orderHoursForDay.closedForDay) {
        const [start, end] = this.getHoursForDay(workingDate, orderHoursForDay);

        if (nowDate <= end) {
          return {
            start,
            end
          };
        }
      }

      day = (day + 1) % 7;
      workingDate.setDate(workingDate.getDate() + 1);
    }

    return null;
  }

  private getHoursForDay(
    current: Date,
    dayLocalOrderHours: ILocalOrderHours
  ): [Date, Date] {
    const currentStart = new Date();
    currentStart.setFullYear(
      current.getFullYear(),
      current.getMonth(),
      current.getDate()
    );

    const [startHour, startMin] = dayLocalOrderHours.startHourMin;
    currentStart.setHours(startHour, startMin, 0, 0);

    const currentEnd = new Date();
    currentEnd.setFullYear(
      current.getFullYear(),
      current.getMonth(),
      current.getDate() + (dayLocalOrderHours.passedMidnight ? 1 : 0)
    );

    const [endHour, endMin] = dayLocalOrderHours.endHourMin;
    currentEnd.setHours(endHour, endMin, 0, 0);

    return [currentStart, currentEnd];
  }
}
