import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { nil } from '../../helpers/nil.helper';

// TODO update the ControlValueAccessor types when updating angular
// TODO replace this component with a v2-form-field style component
@Component({
  selector: 'v2-textarea',
  templateUrl: 'v2-textarea.component.html',
  styleUrls: ['v2-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2Textarea),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Textarea implements ControlValueAccessor {
  @Input() placeholder: string | nil;
  @Input() maxLength: number | nil;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  value?: string;
  disabled: boolean = false;
  onChange: (value: any) => void = () => {};
  onTouched: () => any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
