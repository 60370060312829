export class SerializationUtil {
  // this serializes to JSON, removing any cyclical references
  static safeJSONStringify(
    value: unknown,
    replacer?: Function
  ): string | undefined {
    return JSON.stringify(this.decycle(value, replacer));
  }

  // this method serializes to JSON using the safe method, then parses it back to an object
  static toSafeJSONObject<T>(value: T): T {
    const s = this.safeJSONStringify(value);
    return s === undefined ? null : JSON.parse(s);
  }

  // This method removes cyclical references from an object. Do not modify it.
  // https://github.com/douglascrockford/JSON-js/blob/master/cycle.js
  private static decycle(object: unknown, replacer?: Function): string {
    const objects = new WeakMap();

    // @ts-ignore
    return (function derez(value, path) {
      let old_path;
      // @ts-ignore
      let nu;

      if (replacer !== undefined) {
        value = replacer(value);
      }

      if (
        typeof value === 'object' &&
        value !== null &&
        !(value instanceof Boolean) &&
        !(value instanceof Date) &&
        !(value instanceof Number) &&
        !(value instanceof RegExp) &&
        !(value instanceof String)
      ) {
        old_path = objects.get(value);
        if (old_path !== undefined) {
          return { $ref: old_path };
        }

        objects.set(value, path);

        if (Array.isArray(value)) {
          nu = [];
          value.forEach(function (element, i) {
            nu[i] = derez(element, path + '[' + i + ']');
          });
        } else {
          nu = {};
          Object.keys(value).forEach(function (name) {
            // @ts-ignore
            nu[name] = derez(
              // @ts-ignore
              value[name],
              path + '[' + JSON.stringify(name) + ']'
            );
          });
        }
        return nu;
      }
      return value;
    })(object, '$');
  }
}
