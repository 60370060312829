import { NgModule } from '@angular/core';
import { V2Row } from './core/v2-row.component';
import { V2Column } from './core/v2-column.component';
import { V2Button } from './core/v2-button/v2-button.component';
import { V2Card } from './core/v2-card.component';
import { V2CardFooter } from './core/v2-card-footer.component';
import { V2ListTile } from './core/v2-list-tile/v2-list-tile.component';
import { V2Alert } from './core/v2-alert/v2-alert.component';
import { V2Divider } from './core/v2-divider.component';
import { V2Text } from './core/v2-text/v2-text.component';
import { V2Icon } from './core/v2-icon/v2-icon.component';
import { V2Announcement } from './core/v2-announcement/v2-announcement.component';
import { V2HeaderLargeText } from './typography/v2-header-large.text';
import { V2HeaderSecondaryText } from './typography/v2-header-secondary.text';
import { V2BodyHeader3Text } from './typography/v2-body-header-3.text';
import { V2BodyHeaderHeavyText } from './typography/v2-body-header-heavy.text';
import { V2BodyText } from './typography/v2-body.text';
import { V2BodySmallText } from './typography/v2-body-small.text';
import { V2LabelText } from './typography/v2-label.text';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { V2CurrencyPipe } from './pipes/v2-currency.pipe';
import { V2CardHeader } from './core/v2-card-header/v2-card-header.component';
import { V2LabelSmallText } from './typography/v2-label-small.text';
import { V2LabelSecondaryText } from './typography/v2-label-secondary.text';
import { V2LabelSmallSecondaryText } from './typography/v2-label-small-secondary.text';
import { V2BodyHeader0Text } from './typography/v2-body-header-0.text';
import { V2BodyHeader2Text } from './typography/v2-body-header-2.text';
import { V2BodyLargeText } from './typography/v2-body-large.text';
import { V2BottomSheet } from './core/v2-bottom-sheet/v2-bottom-sheet.component';
import { ComponentInsertionDirective } from './directives/component-insertion.directive';
import { V2CardSubheader } from './core/v2-card-subheader/v2-card-subheader.component';
import { V2Checkbox } from './core/v2-checkbox/v2-checkbox.component';
import { V2BodyHeader1Text } from './typography/v2-body-header-1.text';
import { HostClass } from './directives/host-class.directive';
import { V2ProgressSpinner } from './core/v2-progress-spinner/v2-progress-spinner.component';
import { V2ScrollableBottomSheetVariationHeader } from './core/v2-scrollable-bottom-sheet-variation-header/v2-scrollable-bottom-sheet-variation-header.component';
import { V2Chip } from './core/v2-chip/v2-chip.component';
import { V2Toast } from './core/v2-toast/v2-toast.component';
import { V2ToastSuccess } from './core/v2-toast/v2-toast-success/v2-toast-success.component';
import { V2ToastError } from './core/v2-toast/v2-toast-error/v2-toast-error.component';
import { V2ToastInfo } from './core/v2-toast/v2-toast-info/v2-toast-info.component';
import { V2Center } from './core/v2-center.component';
import { V2Textarea } from './core/v2-textarea/v2-textarea.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { V2ScrollableBottomSheetVariationFooter } from './core/v2-scrollable-bottom-sheet-variation-footer/v2-scrollable-bottom-sheet-variation-footer.component';
import { V2FormField } from './core/v2-form-field/v2-form-field.component';
import { V2InputDirective } from './directives/v2-input.directive';
import { V2InputErrorStateMatcher } from './helpers/v2-input-error-state-matcher';
import { V2ErrorText } from './typography/v2-error.text';
import { V2HorizontalSlider } from './core/v2-horizontal-slider/v2-horizontal-slider.component';
import { V2SliderChildDirective } from './directives/v2-slider-child.directive';
import { V2DesktopDragToScrollDirective } from './directives/v2-desktop-drag-to-scroll.directive';
import { V2RippleDirective } from './directives/v2-ripple.directive';
import { V2AccordionListItemComponent } from './accordion-list-item/accordion-list-item';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
  declarations: [
    // v2 core components
    V2Row,
    V2Column,
    V2Center,
    V2Button,
    V2Card,
    V2CardHeader,
    V2CardSubheader,
    V2CardFooter,
    V2ListTile,
    V2Checkbox,
    V2Alert,
    V2Divider,
    V2Text,
    V2Icon,
    V2ProgressSpinner,
    V2Chip,
    V2BottomSheet,
    V2ScrollableBottomSheetVariationHeader,
    V2ScrollableBottomSheetVariationFooter,
    V2Announcement,
    V2Toast,
    V2ToastSuccess,
    V2ToastInfo,
    V2ToastError,
    V2Textarea,
    V2FormField,
    V2HorizontalSlider,

    // v2 typography components
    V2HeaderLargeText,
    V2HeaderSecondaryText,
    V2BodyHeader0Text,
    V2BodyHeader1Text,
    V2BodyHeader2Text,
    V2BodyHeader3Text,
    V2BodyHeaderHeavyText,
    V2BodyText,
    V2BodySmallText,
    V2BodyLargeText,
    V2LabelText,
    V2LabelSmallText,
    V2LabelSecondaryText,
    V2LabelSmallSecondaryText,
    V2ErrorText,

    // directives
    ComponentInsertionDirective,
    HostClass,
    V2InputDirective,
    V2SliderChildDirective,
    V2DesktopDragToScrollDirective,
    V2RippleDirective,

    // pipes
    V2CurrencyPipe,

    V2AccordionListItemComponent
  ],
  exports: [
    // v2 core components
    V2Row,
    V2Column,
    V2Center,
    V2Button,
    V2Card,
    V2CardHeader,
    V2CardSubheader,
    V2CardFooter,
    V2ListTile,
    V2Checkbox,
    V2Alert,
    V2Divider,
    V2Text,
    V2Icon,
    V2ProgressSpinner,
    V2Chip,
    V2Announcement,
    V2Textarea,
    V2FormField,
    V2HorizontalSlider,

    // v2 typography components
    V2HeaderLargeText,
    V2HeaderSecondaryText,
    V2BodyHeader0Text,
    V2BodyHeader1Text,
    V2BodyHeader2Text,
    V2BodyHeader3Text,
    V2BodyHeaderHeavyText,
    V2BodyText,
    V2BodySmallText,
    V2BodyLargeText,
    V2LabelText,
    V2LabelSmallText,
    V2LabelSecondaryText,
    V2LabelSmallSecondaryText,
    V2ErrorText,

    // directives
    ComponentInsertionDirective,
    HostClass,
    V2InputDirective,
    V2SliderChildDirective,
    V2DesktopDragToScrollDirective,
    V2RippleDirective,

    // pipes
    V2CurrencyPipe,

    V2AccordionListItemComponent
  ],
  providers: [
    // directives
    HostClass,

    // pipes
    V2CurrencyPipe,

    // misc
    V2InputErrorStateMatcher
  ]
})
export class V2ComponentsModule {}
