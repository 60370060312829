import { Component } from '@angular/core';

// this is a 1px grey line to visually separate sections of a list or page
@Component({
  selector: 'divider',
  styles: [
    `
      :host {
        display: block;
        height: 1px;
        background-color: var(--color-ui-light-grey);
        margin-top: 10px;
        margin-bottom: 10px;
      }
    `
  ],
  template: ``
})
export class DividerComponent {}
