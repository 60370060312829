import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IUser } from '../models';
import rg4js, { RaygunPayload } from 'raygun4js';
import { SerializationUtil } from 'utility/serialization.util';

@Injectable({
  providedIn: 'root'
})
export class RaygunService {
  readonly ERROR_DEFAULT_MESSAGE =
    'Something strange happened.  Please try again';

  normalizeError(error: any): Error {
    if (!error) return new Error(this.ERROR_DEFAULT_MESSAGE);

    if (typeof error === 'string') {
      return new Error(error);
    }

    if (typeof error.error?.message === 'string') {
      return new Error(error.error.message);
    }

    if (error.error && typeof error.error === 'object') {
      if (
        error.name === 'HttpErrorResponse' &&
        error.statusText === 'Unknown Error'
      ) {
        return new Error('Network error.  Please try again.');
      }
      return new Error(error.message);
    }

    if (error.message && typeof error.message === 'object') {
      return error.message;
    }

    return error as Error;
  }

  init() {
    this.normalizeStackTraceFilenames();
    rg4js('apiKey', environment.RaygunApiKey);
    rg4js('enableCrashReporting', true);
    rg4js('options', {
      ignore3rdPartyErrors: true
    });
  }

  logToRayGun(error: unknown, data?: object) {
    console.error(error);
    rg4js('send', {
      error: this.normalizeError(error),
      tags: [environment.env],
      customData: data ? SerializationUtil.toSafeJSONObject(data) : undefined
    });
  }

  setUser(user: IUser) {
    rg4js('setUser', {
      identifier: user.id,
      isAnonymous: user.email.includes('readytopay.net'),
      email: user.email,
      fullName: user.name
    });
  }

  // TODO make useful or remove
  log(msg: string) {
    console.log(`LOG: ${msg}`);
  }

  /**
   * We route to our app using subdomains at times (ie. fp.readypay.co), but our source mappings in Raygun are mapped to `https://readypay.co`.
   * To fix source mapping for stack traces from from our app from a subdomain, we need to normalize the filename in the stack trace to
   * `https://readypay.co` so our app can just use the one set of source maps.
   *  (As recommended by Raygun docs - https://raygun.com/documentation/product-guides/crash-reporting/sourcemaps/#using-private-source-maps-for-hybrid-mobile-apps)
   */

  normalizeStackTraceFilenames() {
    const beforeSend = (payload: RaygunPayload) => {
      const stacktrace = payload.Details.Error.StackTrace;

      // our files look like this when deployed.. "https://fp.readypay.co/polyfills.63ed47d0cec7ef57.js"
      // we need to grab "/polyfills.63ed47d0cec7ef57.js" bit
      const normalizeFilename = (filename: string) => {
        try {
          const path = new URL(filename).pathname;
          return 'https://readypay.co' + path;
        } catch {
          return filename;
        }
      };

      for (let i = 0; i < stacktrace.length; i++) {
        const stackline = stacktrace[i];
        stackline.FileName = normalizeFilename(stackline.FileName);
      }

      return payload;
    };

    rg4js('onBeforeSend', beforeSend);
  }
}
