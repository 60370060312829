import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppStore {
  private readonly _isEmbeddedInIOS$ = new BehaviorSubject(false);

  readonly isNotEmbeddedInIOS$ = this._isEmbeddedInIOS$.pipe(
    map((isEmbedded) => !isEmbedded)
  );

  get isEmbeddedInIOS(): boolean {
    return this._isEmbeddedInIOS$.getValue();
  }

  set isEmbeddedInIOS(v: boolean) {
    this._isEmbeddedInIOS$.next(v);
  }

  private readonly _isNFC$ = new BehaviorSubject(false);

  get isNFC(): boolean {
    return this._isNFC$.getValue();
  }

  set isNFC(isNFC: boolean) {
    this._isNFC$.next(isNFC);
  }
}
