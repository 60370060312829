import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AsyncUtil } from 'utility/async.util';
import { RxUtil } from 'utility/rx.util';

export type AlertMessageType =
  | string
  | Promise<string>
  | Observable<string>
  | Error;

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private alertController: AlertController) {}

  public async showErrorAlert(errorMessage: AlertMessageType): Promise<void> {
    return this.showAlert('alertDanger', errorMessage);
  }

  private async showAlert(
    type: 'alertDanger',
    message: AlertMessageType
  ): Promise<void> {
    if (!message) {
      message = '';
    } else if (message instanceof Promise) {
      message = await message;
    } else if (message instanceof Observable) {
      message = await RxUtil.takeOne(message);
    }

    const alert = await this.alertController.create({
      subHeader: `${message}`,
      cssClass: type
    });
    await alert.present();
    await Promise.race([
      alert.onDidDismiss(),
      (async () => {
        await AsyncUtil.sleep(6000);
        await alert.dismiss();
      })()
    ]);
  }
}
