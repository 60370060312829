import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { IReceipt } from '../models/receipts';
import { filter, map, pairwise, startWith } from 'rxjs/operators';
import { isBefore, parseISO } from 'date-fns';
import {
  ICheckSeat,
  ICheckWithSeats
} from '../pages/v2-order-pay/models/check-with-seats.model';
import { LocationStore } from './location.store';
import { UserStore } from './user.store';
import { ILocation, IUser } from '../models';
import { I18nPayBundle } from 'stores/i18n.store';
import { v4 as uuid } from 'uuid';
// This store will replace the old checks store, and only implements
// methods that differ between the two.
@Injectable({
  providedIn: 'root'
})
export class ChecksStore {
  private readonly _checks$ = new BehaviorSubject<IReceipt[]>([]);

  private paidChecks: IReceipt[] = [];

  constructor(
    private locationStore: LocationStore,
    private userStore: UserStore
  ) {}

  setChecks(checks: IReceipt[]) {
    this._checks$.next(this.addUiIdentifierToCheckItems(checks));
  }

  clearChecks() {
    this._checks$.next([]);
  }

  /**
   * Adds unique identifier to all check items.
   * Originally added to improve the item selection in multi-check-pbs store.
   */
  private addUiIdentifierToCheckItems(checks: IReceipt[]) {
    return checks.map((check: IReceipt) => {
      if (Array.isArray(check.items)) {
        check.items = check.items.map((item) => ({
          ...item,
          uiIdentifier: uuid()
        }));
      }

      return check;
    });
  }

  // Returns the entire checks array. If a check was present and then
  // disappears between emits, this means that is has become paid. In this case
  // we emit the checks including the missing checks in a modified paid state.
  readonly allChecks$: Observable<IReceipt[]> = this._checks$.pipe(
    startWith([]),
    pairwise<IReceipt[]>(),
    map(([previousChecks, currentChecks]) => {
      const currentCheckIds = currentChecks.map((c) => c._id);
      const alreadyPaidCheckIds = this.paidChecks.map((c) => c._id);
      const newlyPaidChecks = previousChecks
        .filter((previousCheck) => !currentCheckIds.includes(previousCheck._id))
        .filter(
          (previousCheck) => !alreadyPaidCheckIds.includes(previousCheck._id)
        )
        .map((check) => {
          let c = {
            ...check,
            isPaid: true
          };
          if (Array.isArray(check.items)) {
            c.items = check.items.map((item) => ({ ...item, isPaid: true }));
          }

          return c;
        });

      // TODO uncomment temp fix
      // this.paidChecks.push(...newlyPaidChecks);

      return currentChecks
        .concat(this.paidChecks)
        .sort((a, b) =>
          isBefore(parseISO(a.created), parseISO(b.created)) ? 1 : -1
        )
        .map((check) => {
          if (Array.isArray(check.orders) && check.orders.length > 0) {
            check.customerId = check.orders[0].customerId;
          }

          return check;
        });
    })
  );

  /**
   * Returns the checks for displaying in the UI.
   * In the case at which we require server created checks before guests can order,
   * do not show 0 total/due checks in the UI.
   */
  readonly checks$: Observable<IReceipt[]> = combineLatest([
    this.locationStore.currentLocation$,
    this.allChecks$
  ]).pipe(
    map(([currentLocation, checks]) =>
      !currentLocation.requireServerCreatedCheckBeforeGuestOrder
        ? checks
        : checks.filter(
            (c) => c.totals && c.totals.total > 0 && c.totals.due > 0
          )
    )
  );

  readonly checksWithSeats$: Observable<ICheckWithSeats[]> = combineLatest([
    this.checks$,
    this.locationStore.currentLocation$,
    this.userStore.currentUser$
  ]).pipe(
    map(([checks, currentLocation, currentUser]) => {
      const mappedChecks: ICheckWithSeats[] = checks
        .map((check) => ({
          ...check,
          seats: this.groupItemsBySeat(check).map((seat) => ({
            ...seat,
            isPaid: seat.items.every((item) => item.isPaid)
          }))
        }))
        .map((check) => ({
          ...check,
          hasMoreThanOneSeat: check.seats.length > 1
        }));

      // special sorting for delayed payment ordering
      if (currentLocation.orderDoesNotRequireImmediatePayment) {
        const sortedChecks = [
          // checks for the current user
          ...[
            typeof currentUser?._id === 'string'
              ? mappedChecks.filter(
                  (check) => check.customerId === currentUser._id
                )
              : []
          ].flat(),
          // checks created by server
          ...mappedChecks.filter(
            (check) => !Array.isArray(check.orders) || check.orders.length === 0
          )
        ];
        return [
          ...sortedChecks,
          // checks from other guests
          ...mappedChecks.filter((check) => !sortedChecks.includes(check))
        ];
      }

      return mappedChecks;
    })
  );

  private groupItemsBySeat(check: IReceipt): ICheckSeat[] {
    const seats: ICheckSeat[] = [];

    (check.items || [])
      .concat(
        (check.paidItems || []).map((item) => ({ ...item, isPaid: true }))
      )
      .forEach((item) => {
        const itemSeatNumber = typeof item.seat === 'number' ? item.seat : 1;
        const seat = seats.find((seat) => seat.seatNumber === itemSeatNumber);
        if (seat) {
          seat.items.push(item);
        } else {
          seats.push({
            seatNumber: itemSeatNumber,
            items: [item]
          });
        }
      });

    return seats
      .map((seat) => ({
        ...seat,
        items: seat.items.sort((a, b) => (a.posId > b.posId ? 1 : -1))
      }))
      .sort((a, b) => (a.seatNumber > b.seatNumber ? 1 : -1));
  }

  // Returns the first check in the checks array.
  readonly firstCheckWithSeats$: Observable<ICheckWithSeats> =
    this.checksWithSeats$.pipe(
      filter((arr) => arr.length > 0),
      map((c) => c[0])
    );

  readonly unpaidChecks$: Observable<IReceipt[]> = this.checks$.pipe(
    map((checks) => checks.filter((check) => !check.isPaid))
  );

  readonly areAllChecksPaid$: Observable<boolean> = this.unpaidChecks$.pipe(
    map((unpaidChecks) => unpaidChecks.length === 0)
  );

  readonly unclaimedChecks$: Observable<IReceipt[]> = this.checks$.pipe(
    map((checks) => checks.filter((check) => !check.isClaimed))
  );

  readonly unclaimedUnpaidChecks$: Observable<IReceipt[]> = this.checks$.pipe(
    map((checks) => checks.filter((check) => !check.isPaid && !check.isClaimed))
  );

  readonly areAnyChecksClaimed$: Observable<boolean> = this.checks$.pipe(
    map((checks) => this.isAnyItemClaimedOnReceipts(checks))
  );

  addCardTitlesToCheck(
    check: ICheckWithSeats,
    checks: ICheckWithSeats[],
    currentLocation: ILocation,
    currentUser: IUser | null,
    defaultTitleMethod: () => string,
    defaultSubtitleMethod: () => string,
    i18n: I18nPayBundle
  ): ICheckWithSeats {
    check = { ...check };
    if (currentLocation.orderDoesNotRequireImmediatePayment) {
      check.cardHeaderTitle = this.getCheckCardTitleForDelayedPaymentOrdering(
        check,
        checks,
        currentUser,
        i18n
      );
      check.cardHeaderSubtitle =
        this.getCheckCardSubtitleForDelayedPaymentOrdering(check, checks, i18n);
    } else {
      check.cardHeaderTitle = defaultTitleMethod();
      check.cardHeaderSubtitle = defaultSubtitleMethod();
    }
    return check;
  }

  hasNonReadyPayment(check: IReceipt): boolean {
    return Array.isArray(check.otherPayments) && check.otherPayments.length > 0;
  }

  getNonReadyPaymentsTotal(check: IReceipt): number {
    return check.otherPayments.reduce((a, p) => a + p.dueAmountPaid, 0);
  }

  getTotalPaid(check: IReceipt): number {
    return this.getPaymentsTotal(check) + this.getNonReadyPaymentsTotal(check);
  }

  getPaymentsTotal(check: IReceipt): number {
    return check.payments.reduce((a, p) => a + p.dueAmountPaidWithoutTip, 0);
  }

  getChecksTotalDue(checks: IReceipt[]): number {
    return checks.reduce(
      (acc, check) => acc + (check.totals.total - this.getTotalPaid(check)),
      0
    );
  }

  private isAnyItemClaimedOnReceipts(
    receipts: { isClaimed: boolean; items: { claimedNumerator?: number }[] }[]
  ): boolean {
    return (
      receipts.some((r) => r.isClaimed) ||
      receipts.some((r) => r.items.some((i) => i.claimedNumerator))
    );
  }

  private getCheckCardTitleForDelayedPaymentOrdering(
    check: ICheckWithSeats,
    checks: ICheckWithSeats[],
    currentUser: IUser | null,
    i18n: I18nPayBundle
  ): string {
    if (
      typeof currentUser?._id === 'string' &&
      check.customerId === currentUser._id
    ) {
      return i18n.yourOrder;
    } else if (!Array.isArray(check.orders) || check.orders.length === 0) {
      return i18n.addedByServer;
    } else {
      const sortedChecksByCreated = [...checks]
        .filter((c) => Array.isArray(c.orders) && c.orders.length > 0)
        .sort((a, b) =>
          isBefore(parseISO(a.created), parseISO(b.created)) ? -1 : 1
        );
      return `${i18n.guest} ${
        sortedChecksByCreated.findIndex((c) => c._id === check._id) + 1
      }`;
    }
  }

  private getCheckCardSubtitleForDelayedPaymentOrdering(
    check: ICheckWithSeats,
    checks: ICheckWithSeats[],
    i18n: I18nPayBundle
  ): string {
    if (check.posTicketNumber === '-1' && checks.length === 1) {
      return i18n.yourCheck;
    } else {
      return `${
        check.posTicketNumber !== '-1' ? `#${check.posTicketNumber} | ` : ''
      }${i18n.check} ${checks.findIndex((c) => c._id === check._id) + 1}/${
        checks.length
      }`;
    }
  }
}
