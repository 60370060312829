import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  ViewChild
} from '@angular/core';
import { V2SliderChildDirective } from '../../directives/v2-slider-child.directive';
import {
  asyncScheduler,
  BehaviorSubject,
  combineLatest,
  Observable,
  Subscription
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  skip,
  switchMap,
  take,
  throttleTime
} from 'rxjs/operators';
import { ResizeObserver } from '@juggle/resize-observer';
import { ColorService } from '../../services/color.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { nil } from '../../helpers/nil.helper';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { RxUtil } from 'utility/rx.util';
import { UiService } from '../../services/ui.service';

// Displays a list of child elements on the horizontal axis.
// When multiple children are shown and they overflow the container, a bullet list will
// be shown beneath the scrolling area to denote which element is currently 'active'.
// By default, when scrolling between elements the widget will 'snap' to each element. This will
// not occur when any element is larger than the width of the scrolling area.
@Component({
  selector: 'v2-horizontal-slider',
  templateUrl: 'v2-horizontal-slider.component.html',
  styleUrls: ['v2-horizontal-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2HorizontalSlider implements AfterViewInit, OnDestroy {
  @Input() showScrollShadows: boolean | nil = false;
  @Input() scrollShadowColor: string | nil = this.colorService.black;
  @Input() showNavButtons: boolean | nil = false;
  @Input() bulletColor: string | nil = this.colorService.black;
  @Input() @HostBinding('class.hide-scrollbars') hideScrollbars: boolean | nil =
    false;

  constructor(
    private colorService: ColorService,
    private sanitizer: DomSanitizer,
    private uiService: UiService
  ) {}

  @ContentChildren(V2SliderChildDirective)
  childQueryList?: QueryList<V2SliderChildDirective>;

  @ViewChild('scrollContainer', { read: ElementRef })
  private scrollContainerRef?: ElementRef;
  private scrollContainerResizeObserver?: ResizeObserver;
  private scrollOverflowSub?: Subscription;

  readonly childElements$ = new BehaviorSubject<HTMLElement[]>([]);
  readonly scrollEvents$ = new BehaviorSubject<Event | null>(null);

  private readonly scrollContainerEdgeThresholdForFloatingElements = 15;
  private readonly scrollContainerOuterDotThreshold = 15;

  private readonly scrollContainerEl$ = new BehaviorSubject<HTMLElement | null>(
    null
  );
  private readonly definedScrollContainerEl$ = this.scrollContainerEl$.pipe(
    filter((scrollContainerEl) => !!scrollContainerEl)
  ) as Observable<HTMLElement>;
  private readonly scrollContainerResizeEvents$ = new BehaviorSubject(null);

  ngAfterViewInit(): void {
    if (this.scrollContainerRef) {
      this.scrollContainerEl$.next(this.scrollContainerRef.nativeElement);

      this.scrollContainerResizeObserver = new ResizeObserver((_) =>
        this.scrollContainerResizeEvents$.next(null)
      );
      this.scrollContainerResizeObserver.observe(
        this.scrollContainerRef.nativeElement
      );
    }

    if (this.childQueryList) {
      this.updateChildElementsFromQueryList(this.childQueryList);

      this.childQueryList.changes.subscribe((childQueryList) =>
        this.updateChildElementsFromQueryList(childQueryList)
      );
    }

    // The first time we shift between overflow modes we scroll to the beginning of the container
    // to remove inconsistency in browser behavior. This state occurs naturally when going from
    // the initial non-overflowing state to the overflowing state when elements are rendered.
    this.scrollOverflowSub = this.scrollContainerOverflowing$
      .pipe(
        distinctUntilChanged(),
        skip(1),
        take(1),
        delay(50),
        switchMap((_) => this.definedScrollContainerEl$)
      )
      .subscribe((definedScrollContainerEl) => {
        definedScrollContainerEl.scrollTo(0, 0);
        this.scrollEvents$.next(new Event('scroll'));
      });
  }

  ngOnDestroy(): void {
    this.scrollContainerResizeObserver?.disconnect();
    this.scrollOverflowSub?.unsubscribe();
  }

  private updateChildElementsFromQueryList(
    childQueryList: QueryList<V2SliderChildDirective>
  ) {
    this.childElements$.next(
      childQueryList.toArray().map((child) => child.hostElement.nativeElement)
    );
  }

  readonly scrollContainerOverflowing$: Observable<boolean> = combineLatest([
    this.definedScrollContainerEl$,
    this.scrollContainerResizeEvents$
  ]).pipe(
    map(
      ([definedScrollContainerEl, _]) =>
        definedScrollContainerEl.scrollWidth >
        definedScrollContainerEl.clientWidth
    )
  );

  // Don't scroll snap if any child element exceeds the width of the container
  readonly shouldScrollSnap$: Observable<boolean> = combineLatest([
    this.definedScrollContainerEl$,
    this.scrollContainerResizeEvents$,
    this.childElements$
  ]).pipe(
    map(([definedScrollContainerEl, _, childElements]) => {
      const contWidth = definedScrollContainerEl.getBoundingClientRect().width;
      return childElements.every(
        (child) => child.getBoundingClientRect().width <= contWidth
      );
    })
  );

  readonly showBullets$: Observable<boolean> = combineLatest([
    this.childElements$,
    this.scrollContainerOverflowing$
  ]).pipe(
    map(
      ([childElements, scrollContainerOverflowing]) =>
        childElements.length > 1 && scrollContainerOverflowing
    )
  );

  // The active bullet is determined by finding which child elements center is closest to the scroll containers center.
  // When the scroll container is overflowing and has scrolled near one of the edges, the dot will show the first or
  // last as being active.
  readonly activeBulletIndex$: Observable<number> = combineLatest([
    this.definedScrollContainerEl$,
    this.childElements$,
    this.scrollContainerOverflowing$,
    this.scrollEvents$.pipe(
      throttleTime(10, asyncScheduler, { leading: true, trailing: true })
    ),
    // This fixes a bug where chrome returns incorrect values from getBoundingClientRect() when changing parent display
    this.scrollContainerResizeEvents$.pipe(delay(100))
  ]).pipe(
    map(([scrollContainerEl, childElements, scrollContainerOverflowing]) =>
      this.getActiveBulletIndex(
        scrollContainerEl,
        childElements,
        scrollContainerOverflowing
      )
    )
  );

  private getChildClosestToCenter(
    scrollContainerEl: HTMLElement,
    childElements: HTMLElement[]
  ): HTMLElement | null {
    const scrollContainerRect = scrollContainerEl.getBoundingClientRect();

    const scrollContainerCenter =
      scrollContainerRect.left + scrollContainerRect.width / 2;

    let childClosestToCenter: [number, HTMLElement] | null = null;
    childElements.forEach((child) => {
      const childRect = child.getBoundingClientRect();
      const childCenter = childRect.left + childRect.width / 2;
      const centerDiff = Math.abs(scrollContainerCenter - childCenter);
      if (!childClosestToCenter || centerDiff < childClosestToCenter[0]) {
        childClosestToCenter = [centerDiff, child];
      }
    });

    return childClosestToCenter ? childClosestToCenter[1] : null;
  }

  private getChildClosestToCenterIndex(
    scrollContainerEl: HTMLElement,
    childElements: HTMLElement[]
  ): number {
    const childClosestToCenter = this.getChildClosestToCenter(
      scrollContainerEl,
      childElements
    );

    return childClosestToCenter
      ? childElements.findIndex((child) =>
          child.isSameNode(childClosestToCenter)
        )
      : 0;
  }

  private getActiveBulletIndex(
    scrollContainerEl: HTMLElement,
    childElements: HTMLElement[],
    scrollContainerOverflowing: boolean
  ): number {
    if (scrollContainerOverflowing) {
      if (
        scrollContainerEl.scrollLeft < this.scrollContainerOuterDotThreshold
      ) {
        return 0;
      } else if (
        this.uiService.computeScrollRight(scrollContainerEl) <
        this.scrollContainerOuterDotThreshold
      ) {
        return childElements.length - 1;
      }
    }

    return this.getChildClosestToCenterIndex(scrollContainerEl, childElements);
  }

  private async getActiveBulletIndexFromStreams(): Promise<number> {
    return this.getActiveBulletIndex(
      ...(await RxUtil.takeOne(
        combineLatest([
          this.definedScrollContainerEl$,
          this.childElements$,
          this.scrollContainerOverflowing$
        ])
      ))
    );
  }

  getLeftShadowBackground(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `background: linear-gradient(90deg, ${this.scrollShadowColor} 0%, rgba(255,255,255,0) 100%);`
    );
  }

  getRightShadowBackground(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `background: linear-gradient(90deg, rgba(255,255,255,0) 0%, ${this.scrollShadowColor} 100%);`
    );
  }

  readonly shouldLeftFloatingElementsBeVisible$: Observable<boolean> =
    combineLatest([
      this.scrollEvents$,
      this.scrollContainerOverflowing$,
      this.definedScrollContainerEl$
    ]).pipe(
      map(([_, scrollContainerOverflowing, scrollContainerEl]) => {
        return (
          scrollContainerOverflowing &&
          scrollContainerEl.scrollLeft >
            this.scrollContainerEdgeThresholdForFloatingElements
        );
      })
    );

  readonly shouldRightFloatingElementsBeVisible$: Observable<boolean> =
    combineLatest([
      this.scrollEvents$,
      this.scrollContainerOverflowing$,
      this.definedScrollContainerEl$
    ]).pipe(
      map(([_, scrollContainerOverflowing, scrollContainerEl]) => {
        return (
          scrollContainerOverflowing &&
          this.uiService.computeScrollRight(scrollContainerEl) >
            this.scrollContainerEdgeThresholdForFloatingElements
        );
      })
    );

  async onLeftNavButtonClick() {
    const activeBulletIndex = await this.getActiveBulletIndexFromStreams();
    const childElements = this.childElements$.getValue();
    if (activeBulletIndex === 0) {
      return;
    }
    await this.scrollToIndex(activeBulletIndex - 1, childElements);
  }

  async onRightNavButtonClick() {
    const activeBulletIndex = await this.getActiveBulletIndexFromStreams();
    const childElements = this.childElements$.getValue();
    if (activeBulletIndex === childElements.length - 1) {
      return;
    }
    await this.scrollToIndex(activeBulletIndex + 1, childElements);
  }

  async scrollToIndex(i: number, childElements: HTMLElement[]) {
    await scrollIntoView(childElements[i], {
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }
}
