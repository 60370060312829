import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit
} from '@angular/core';

// When on desktop, apply logic to the host element that provides drag to scroll behaviour.
// Smooth scrolling and scroll snapping will be disabled on the host element while the user is dragging.
@Directive({
  selector: '[v2DesktopDragToScroll]'
})
export class V2DesktopDragToScrollDirective implements OnInit, OnDestroy {
  private readonly isDesktop;
  private readonly mouseDownMovementThreshold = 16;

  private position = {
    scrollLeft: 0,
    scrollTop: 0,
    mouseX: 0,
    mouseY: 0
  };
  private isMouseDown = false;
  private hasMovedWhileMouseDown = false;

  constructor(private elementRef: ElementRef<HTMLElement>) {
    // TODO move to cdk-like util services once available
    this.isDesktop = window.matchMedia(
      '(hover: hover) and (pointer: fine)'
    ).matches;
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener(
      'click',
      this.handleClickEvent.bind(this),
      true
    );
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener(
      'click',
      this.handleClickEvent.bind(this),
      true
    );
  }

  private handleClickEvent(e: MouseEvent) {
    if (this.hasMovedWhileMouseDown) {
      e.stopPropagation();
    }
  }

  @HostBinding('style.user-select') private get userSelect() {
    return this.isMouseDown ? 'none' : null;
  }

  @HostBinding('style.scroll-behavior') private get scrollBehavior() {
    return this.isMouseDown ? 'auto' : null;
  }

  @HostBinding('style.scroll-snap-type') private get scrollSnapType() {
    return this.isMouseDown ? 'none' : null;
  }

  @HostListener('mousedown', ['$event']) private onMouseDown(e: MouseEvent) {
    if (!this.isDesktop) return;

    this.isMouseDown = true;
    this.hasMovedWhileMouseDown = false;

    this.position = {
      scrollLeft: this.elementRef.nativeElement.scrollLeft,
      scrollTop: this.elementRef.nativeElement.scrollTop,
      mouseX: e.clientX,
      mouseY: e.clientY
    };
  }

  @HostListener('mouseup') private onMouseUp() {
    if (!this.isDesktop) return;

    this.isMouseDown = false;
  }

  @HostListener('mousemove', ['$event']) private onMouseMove(e: MouseEvent) {
    if (!this.isDesktop || !this.isMouseDown) return;

    const distanceX = e.clientX - this.position.mouseX;
    const distanceY = e.clientY - this.position.mouseY;

    if (
      Math.abs(distanceX) > this.mouseDownMovementThreshold ||
      Math.abs(distanceY) > this.mouseDownMovementThreshold
    ) {
      this.hasMovedWhileMouseDown = true;
    }

    this.elementRef.nativeElement.scrollTop =
      this.position.scrollTop - distanceY;
    this.elementRef.nativeElement.scrollLeft =
      this.position.scrollLeft - distanceX;
  }

  @HostListener('mouseleave') private onMouseLeave() {
    this.isMouseDown = false;
  }
}
