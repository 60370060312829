import { NgModule } from '@angular/core';
import { TrustPipe } from './trust.pipe';
import { ShortenBrandNamePipe } from './shorten-brand-name.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { DiscountTypeShorthandPipe } from './discount-type-shorthand.pipe';
import { OrderModifierPricePipe } from './order-modifier-price.pipe';

@NgModule({
  declarations: [
    TrustPipe,
    ShortenBrandNamePipe,
    TimeAgoPipe,
    DiscountTypeShorthandPipe,
    OrderModifierPricePipe
  ],
  exports: [
    TrustPipe,
    ShortenBrandNamePipe,
    TimeAgoPipe,
    DiscountTypeShorthandPipe,
    OrderModifierPricePipe
  ]
})
export class PipesModule {}
