import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare global {
  interface Window {
    cxApi: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ExperimentsService {
  private currentVariation: number | undefined;

  public init() {
    if (environment.ExperimentRunning) {
      this.currentVariation = window.cxApi.chooseVariation();
    }
  }
}
