import { Injectable } from '@angular/core';
import { ReadyHttpClient } from './ready-http-client';
import {
  IAssistantConversationResponse
} from './contracts/chat-assistant.contracts';

export interface SendMessageRequest {
  externalId: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatAssistantApi {
  constructor(private http: ReadyHttpClient) {}

  startConversation(beaconId: string): Promise<IAssistantConversationResponse> {
    return this.http.post(`/tables/${beaconId}/assistant/conversations`);
  }

  getConversationById(
    beaconId: string,
    conversationId: string
  ): Promise<IAssistantConversationResponse> {
    return this.http.get(
      `/tables/${beaconId}/assistant/conversations/${conversationId}`
    );
  }

  sendMessage(
    beaconId: string,
    bodyReq: SendMessageRequest
  ): Promise<IAssistantConversationResponse> {
    return this.http.post(
      `/tables/${beaconId}/assistant/conversations/${bodyReq.externalId}/message`,
      {
        ...bodyReq
      }
    );
  }
}
