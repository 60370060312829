import { Injectable } from '@angular/core';
import { ThemeStore } from 'stores/theme.store';
import { V2Color } from '../models/v2-colors.model';

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  readonly white = '#ffffff';
  readonly offWhite = '#faf8f8';
  readonly lightGrey = '#e7e7e7';
  readonly grey = '#d5d5d5';
  readonly inactiveGrey = '#999999';
  readonly mediumGrey = '#727272';
  readonly darkGrey = '#5d5d5d';
  readonly darkestGrey = '#3b3b3b';
  readonly black = '#1a1a1a';
  readonly alertOrange = '#e37f29';
  readonly alertOrangeTint = '#f7f4f0';
  readonly serviceBlue = '#014080';
  readonly serviceBlueDark = '#062f3f';
  readonly serviceBlueTint = '#f0f4f7';
  readonly discountGreen = '#018049';
  readonly discountGreenTint = '#f0f7f4';

  private readonly colorValueMap = new Map([
    ['primary', this.primary],
    ['secondary', this.secondary],
    ['white', this.white],
    ['offWhite', this.offWhite],
    ['lightGrey', this.lightGrey],
    ['grey', this.grey],
    ['inactiveGrey', this.inactiveGrey],
    ['mediumGrey', this.mediumGrey],
    ['darkGrey', this.darkGrey],
    ['darkestGrey', this.darkestGrey],
    ['black', this.black],
    ['alertOrange', this.alertOrange],
    ['alertOrangeTint', this.alertOrangeTint],
    ['serviceBlue', this.serviceBlue],
    ['serviceBlueDark', this.serviceBlueDark],
    ['serviceBlueTint', this.serviceBlueTint],
    ['discountGreen', this.discountGreen],
    ['discountGreenTint', this.discountGreenTint]
  ]);

  constructor(private themeStore: ThemeStore) {}

  get primary(): string {
    return this.themeStore.primaryColor;
  }

  get secondary(): string {
    return this.themeStore.primaryColor;
  }

  getColorValueForName(colorName: V2Color): string {
    return this.colorValueMap.get(colorName) ?? `color ${colorName} not found`;
  }
}
