import { HostBinding, Input, Directive } from '@angular/core';
import { nil } from './nil.helper';

@Directive()
export class V2Flex {
  @Input() @HostBinding('style.justify-content') justifyContent:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | nil;
  @Input() @HostBinding('style.align-items') alignItems:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'baseline'
    | 'stretch'
    | nil;
}
