import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { environment } from '../environments/environment';
import { isProdEnvironment } from './utility';
import { AnalyticsService } from './services/analytics.service';
import { RaygunService } from './services/raygun.service';
import { ExperimentsService } from './services/experiments.service';
import { ToastService } from './v2-components/services/toast.service';
import { unawaited } from './utility/unawaited';
import 'md-gum-polyfill';
import { PlatformService } from 'services/platform.service';
import { GuestPresenceService } from 'services/guest-presence.service';
import { AppStore } from 'stores/app.store';
import { UserService } from 'services/user.service';
import { ServiceWorkerService } from 'services/service-worker.service';
import { InvoiceService } from 'services/invoice.service';
import { I18nService } from 'services/i18n.service';
import { ChatAssistantStore } from 'stores/chat-assistant.store';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('routerOutletElement', { static: true, read: ElementRef })
  private routerOutletElement?: ElementRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private platform: Platform,
    private analyticsService: AnalyticsService,
    private raygunService: RaygunService,
    private experimentsService: ExperimentsService,
    private navCtrl: NavController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private platformService: PlatformService,
    private guestPresenceService: GuestPresenceService,
    private appStore: AppStore,
    private userService: UserService,
    private serviceWorkerService: ServiceWorkerService,
    private invoiceService: InvoiceService,
    private i18nService: I18nService,
    public chatAssistantStore: ChatAssistantStore
  ) {
    if (!isProdEnvironment()) {
      this.document
        .getElementById('appFavicon')
        ?.setAttribute(
          'href',
          `assets/icon/${environment.readyConfig.favicon}`
        );
      this.document
        .getElementById('appleTouchFavicon')
        ?.setAttribute(
          'href',
          `assets/icon/${environment.readyConfig.favicon}`
        );
    }

    this.injectGoogleAnalytics4ScriptsInDocumentHead();

    this.platform.ready().then(async () => {
      this.analyticsService.init();
      this.raygunService.init();
      this.experimentsService.init();
      this.checkForBeacon();
      this.checkForNfcTap();
      this.platformService.detectOperatingSystem();
      unawaited(this.userService.signIn());
      this.serviceWorkerService.initServiceWorkerListeners();
      this.i18nService.initPluralizationBundleRegistration();
    });
  }

  ngOnInit() {
    this.conditionallyPerformRedirect();

    // Stop the logo from showing up when uninvited.
    const logoCont = this.document.querySelector('.logo-container');
    if (logoCont) {
      logoCont.remove();
    }
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let route = this.activatedRoute.snapshot.firstChild;

        // traverse an activated route tree of any depth
        while (route?.firstChild) {
          route = route.firstChild;
        }

        if (route?.data?.name) {
          this.analyticsService.trackPage(route.data.name);
        }
      }
    });
  }

  // Abandon invoice on page close
  @HostListener('window:pagehide', ['$event']) private onPageHide(
    e: PageTransitionEvent
  ) {
    if (!e.persisted) {
      unawaited(this.invoiceService.abandonInvoice(true));
    }
  }

  // There are times where we want to treat the actual domain as a scan code (beaconId)
  // For example, our ordering flow usually uses a domain for the restaurant... like
  // order.brewhall.com  -- the point of this code is to redirect to /scan/order.brewhall.com
  // where order.brewhall.com is a valid beaconId
  private conditionallyPerformRedirect() {
    if (
      !environment.readyDomains.some(
        (domain) =>
          window.location.hostname.toUpperCase() === domain.toUpperCase()
      )
    ) {
      unawaited(this.router.navigateByUrl(`/scan/${window.location.hostname}`));
    }
  }

  checkForBeacon() {
    // This checks for URLs of the type http://readydemo.co/#/DEM416
    // and tries to redirect them to the appropriate page, while ignoring all in-app URLs
    const beaconId = window.location.pathname
      .split('/')
      .pop()
      ?.split('?')
      .shift();
    if (
      beaconId &&
      beaconId !== 'home' &&
      !window.location.pathname.includes('scan') &&
      beaconId.match(/DEM/i)
    ) {
      this.navCtrl.navigateForward(['scan', beaconId]);
    }
  }

  checkForNfcTap() {
    const queryParams = window.location.href.split('?').pop();
    const searchParams = new URLSearchParams(queryParams);
    const mode = searchParams.get('m');
    if (mode?.toLowerCase() === 'nfc') {
      this.analyticsService.trackPage('NFC Tap');
      this.appStore.isNFC = true;
      unawaited(
        this.guestPresenceService.registerValidatedGuestPresenceFromTargetScan()
      );
    }
  }

  onRouterActivate() {
    if (this.routerOutletElement) {
      const currentPageElement = Array.from<HTMLElement>(
        this.routerOutletElement.nativeElement.children
      )
        .reverse()
        .find((child) => child.style.display !== 'none');

      if (currentPageElement) {
        this.toastService.setCurrentPageElement(currentPageElement);
      }
    }
  }

  private injectGoogleAnalytics4ScriptsInDocumentHead() {
    const measurementId = environment.GoogleAnalytics4MeasurementId;

    this.document.head.appendChild(
      this.document.createComment(
        'Global site tag (gtag.js) - Google Analytics'
      )
    );

    const scriptTag1 = this.document.createElement('script');
    scriptTag1.async = true;
    scriptTag1.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    this.document.head.appendChild(scriptTag1);

    const scriptTag2 = this.document.createElement('script');
    scriptTag2.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${measurementId}');
    `;
    this.document.head.appendChild(scriptTag2);
  }
}
