import { Component, Input } from '@angular/core';

export enum LogoType {
  Word = 'word',
  Icon = 'icon'
}

export enum LogoColor {
  White = 'white',
  Grey = 'grey'
}

@Component({
  selector: 'ready-logo',
  templateUrl: 'ready-logo.html',
  styleUrls: ['ready-logo.scss']
})
export class ReadyLogoComponent {
  readonly LogoType = LogoType;

  @Input() type: LogoType | undefined;
  @Input() color: LogoColor | undefined;

  applyStyle(): string {
    switch (this.color?.toLowerCase()) {
      case LogoColor.White:
        return '#ffffff';
      case LogoColor.Grey:
        return '#9b9b9b';
      default:
        return '';
    }
  }
}
