export const environment = {
  production: false,
  readyConfig: {
    serverUrl: 'https://test1.readytopay.co',
    leaseExpiryDuration: 60 * 1000,
    leaseRenewalDuration: 30 * 1000,
    favicon: 'favicon.png'
  },
  firebaseConfig: {
    apiKey: "AIzaSyC5tLhf95_hFZbAsfReThLNckKiDEBq4tw",
    authDomain: "readypay-test.firebaseapp.com",
    projectId: "readypay-test",
    storageBucket: "readypay-test.appspot.com",
    messagingSenderId: "214386362070",
    appId: "1:214386362070:web:0d8e0b49a1f891032c6b77",
    measurementId: "G-K171RBHKJ1"
  },
  readyDomains: ['GARETTR-UI.READYTOPAY.NET', 'TEST1.READYDEMO.CO', 'LOCALHOST', 'NGROK.IO', 'BS-LOCAL.COM', 'JETPAY-READY-DEV.READYPAY.CO'],
  AmsConfig: {
    serviceUrl: 'https://amsint.iqmetrix.net'
  },
  AdyenConfig: {
    serviceDomain: 'adyen.com',
    clientKey: 'test_BZIOSTCMPZDOLKPJFQFOCCBXXUDWQCFV',
    environment: 'TEST',
    googlePayEnvironment: 'TEST'
  },
  StripeConfig: {
    publishableKey: 'pk_test_hONyoMTFCxHkbs4pFb6Bz45Q'
  },
  IQPaymentsConfig: {
    merchantId: 'merchant.com.paymentservicesgateway',
    hostedFormsUrl: 'https://hostedpaymentformtest12.iqmetrix.net'
  },
  FreedomPayConfig: {
    serviceDomain: 'hpc.uat.freedompay.com'
  },
  RealexConfig: {
    hppUrl: 'https://pay.sandbox.realexpayments.com/pay'
  },
  SquareConfig: {
    applicationId: 'sandbox-sq0idb-6XOudFJfrTv4CSEyYnQn5w'
  },
  DynamicPaymentProcessorApiScripts: {
    freedompay: ['https://hpc.uat.freedompay.com/api/v1.5/cdn/applepay_min.js'],
    square: ['https://sandbox.web.squarecdn.com/v1/square.js']
  },
  RecaptchaConfig: {
    siteKey: '6LesmDgcAAAAAOdqBy3fgAGv6uib3TB99KEith92'
  },
  env: 'test',
  GoogleAnalyticsId: 'UA-68293320-3',
  GoogleAnalytics4MeasurementId: 'G-KS58YW22NF',
  RaygunApiKey: 'IOflLMokYxx4DnUCOvKWcw==',
  ExperimentRunning: false,
  EventPollingIntervalMilliseconds: 60000,
};
