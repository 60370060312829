import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OnAccountPaymentMethodUiModel } from '../ui-models/on-account-payment-method.ui-model';
import { PickupDetailsCustomerFieldUiModel } from '../ui-models/pickup-details-customer-field.ui-model';

export enum OnAccountBottomSheetContext {
  ReviewOrder,
  Checkout
}

@Injectable({
  providedIn: 'root'
})
export class OnAccountPaymentStore {
  private readonly _isOnAccountPaymentsSettingEnabled$ = new BehaviorSubject(
    false
  );

  setOnAccountPaymentSettingEnabled(v: boolean) {
    this._isOnAccountPaymentsSettingEnabled$.next(v);
  }

  private readonly _onAccountPaymentMethods$ = new BehaviorSubject<
    Readonly<OnAccountPaymentMethodUiModel[]>
  >([]);

  readonly onAccountPaymentMethods$ =
    this._onAccountPaymentMethods$.asObservable();

  setOnAccountPaymentMethods(v: OnAccountPaymentMethodUiModel[]) {
    this._onAccountPaymentMethods$.next(v);
  }

  areOnAccountPaymentsEnabled$: Observable<boolean> = combineLatest([
    this._isOnAccountPaymentsSettingEnabled$,
    this._onAccountPaymentMethods$
  ]).pipe(
    map(
      ([isOnAccountPaymentsSettingEnabled, onAccountPaymentMethods]) =>
        isOnAccountPaymentsSettingEnabled && onAccountPaymentMethods.length > 0
    )
  );

  private _selectedOnAccountPaymentMethodId$ = new BehaviorSubject<
    string | null
  >(null);

  setSelectedOnAccountPaymentMethodId(v: string) {
    this._selectedOnAccountPaymentMethodId$.next(v);
  }

  clearSelectedOnAccountPaymentMethodId() {
    this._selectedOnAccountPaymentMethodId$.next(null);
  }

  readonly selectedOnAccountPaymentMethod$: Observable<Readonly<OnAccountPaymentMethodUiModel> | null> =
    combineLatest([
      this._selectedOnAccountPaymentMethodId$,
      this._onAccountPaymentMethods$
    ]).pipe(
      map(
        ([selectedOnAccountPaymentMethodId, onAccountPaymentMethods]) =>
          onAccountPaymentMethods.find(
            (pm) => pm.id === selectedOnAccountPaymentMethodId
          ) ?? null
      )
    );

  private readonly _customerFieldResponses$ = new BehaviorSubject<
    Readonly<Map<string, Map<string, string>>>
  >(new Map());

  setCustomerFieldResponse(fieldLabel: string, customerResponse: string) {
    const selectedOnAccountPaymentMethodId =
      this._selectedOnAccountPaymentMethodId$.getValue();
    if (selectedOnAccountPaymentMethodId) {
      const customerFieldResponsesMap =
        this._customerFieldResponses$.getValue();
      const onAccountMethodMap =
        customerFieldResponsesMap.get(selectedOnAccountPaymentMethodId) ??
        new Map<string, string>();
      onAccountMethodMap.set(fieldLabel, customerResponse);
      customerFieldResponsesMap.set(
        selectedOnAccountPaymentMethodId,
        onAccountMethodMap
      );
      this._customerFieldResponses$.next(customerFieldResponsesMap);
    }
  }

  readonly selectedOnAccountPaymentMethodCustomerFields$: Observable<
    Readonly<PickupDetailsCustomerFieldUiModel[]>
  > = combineLatest([
    this.selectedOnAccountPaymentMethod$,
    this._customerFieldResponses$
  ]).pipe(
    map(
      ([selectedOnAccountPaymentMethod, customerFieldResponses]) =>
        selectedOnAccountPaymentMethod?.customerFields.map((cf) => ({
          ...cf,
          customerResponse:
            customerFieldResponses
              .get(selectedOnAccountPaymentMethod?.id ?? '')
              ?.get(cf.inputLabel) ?? ''
        })) ?? []
    )
  );

  readonly selectedOnAccountPaymentMethodName$: Observable<string> =
    this.selectedOnAccountPaymentMethod$.pipe(
      map(
        (selectedOnAccountPaymentMethod) =>
          selectedOnAccountPaymentMethod?.name ?? 'Pay Now'
      )
    );

  readonly areOnAccountPickupDetailsRequired$: Observable<boolean> =
    this.selectedOnAccountPaymentMethodCustomerFields$.pipe(
      map(
        (selectedOnAccountPaymentMethodCustomerFields) =>
          selectedOnAccountPaymentMethodCustomerFields.length > 0
      )
    );

  private readonly _bottomSheetContext$ = new BehaviorSubject(
    OnAccountBottomSheetContext.ReviewOrder
  );

  readonly bottomSheetContext$ = this._bottomSheetContext$.asObservable();

  setBottomSheetContext(v: OnAccountBottomSheetContext) {
    this._bottomSheetContext$.next(v);
  }

  // the first field that has a customer response
  readonly primaryFieldToDisplay$: Observable<Readonly<PickupDetailsCustomerFieldUiModel> | null> =
    this.selectedOnAccountPaymentMethodCustomerFields$.pipe(
      map((selectedOnAccountPaymentMethodCustomerFields) => {
        return (
          selectedOnAccountPaymentMethodCustomerFields.find(
            (response) => response.customerResponse.length > 0
          ) ?? null
        );
      })
    );

  readonly additionalCustomerFieldsToDisplay$: Observable<
    Readonly<PickupDetailsCustomerFieldUiModel[]>
  > = combineLatest([
    this.primaryFieldToDisplay$,
    this.selectedOnAccountPaymentMethodCustomerFields$
  ]).pipe(
    map(([primaryField, customerFields]) => {
      if (!primaryField) {
        return customerFields;
      }

      const customerFieldsCopy = [...customerFields];
      customerFieldsCopy.splice(
        customerFieldsCopy.findIndex(
          (customerField) =>
            primaryField.customerResponse === customerField.customerResponse
        ),
        1
      );
      return customerFieldsCopy;
    })
  );
}
