import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { LocationStore } from './location.store';
import { CustomerFlow } from '../pages/v2-order-pay/models/customer-flow.model';
import { RxUtil } from 'utility/rx.util';
import { IonContent } from '@ionic/angular';
import { OrderStore } from 'stores/order.store';
import { PaymentStore } from 'stores/payment.store';
import { ChecksStore } from 'stores/checks.store';
import { AwaitingServerCheckInSheetMode } from '../pages/v2-order-pay/bottom-sheets/awaiting-server-check-in/awaiting-server-check-in.sheet';
import { TableRefreshStore } from './table-refresh-store.service';
import { Signals } from 'services/signals.service';

@Injectable({
  providedIn: 'root'
})
export class OrderPayStore {
  constructor(
    private router: Router,
    private tableRefreshStore: TableRefreshStore,
    private locationStore: LocationStore,
    private orderStore: OrderStore,
    private paymentStore: PaymentStore,
    private checkStore: ChecksStore
  ) {}

  private readonly _viewScroll$ = new BehaviorSubject<CustomEvent | null>(null);
  readonly viewScroll$: Observable<CustomEvent> = this._viewScroll$.pipe(
    filter(RxUtil.inputIsNotNullOrUndefined)
  );

  private readonly _viewScrollElement$ =
    new BehaviorSubject<HTMLElement | null>(null);
  readonly viewScrollElement$ = this._viewScrollElement$.pipe(
    filter(RxUtil.inputIsNotNullOrUndefined)
  );

  async onViewScroll(
    event: CustomEvent,
    contentContainer: IonContent
  ): Promise<void> {
    this._viewScroll$.next(event);
    this._viewScrollElement$.next(await contentContainer.getScrollElement());
  }

  private readonly _beaconId$ = new BehaviorSubject<string>('');

  readonly beaconId$: Observable<string> = this._beaconId$.pipe(
    filter((b) => !!b)
  );

  get beaconId(): string {
    return this._beaconId$.getValue();
  }

  set beaconId(v: string) {
    this._beaconId$.next(v);
  }

  private readonly _currentFlow$ = new BehaviorSubject<CustomerFlow | null>(
    null
  );

  get currentFlow(): CustomerFlow | null {
    return this._currentFlow$.getValue();
  }

  setCurrentFlow(f: CustomerFlow) {
    this._currentFlow$.next(f);
  }

  readonly isOrderFlow$: Observable<boolean> = this._currentFlow$.pipe(
    map((flow) => flow === CustomerFlow.Order)
  );

  readonly isPayFlow$: Observable<boolean> = this._currentFlow$.pipe(
    map((flow) => flow === CustomerFlow.Pay)
  );

  readonly navigationToOrderView$: Observable<Event> = this.router.events.pipe(
    filter(
      (event) =>
        event instanceof NavigationEnd && event.url.endsWith('order/menu')
    )
  );

  readonly tableEventsWithBeaconId$: Observable<[Signals, string]> =
    combineLatest([this.tableRefreshStore.events$, this.beaconId$]);

  readonly orderPayFlags$: Observable<[boolean, boolean, boolean]> =
    combineLatest([
      this.locationStore.isPayEnabled$,
      this.locationStore.isOrderingEnabled$,
      this.locationStore.isDigitalMenuEnabledAndValid$
    ]);

  private readonly _forceShowViewTogglePillCounter$ = new BehaviorSubject(0);

  readonly forceShowViewTogglePill$: Observable<boolean> =
    this._forceShowViewTogglePillCounter$.pipe(
      map(
        (viewScrollingToElementCounter) => viewScrollingToElementCounter !== 0
      )
    );

  forceShowViewTogglePill() {
    this._forceShowViewTogglePillCounter$.next(
      this._forceShowViewTogglePillCounter$.getValue() + 1
    );
  }

  finishForceShowingViewTogglePill() {
    this._forceShowViewTogglePillCounter$.next(
      this._forceShowViewTogglePillCounter$.getValue() - 1
    );
  }

  private readonly _showNewCheckOverlayOnViewTogglePill$ = new BehaviorSubject(
    false
  );
  readonly showNewCheckOverlayOnViewTogglePill$ =
    this._showNewCheckOverlayOnViewTogglePill$.asObservable();

  set showNewCheckOverlayOnViewTogglePill(v: boolean) {
    this._showNewCheckOverlayOnViewTogglePill$.next(v);
  }

  readonly shouldShowOrderingDisabledFooter$: Observable<boolean> =
    combineLatest([
      this.locationStore.isMenuReadonly$,
      this.orderStore.isOrderingEnabled$
    ]).pipe(
      map(
        ([isMenuReadonly, isOrderingEnabled]) =>
          !isMenuReadonly && !isOrderingEnabled
      )
    );

  readonly shouldShowPaymentDisruptionFooter$: Observable<boolean> =
    combineLatest([
      this.locationStore.isMenuReadonly$,
      this.orderStore.isOrderingEnabled$,
      this.paymentStore.showNoPaymentsSupportedOnDeviceFooter$,
      this.paymentStore.showNoPaymentsConfiguredFooter$
    ]).pipe(
      map(
        ([
          isMenuReadonly,
          isOrderingEnabled,
          showNoPaymentsSupportedOnDeviceFooter,
          showNoPaymentsConfiguredFooter
        ]) =>
          !isMenuReadonly &&
          isOrderingEnabled &&
          (showNoPaymentsSupportedOnDeviceFooter ||
            showNoPaymentsConfiguredFooter)
      )
    );

  readonly shouldShowServerCreatedCheckRequirementFooter$: Observable<boolean> =
    combineLatest([
      this.locationStore.currentLocation$,
      this.shouldShowOrderingDisabledFooter$,
      this.shouldShowPaymentDisruptionFooter$
    ]).pipe(
      map(
        ([
          currentLocation,
          shouldShowOrderingDisabledFooter,
          shouldShowPaymentDisruptionFooter
        ]) =>
          !!currentLocation.requireServerCreatedCheckBeforeGuestOrder &&
          !shouldShowOrderingDisabledFooter &&
          !shouldShowPaymentDisruptionFooter
      )
    );

  readonly haveServerCreatedCheckRequirementsBeenMet$: Observable<boolean> =
    combineLatest([
      this.locationStore.currentLocation$,
      this.checkStore.allChecks$
    ]).pipe(
      map(
        ([currentLocation, checks]) =>
          !currentLocation.requireServerCreatedCheckBeforeGuestOrder ||
          checks.length > 0
      )
    );

  private readonly _awaitingServerCheckInSheetMode$ =
    new BehaviorSubject<AwaitingServerCheckInSheetMode | null>(null);

  readonly awaitingServerCheckInSheetMode$: Observable<AwaitingServerCheckInSheetMode> =
    combineLatest([
      this._awaitingServerCheckInSheetMode$,
      this.haveServerCreatedCheckRequirementsBeenMet$
    ]).pipe(
      map(
        ([
          awaitingServerCheckInSheetMode,
          haveServerCreatedCheckRequirementsBeenMet
        ]) =>
          haveServerCreatedCheckRequirementsBeenMet ||
          !awaitingServerCheckInSheetMode
            ? 'ready-to-order'
            : awaitingServerCheckInSheetMode
      )
    );

  setAwaitingServerCheckInSheetMode(v: AwaitingServerCheckInSheetMode) {
    this._awaitingServerCheckInSheetMode$.next(v);
  }
}
