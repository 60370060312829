import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RateLimitStore {
  readonly creditCardPaymentAmountLimitMessage: string =
    'Transaction could not be processed for the specified amount. Please pay via your server..';

  private readonly _exceedsPaymentLimits$ = new BehaviorSubject(false);

  get exceedsPaymentLimits(): boolean {
    return this._exceedsPaymentLimits$.getValue();
  }

  setExceedsPaymentLimits(v: boolean) {
    this._exceedsPaymentLimits$.next(v);
  }
}
