import { Component } from '@angular/core';

@Component({
  selector: 'v2-error-text',
  template: `
    <v2-text color="alert-orange">
      <ng-content></ng-content>
    </v2-text>
  `,
  styles: [
    `
      :host {
        display: block;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.4;
        text-align: center;
      }
    `
  ]
})
export class V2ErrorText {}
