import { Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent } from '@angular/service-worker';
import { interval } from 'rxjs';
import { RaygunService } from 'services/raygun.service';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {
  constructor(
    private swUpdate: SwUpdate,
    private raygunService: RaygunService
  ) {}

  initServiceWorkerListeners() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(filter((event) => event.type === 'VERSION_READY'))
        .subscribe(() => this.handleAvailableUpdate());

      // Handle unrecoverable situations such as cache eviction of an application
      // chunk that no longer exists on the server.
      this.swUpdate.unrecoverable.subscribe((e) =>
        this.handleUnrecoverableState(e)
      );

      // Manually check for updates every 6 hours to deal with tabs that remain open
      // beyond reasonable expectations.
      interval(6 * 60 * 60 * 1000).subscribe(() =>
        this.swUpdate.checkForUpdate()
      );
    }
  }

  private handleUnrecoverableState(event: UnrecoverableStateEvent) {
    window.alert(
      `An error occurred that we cannot recover from:
          ${event.reason}
          Please reload the page.`
    );
    this.raygunService.logToRayGun(
      new Error(`Unrecoverable state encountered: ${event.reason}`)
    );
  }

  private async handleAvailableUpdate(): Promise<void> {
    if (
      window.confirm(
        'An update is available for this application. Refresh now to apply changes?'
      )
    ) {
      await this.swUpdate.activateUpdate();
      document.location.reload();
    }
  }
}
