import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { V2Color } from '../models/v2-colors.model';
import { Subset } from '../helpers/subset.helper';

@Component({
  selector: 'v2-divider',
  template: ` <div id="wrapper" [class]="color"></div> `,
  styles: [
    `
      :host {
        display: block;
        height: 1px;
      }

      :host(.inset) {
        margin-left: 16px;
      }

      :host(.vertical) {
        height: inherit;
        width: 1px;
      }

      #wrapper {
        width: 100%;
        height: 100%;
        background-color: var(--v2-color-off-white);
      }

      #wrapper.grey {
        background-color: var(--v2-color-grey);
      }

      #wrapper.white {
        background-color: var(--v2-color-white);
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Divider {
  @Input() @HostBinding('class.inset') inset: boolean = false;
  @Input() @HostBinding('class.vertical') vertical: boolean = false;
  @Input() color: Subset<V2Color, 'off-white' | 'grey' | 'white'> = 'off-white';
}
