import { Injectable } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { signInWithCustomToken, User } from 'firebase/auth';
import { isProdEnvironment } from 'utility';
import { UserApi } from '../api/user.api';
import { ICard, IStripeToken } from '../models';
import { AnalyticsService } from './analytics.service';
import { RaygunService } from './raygun.service';
import { UserStore } from '../stores/user.store';
import { ReadyAuthApi } from '../api/ready-auth.api';
import { Observable } from 'rxjs';
import { RxUtil } from 'utility/rx.util';
import {
  IAnonInfoRes,
  ICreateAccountRes
} from '../api/contracts/auth.contracts';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly authState: Observable<User | null>;

  constructor(
    private readyAuthApi: ReadyAuthApi,
    private auth: Auth,
    private analyticsService: AnalyticsService,
    private raygunService: RaygunService,
    private userStore: UserStore,
    private userApi: UserApi
  ) {
    this.authState = authState(this.auth);
  }

  async submitPhoneNumber(phoneNumber: string): Promise<void> {
    await this.userApi.submitPhoneNumber(phoneNumber.replace(/\D/g, ''));
    this.userStore.patchCurrentUser('phone', phoneNumber);
  }

  async verifyPhoneNumber(verificationCode: string): Promise<void> {
    await this.userApi.verifyPhoneCode(verificationCode);
    this.userStore.patchCurrentUser('phoneVerified', true);
  }

  async signIn(customToken?: string): Promise<void> {
    this.userStore.firebaseUser = await this.getFirebaseUser();

    try {
      if (this.userStore.firebaseUser) {
        await this.loadUser(this.userStore.firebaseUser.uid);
      } else {
        await this.createAnonymousUser(customToken);
      }
    } catch {
      await this.createAnonymousUser(customToken);
    }
  }

  private addCustomerCard(cardDetails: ICard | IStripeToken) {
    // cardDetails supports a card object when manually adding a card
    // and a stripe object for when a card is added with stripe using a token
    return this.userApi
      .addCustomerCard(cardDetails)
      .then((res) => {
        let newSources = res;
        if (this.userStore.currentUser) {
          this.userStore.patchCurrentUser(
            'defaultCard',
            newSources.defaultCard
          );
          this.userStore.patchCurrentUser('sources', newSources.sources);
        }
        return res;
      })
      .catch((error) => {
        throw error;
      });
  }

  private async createAnonReadyAccount(): Promise<{
    anonInfo: IAnonInfoRes;
    readyUser: ICreateAccountRes;
  }> {
    const anonInfo = await this.readyAuthApi.getAnonymousInformation();
    const readyUser = await this.readyAuthApi.createAccount(
      anonInfo.email,
      anonInfo.name,
      anonInfo.pwd
    );
    return {
      anonInfo,
      readyUser
    };
  }

  private async authenticateWithAnonReadyAccount(): Promise<void> {
    const firstAccount = await this.createAnonReadyAccount();
    let readyUser = firstAccount.readyUser;

    if (!readyUser.token) {
      const secondAccount = await this.createAnonReadyAccount();
      readyUser = secondAccount.readyUser;

      if (!readyUser.token) {
        this.raygunService.logToRayGun(
          new Error(
            'encountered missing token twice from readyAuthApi.createAccount'
          ),
          {
            firstEmail: firstAccount.anonInfo.email,
            secondEmail: secondAccount.anonInfo.email
          }
        );
      }
    }

    try {
      this.userStore.firebaseUser = (
        await signInWithCustomToken(this.auth, readyUser.token ?? '')
      ).user;
    } catch (e) {
      this.raygunService.logToRayGun(e, {
        readyUser,
        context:
          'failed to signInWithCustomToken from readyAuthApi.createAccount'
      });
    }
  }

  private async createAnonymousUser(customToken?: string): Promise<void> {
    try {
      if (customToken) {
        try {
          this.userStore.firebaseUser = (
            await signInWithCustomToken(this.auth, customToken)
          ).user;
        } catch (e) {
          console.error('Failed to login with custom auth token', e);
          await this.authenticateWithAnonReadyAccount();
        }
      } else {
        await this.authenticateWithAnonReadyAccount();
      }

      this.analyticsService.log('Anonymous User Created');

      if (!isProdEnvironment()) {
        await this.addCustomerCard({
          expiryMonth: '01',
          expiryYear: '2029',
          cardNumber: '4111111111111111',
          cvv: '999'
        });
      }
      await this.loadUser(this.userStore.firebaseUser?.uid);
    } catch (error) {
      throw error;
    }
  }

  private async loadUser(firebaseUid?: string): Promise<void> {
    try {
      if (
        !this.userStore.currentUser ||
        this.userStore.currentUser.id !== firebaseUid
      ) {
        this.userStore.setCurrentUser(
          await this.userApi.getUserById(firebaseUid ?? '')
        );
        this.raygunService.setUser(this.userStore.currentUser!);
        this.analyticsService.setUserId(this.userStore.currentUser!._id);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  private async getFirebaseUser(): Promise<User | null> {
    return RxUtil.takeOne(this.authState);
  }
}
