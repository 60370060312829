import { Directive } from '@angular/core';
import { NgClass } from '@angular/common';

// This directive is used to add css classes to a component from within itself,
// without overriding the class, [class], or [ngClass] html attributes
@Directive({
  selector: 'host-class'
})
export class HostClass extends NgClass {
  apply(value: string | string[] | Set<string> | { [klass: string]: any }) {
    this.ngClass = value;
    this.ngDoCheck();
  }
}

export const hostClassProviders = [NgClass, HostClass];
