import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { IInvoice, IItem, ITotals } from '../models';
import { map } from 'rxjs/operators';
import { IReceipt } from '../models/receipts';
import { LocationStore } from './location.store';
import { ChecksStore } from './checks.store';
import { Invoice2Store } from 'stores/invoice2.store';

@Injectable({
  providedIn: 'root'
})
export class InvoiceStore {
  private readonly fakeInvoiceItemLocationPropKeys = [
    'orderAheadCompatibilityMenuItemId',
    'customerFieldResponseMenuItemId'
  ] as const;

  constructor(
    private checksStore: ChecksStore,
    private locationStore: LocationStore,
    private invoice2Store: Invoice2Store
  ) {}

  private _currentInvoice$ = new BehaviorSubject<IInvoice | null>(null);
  readonly currentInvoice$ = this._currentInvoice$.asObservable();

  get currentInvoice(): IInvoice | null {
    return this._currentInvoice$.getValue();
  }

  setCurrentInvoice(invoice: IInvoice) {
    this._currentInvoice$.next({
      ...invoice,
      id: invoice.id || invoice._id,
      _id: invoice.id || invoice._id
    });
    this.storeInvoiceTotals(invoice.totals);
  }

  setAbandonedInvoice(invoice: IInvoice) {
    this._currentInvoice$.next({ ...invoice, abandoned: true });
  }

  private storeInvoiceTotals(totals?: Partial<ITotals>) {
    if (totals) {
      this.invoice2Store.setInvoiceTotals({
        discounts: totals.discounts || 0,
        due: totals.due || 0,
        otherCharges: totals.otherCharges || 0,
        otherPayments: totals.otherPayments || 0,
        serviceCharge: totals.serviceCharge || 0,
        splitDenominator: totals.splitDenominator || 0,
        splitNumerator: totals.splitNumerator || 0,
        subtotal: totals.subtotal || 0,
        tax: totals.tax || 0,
        total: totals.total || 0
      });
    }
  }

  clearCurrentInvoice() {
    this._currentInvoice$.next(null);
    this.invoice2Store.clearInvoiceTotals();
  }

  private _currentInvoiceChecks$ = new BehaviorSubject<IReceipt[]>([]);
  readonly currentInvoiceChecks$ = this._currentInvoiceChecks$.asObservable();

  setCurrentInvoiceChecks(v: IReceipt[]) {
    this._currentInvoiceChecks$.next(v);
  }

  readonly hasServiceCharge$: Observable<boolean> =
    this.invoice2Store.invoiceTotals$.pipe(
      map(
        (invoiceTotals) =>
          invoiceTotals.serviceCharge + invoiceTotals.otherCharges > 0
      )
    );

  readonly amountPaid$: Observable<number> = combineLatest([
    this.checksStore.checks$,
    this.currentInvoice$
  ]).pipe(
    map(([checks, invoice]) => {
      let amountPaid = 0;

      if (invoice && typeof invoice.amountPaid === 'number') {
        amountPaid += invoice.amountPaid;
      }

      for (const check of checks) {
        if (Array.isArray(check.otherPayments)) {
          amountPaid += this.checksStore.getNonReadyPaymentsTotal(check);
        }
      }

      return amountPaid;
    })
  );

  private readonly fakeInvoiceItemPosIds$: Observable<string[]> =
    this.locationStore.currentLocation$.pipe(
      map((location) =>
        this.fakeInvoiceItemLocationPropKeys.map(
          // @ts-ignore
          (key) => location.systemType.options[key]
        )
      )
    );

  readonly filteredOrderItems$: Observable<IItem[]> = combineLatest([
    this.fakeInvoiceItemPosIds$,
    this.currentInvoice$
  ]).pipe(
    map(
      ([fakeInvoiceItemPosIds, invoice]) =>
        invoice?.items
          .filter(
            (item) =>
              !fakeInvoiceItemPosIds.includes(item.externalId || item.posId)
          )
          .map((item) => ({
            ...item,
            modifiers: item.modifiers.filter((m) => !m.excludeFromReceipt)
          })) ?? []
    )
  );

  readonly invoiceHasValidLoyaltyDiscount$: Observable<boolean> =
    this.currentInvoice$.pipe(
      map(
        (currentInvoice) =>
          typeof currentInvoice?.loyaltyDiscount === 'number' &&
          currentInvoice.loyaltyDiscount > 0
      )
    );

  readonly invoiceOrderAheadTime$: Observable<string | null> =
    this.currentInvoice$.pipe(
      map((invoice) => invoice?.orderAheadTime ?? null)
    );

  readonly isAutoGratuityEnabled$: Observable<boolean> =
    this.currentInvoiceChecks$.pipe(
      map(
        (checks) =>
          checks.reduce(
            (a, v) =>
              a + (v.totals.serviceCharge || 0) + (v.totals.otherCharges || 0),
            0
          ) > 0
      )
    );
}
