import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  HostListener,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { V2FormFieldControl } from '../../helpers/v2-form-field-control';
import { startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { RxUtil } from 'utility/rx.util';

@Component({
  selector: 'v2-form-field',
  templateUrl: 'v2-form-field.component.html',
  styleUrls: ['v2-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2FormField implements AfterContentInit, AfterViewInit, OnDestroy {
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  @Input() labelText: string = '';
  /** Used to add the "new" animated validation error styles. Ideally we move all of our forms to use this.
   * Todo: Consider refactoring to add this to all form fields
   */
  @Input() addValidationErrorStyles: boolean = false;

  @ContentChild(V2FormFieldControl)
  private controlNonStatic?: V2FormFieldControl<unknown>;
  @ContentChild(V2FormFieldControl, { static: true })
  private controlStatic?: V2FormFieldControl<unknown>;
  get control() {
    // TODO clean this up once Ivy is the default renderer.
    return (
      this.explicitFormFieldControl ||
      this.controlNonStatic ||
      this.controlStatic
    );
  }
  set control(value) {
    this.explicitFormFieldControl = value;
  }
  private explicitFormFieldControl?: V2FormFieldControl<unknown>;

  private controlChangesSub?: Subscription;
  private controlFormChangesSub?: Subscription;

  ngAfterContentInit(): void {
    if (!this.control) {
      throw new Error('v2-form-field must contain a V2FormFieldControl.');
    }

    // subscribe to changes in the child control state in order to update the form field UI
    this.controlChangesSub = this.control.stateChanges
      .pipe(startWith(null!))
      .subscribe(() => this.changeDetectorRef.markForCheck());

    // run change detection if the form value changes
    if (this.control.ngControl && this.control.ngControl.valueChanges) {
      this.controlFormChangesSub =
        this.control.ngControl.valueChanges.subscribe(() =>
          this.changeDetectorRef.markForCheck()
        );
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.changeDetectorRef.markForCheck(), 100);
  }

  ngOnDestroy(): void {
    RxUtil.cleanupSubscription(this.controlChangesSub);
    RxUtil.cleanupSubscription(this.controlFormChangesSub);
  }

  @HostListener('window:resize') onWindowResize() {
    this.changeDetectorRef.markForCheck();
  }

  getTopBorderBoxStyle(mirrorSource: HTMLDivElement): { [k: string]: string } {
    return {
      left: mirrorSource.offsetLeft + 'px',
      width: mirrorSource.offsetWidth + 'px'
    };
  }
}
