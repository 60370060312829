import { Injectable } from '@angular/core';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import { AbstractControl, ValidationErrors } from '@angular/forms';

interface IdEntity {
  id: string;
}

const countryCodesSupported = ['US', 'VI', 'MP', 'GU', 'AS', 'PR', 'CA'];

@Injectable({
  providedIn: 'root'
})
export class UiService {
  readonly stdAnimationTimeMS = 300;

  stdEntityIdTrackByFn(i: number, entity: IdEntity): string {
    return entity.id;
  }

  formatPhoneInputAsYouType(inputEl: EventTarget | null) {
    if (inputEl) {
      const target = inputEl as HTMLInputElement;
      // only format long enough numbers so that we don't get stuck in the
      // situation where the user cannot backspace the ")" character
      if (target.value.length > 4) {
        target.value = new AsYouType('US').input(target.value);
      }
    }
  }

  limitPhoneNumberLength(inputEl: EventTarget | null) {
    if (inputEl) {
      const target = inputEl as HTMLInputElement;
      if (target.value.length >= 11) {
        // phone numbers are 10 digits, but here we want to limit
        // user input to 11 digits to cover country code entry
        target.value = target.value.substr(0, 11);
      }
    }
  }

  phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    const parsedPhoneNumber = parsePhoneNumberFromString(control.value, 'US');
    const isSupportedCountryCode = countryCodesSupported.includes(
      parsedPhoneNumber?.country || ''
    );

    return parsedPhoneNumber &&
      parsedPhoneNumber.isValid() &&
      isSupportedCountryCode
      ? null
      : { phone: true };
  }

  computeScrollRight(scrollContainerEl: HTMLElement): number {
    return (
      scrollContainerEl.scrollWidth -
      scrollContainerEl.clientWidth -
      scrollContainerEl.scrollLeft
    );
  }
}
