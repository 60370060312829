export const V2Colors = [
  'primary',
  'primary-tint',
  'secondary',
  'secondary-tint',
  'white',
  'off-white',
  'light-grey',
  'grey',
  'inactive-grey',
  'medium-grey',
  'dark-grey',
  'darkest-grey',
  'black',
  'alert-orange',
  'service-blue',
  'discount-green'
] as const;

export type V2Color = typeof V2Colors[number];
