import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICard } from '../models';
import { PaymentProvider } from '../pages/v2-order-pay/models/payment.model';
import { LocationStore } from './location.store';

@Injectable({
  providedIn: 'root'
})
export class StripeStore {
  constructor(private locationStore: LocationStore) {}

  private readonly _defaultStripeCreditCard$ = new BehaviorSubject<ICard | null>(
    null
  );
  readonly defaultStripeCreditCard$ = this._defaultStripeCreditCard$.asObservable();
  get defaultStripeCreditCard(): ICard | null {
    return this._defaultStripeCreditCard$.getValue();
  }
  set defaultStripeCreditCard(value: ICard | null) {
    this._defaultStripeCreditCard$.next(value);
  }

  readonly isStripePaymentAvailable$: Observable<boolean> = combineLatest([
    this.locationStore.currentLocation$,
    this.defaultStripeCreditCard$
  ]).pipe(
    map(
      ([location, defaultCreditCard]) =>
        location.paymentProcessor.provider === PaymentProvider.Stripe &&
        !!defaultCreditCard
    )
  );

  private _isFormValid$ = new BehaviorSubject<boolean>(false);
  readonly isFormValid$ = this._isFormValid$.asObservable();

  set isFormValid(isFormValid: boolean) {
    this._isFormValid$.next(isFormValid);
  }

  private _cardError$ = new BehaviorSubject<string | null>(null);
  readonly cardError$ = this._cardError$.asObservable();

  set cardError(error: string | null) {
    this._cardError$.next(error);
  }
}
