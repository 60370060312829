import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Subscription, map } from 'rxjs';
import { ChatAssistantService } from 'services/chat-assistant.service';
import { ChatMessage } from 'src/app/models/chat-assistant.model';
import { ChatAssistantStore } from 'stores/chat-assistant.store';
import { OrderPayStore } from 'stores/order-pay.store';

@Component({
  selector: 'chat-assistant-dialogue',
  templateUrl: 'chat-assistant-dialogue.component.html',
  styleUrls: ['chat-assistant-dialogue.component.scss']
})
export class ChatAssistantDialogue implements OnInit, AfterViewInit {
  userMessage: string = '';
  messages: ChatMessage[] = [];
  beaconId: string = '';
  private subscriptions: Subscription[] = [];
  private shouldScrollToBottom: boolean = false;

  constructor(
    public chatAssistantStore: ChatAssistantStore,
    private chatAssistantService: ChatAssistantService,
    private orderPayStore: OrderPayStore
  ) {}

  chatState$ = this.chatAssistantStore.chatState$.pipe(
    map((chatState) => chatState)
  );

  ngOnInit(): void {
    const subscribtion = this.chatState$.subscribe((state) => {
      this.messages = state.chatSession.messages.sort(
        (a, b) => a.timestamp - b.timestamp
      );
      this.beaconId = this.orderPayStore.beaconId;
      this.shouldScrollToBottom = true;
    });

    this.subscriptions.push(subscribtion);
    this.initializeChat(this.orderPayStore.beaconId);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  }

  ngAfterViewChecked(): void {
    if (this.shouldScrollToBottom) {
      this.scrollToBottom();
      this.shouldScrollToBottom = false;
    }
  }

  private scrollToBottom(): void {
    const element = document.getElementById('chatMessagesContainer');
    element?.scrollTo({
      top: element.scrollHeight,
      behavior: 'smooth'
    });
  }

  sendMessage(beaconId: string, message: string): void {
    this.scrollToBottom();
    this.messages.push({
      text: message,
      role: 'user',
      timestamp: Date.now()
    });
    this.userMessage = '';
    this.chatAssistantService.sendMessage(beaconId, message);
    this.shouldScrollToBottom = true;
  }

  handleSelectPrompt(buttonText: string): void {
    this.sendMessage(this.beaconId, buttonText);
  }

  handleInputSubmission(newMessage: string): void {
    this.sendMessage(this.beaconId, newMessage);
  }

  initializeChat(beaconId: string): void {
    this.chatAssistantService.startConversation(beaconId);
  }
}
