import { Component, Input } from '@angular/core';
import { IInvoice } from '../../models';
import { NavController } from '@ionic/angular';
import { unawaited } from '../../utility/unawaited';

@Component({
  selector: 'order-history-preview',
  templateUrl: 'order-history-preview.html',
  styleUrls: ['order-history-preview.scss']
})
export class OrderHistoryPreviewComponent {
  @Input() invoice: IInvoice | undefined;

  constructor(private navCtrl: NavController) {}

  openReceipt() {
    unawaited(
      this.navCtrl.navigateForward(['transaction-details', this.invoice?.id], {
        state: {
          invoice: this.invoice
        }
      })
    );
  }
}
