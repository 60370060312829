import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { V2Color } from '../models/v2-colors.model';

@Component({
  selector: 'v2-accordion-list-item',
  templateUrl: 'accordion-list-item.html',
  styleUrls: ['accordion-list-item.scss']
})
export class V2AccordionListItemComponent {
  @HostBinding('class.highlight') showBody: boolean = false;
  @Input() iconColor: V2Color = 'white';

  @HostListener('click') onClick() {
    this.setShow(!this.showBody);
  }

  setShow(value: boolean) {
    this.showBody = value;
  }
}
