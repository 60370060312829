import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot
} from '@angular/router';
import { NavController } from '@ionic/angular';
import { LocationStore } from '../stores/location.store';

// This guard ensures child routes have the table loaded before the route loads.
// If the table hasn't been loaded, set the scan page as the navigation root and
// the loading will be done there with a loading animation.
@Injectable({
  providedIn: 'root'
})
export class TableScanGuard implements CanActivateChild {
  constructor(
    private navCtrl: NavController,
    private locationStore: LocationStore
  ) {}

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    if (this.locationStore.currentLocation) {
      return true;
    } else {
      await this.navCtrl.navigateRoot([
        'scan',
        childRoute.paramMap.get('beaconid')
      ]);
      return false;
    }
  }
}
