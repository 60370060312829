import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { unawaited } from 'utility/unawaited';

@Component({
  selector: 'unbranded-header',
  templateUrl: 'unbranded-header.html',
  styleUrls: ['unbranded-header.scss']
})
export class UnbrandedHeader {
  constructor(public navCtrl: NavController) {}

  public navigateBack() {
    unawaited(this.navCtrl.pop());
  }
}
