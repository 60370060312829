import { Component, Input } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';
import { ReceiptsService } from '../../services/receipts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'receipt-email-form',
  templateUrl: 'receipt-email-form.html',
  styleUrls: ['receipt-email-form.scss']
})
export class ReceiptEmailFormComponent {
  @Input() invoice?: { id: any; _id: any };
  @Input() beaconId?: string;
  emailSent?: boolean;
  showEmailForm: boolean = true;
  emailMessage?: string;
  emailAddress?: string;
  confirmedEmail?: string;

  constructor(
    private receiptsService: ReceiptsService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService
  ) {}

  setReceiptSuccess(email: string) {
    this.emailSent = true;
    this.confirmedEmail = email ? email : this.emailAddress;
    this.emailAddress = '';
    this.emailMessage = this.translateService.instant(
      'TransactionDetails.EmailMessage1'
    );
  }

  setReceiptError() {
    this.emailSent = false;
    this.emailMessage = this.translateService.instant(
      'TransactionDetails.EmailMessage2'
    );
  }

  toggleEmailForm() {
    this.showEmailForm = !this.showEmailForm;
  }

  resetEmailForm() {
    this.emailSent = false;
    this.emailMessage = '';
  }

  public setEmailAddress(email?: string) {
    this.emailMessage = '';
    if (!this.invoice) return;

    email = email || this.emailAddress;
    if (!email) return;

    // save this email address to the user account in a 'unverified_email' field...
    // we don't want them to be able to change their temporary email address to one that already exists in the system..
    // worry about that on the next step
    this.receiptsService
      .emailReceipt(this.invoice.id || this.invoice._id, email)
      .then(() => {
        this.setReceiptSuccess(email!);
        this.analyticsService.log('Receipt Emailed');
      })
      .catch(() => {
        this.setReceiptError();
      });
  }
}
