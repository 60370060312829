import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { V2Color } from '../../models/v2-colors.model';

@Component({
  selector: 'v2-progress-spinner',
  templateUrl: 'v2-progress-spinner.component.html',
  styleUrls: ['v2-progress-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2ProgressSpinner {
  @Input() size: number = 20;
  @Input() color: V2Color = 'grey';
}
