import { CustomTipType } from '../pages/v2-order-pay/models/custom-tip-type.model';
import { nil } from '../v2-components/helpers/nil.helper';

export interface LocationUiModel {
  id: string;
  openTabGuestPresenceValidationMethods: GuestPresenceValidationMethods[];
  openTabGuestPresenceTargetScanExpiryMessage: string;
  openTabGuestPresenceTargetScanDurationMinutes: number;
  finalNavigationText: string;
  customPaymentMessage: string;
  menuReadOnly: boolean;
  menuItemPinningEnabled: boolean;
  orderHours: IOrderHoursUiModel[];
  timezone: string;
  payEnabled: boolean;
  locale: SupportedLocale;
  defaultCustomTipType: CustomTipType;

  requireEmailAddressOnCheckout: boolean;
}

export interface IOrderHoursUiModel {
  day: number;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  offset: number;
  disabled: boolean | nil;
}

export type GuestPresenceValidationMethods = 'phone' | 'targetScan';

export type OverridableLocationProps = Partial<
  Pick<LocationUiModel, 'payEnabled'>
>;

export enum SupportedLocale {
  EnUS = 'en-US',
  EsMX = 'es-MX'
}
