import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  Type,
  ViewChild
} from '@angular/core';
import { ComponentInsertionDirective } from '../../directives/component-insertion.directive';
import { V2BottomSheetRef } from '../../helpers/v2-bottom-sheet-ref';
import { V2BottomSheetContainer } from '../../helpers/v2-bottom-sheet-container';
import { AsyncUtil } from 'utility/async.util';

@Component({
  selector: 'v2-scrollable-bottom-sheet-variation-header',
  templateUrl: 'v2-scrollable-bottom-sheet-variation-header.component.html',
  styleUrls: ['v2-scrollable-bottom-sheet-variation-header.component.scss']
})
export class V2ScrollableBottomSheetVariationHeader
  extends V2BottomSheetContainer<unknown>
  implements AfterViewInit, OnDestroy
{
  @ViewChild('insertionCont', { read: ElementRef })
  private insertionCont?: ElementRef;
  @ViewChild('insertionTitle', { read: ElementRef })
  private insertionTitle?: ElementRef;
  @ViewChild(ComponentInsertionDirective, { static: true })
  private insertionPoint?: ComponentInsertionDirective;
  private titleObserver?: IntersectionObserver;

  titleText?: string;
  descriptionText?: string;
  showCardHeaderTitle: boolean = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    public bottomSheetRef: V2BottomSheetRef
  ) {
    super();
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();

    if (this.titleText) {
      this.titleObserver = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          if (entries.length > 0) {
            this.showCardHeaderTitle = entries[0].intersectionRatio < 0.35;
          }
        },
        {
          root: this.insertionCont?.nativeElement,
          threshold: [0, 0.25, 0.5, 0.75, 1]
        }
      );
      this.titleObserver.observe(this.insertionTitle?.nativeElement);
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.titleObserver) {
      this.titleObserver.disconnect();
    }
  }

  @HostListener('click') onClick() {
    if (this.bottomSheetRef.closeOnBackdropClick) {
      this.bottomSheetRef.close();
    }
  }

  private loadChildComponent(componentType?: Type<unknown>) {
    if (!componentType) return;
    const viewContainerRef = this.insertionPoint?.viewContainerRef;
    viewContainerRef?.clear();

    this.componentRef = viewContainerRef?.createComponent<unknown>(
      this.componentFactoryResolver.resolveComponentFactory(componentType),
      undefined,
      Injector.create({
        providers: [
          { provide: V2BottomSheetRef, useValue: this.bottomSheetRef }
        ]
      })
    );
  }

  // The slide-in animation happens automatically, but the slide-out must be
  // called explicitly (this is managed by the bottom-sheet.service)
  async slideOut(): Promise<void> {
    this.slideOutCommenced = true;
    await AsyncUtil.sleep(300);
  }
}
