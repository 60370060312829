import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class V2ToastRef {
  readonly primaryText: string;
  readonly secondaryText?: string;

  readonly bottomPageOffset$ = new BehaviorSubject<number>(0);

  constructor(primaryText: string, secondaryText?: string) {
    this.primaryText = primaryText;
    this.secondaryText = secondaryText;
  }

  close() {
    this._onClose$.next();
    this._onClose$.complete();
  }

  // TODO reconsider making this a promise
  private readonly _onClose$ = new Subject<void>();
  readonly onClose$: Observable<void> = this._onClose$.asObservable();
}
