import { Injectable } from '@angular/core';
import { ICard, IStripeToken, IUser } from '../models';
import { ReadyHttpClient } from './ready-http-client';
import {
  IUserVerifyPhoneCodeResponse,
  IUserSubmitPhoneResponse
} from './contracts/user.contracts';

@Injectable({
  providedIn: 'root'
})
export class UserApi {
  constructor(private http: ReadyHttpClient) {}

  getUserById(userId: string): Promise<IUser> {
    return this.http.get<IUser>(`/customer?userid=${userId}`);
  }

  submitPhoneNumber(phone: string): Promise<IUserSubmitPhoneResponse> {
    return this.http.post<IUserSubmitPhoneResponse>(`/customers/code`, {
      phone
    });
  }

  verifyPhoneCode(code: string): Promise<IUserVerifyPhoneCodeResponse> {
    return this.http.post<IUserVerifyPhoneCodeResponse>(
      `/customers/verifyphonecode`,
      {
        code
      }
    );
  }

  addCustomerCard(cardDetails: ICard | IStripeToken): Promise<any> {
    return this.http.post<any>(`/customer/card`, cardDetails);
  }
}
