import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { V2Color, V2Colors } from '../../models/v2-colors.model';
import { nil } from '../../helpers/nil.helper';

@Component({
  selector: 'v2-text',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['v2-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Text {
  @Input() color: V2Color | string | nil = 'black';

  // Use this to restrict the number of vertical lines with a trailing ellipsis.
  @Input()
  @HostBinding('class.line-clamp')
  @HostBinding('style.-webkit-line-clamp')
  lineClamp: number | nil;

  @HostBinding('class') private get class() {
    return this.color && V2Colors.includes(this.color as V2Color)
      ? `color-${this.color}`
      : null;
  }

  @HostBinding('style.color') private get styleColor() {
    return this.color && V2Colors.includes(this.color as V2Color)
      ? null
      : this.color;
  }
}
