import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationStore } from './location.store';
import { Invoice2Store } from 'stores/invoice2.store';
import { TipStore } from 'stores/tip.store';

@Injectable({
  providedIn: 'root'
})
export class CheckoutStore {
  constructor(
    private locationStore: LocationStore,
    private invoice2Store: Invoice2Store,
    private tipStore: TipStore
  ) {}

  private _customerEmail$ = new BehaviorSubject<string | null>(null);

  get customerEmail(): string | null {
    return this._customerEmail$.getValue();
  }

  set customerEmail(v: string | null) {
    this._customerEmail$.next(v);
  }

  readonly totalDue$: Observable<number> = combineLatest([
    this.invoice2Store.invoiceTotals$,
    this.locationStore.currentLocation$,
    this.tipStore.tipAmountCents$
  ]).pipe(
    map(
      ([invoiceTotals, location, tipAmountCents]) =>
        invoiceTotals.due + (location.tipsDisallowed ? 0 : tipAmountCents)
    )
  );

  private _showReceiptSentBanner$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly showReceiptSentBanner$: Observable<boolean> =
    this._showReceiptSentBanner$.asObservable();

  set showReceiptSentBanner(v: boolean) {
    this._showReceiptSentBanner$.next(v);
  }
}
