import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationStore } from './location.store';
import { IMenuTheme } from '../pages/v2-order-pay/models/menu-theme.model';
import { ILocationTheme } from '../models/location-theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeStore {
  constructor(private locationStore: LocationStore) {}

  private readonly _initialTheme$: BehaviorSubject<ILocationTheme | null> =
    new BehaviorSubject<ILocationTheme | null>(null);

  get initialTheme(): ILocationTheme | null {
    return this._initialTheme$.getValue();
  }

  set initialTheme(v: ILocationTheme | null) {
    this._initialTheme$.next(v);
  }

  private readonly _primaryColor$ = new BehaviorSubject('');
  readonly primaryColor$: Observable<string> =
    this._primaryColor$.asObservable();

  get primaryColor(): string {
    return this._primaryColor$.getValue();
  }

  set primaryColor(v: string) {
    this._primaryColor$.next(v);
  }

  private readonly _secondaryColor$ = new BehaviorSubject('');

  get secondaryColor(): string {
    return this._secondaryColor$.getValue();
  }

  set secondaryColor(v: string) {
    this._secondaryColor$.next(v);
  }

  readonly headerBackgroundImage$: Observable<string | null> =
    this.locationStore.currentLocation$.pipe(
      map((location) => location.theme?.images?.['image-header-small'] ?? null)
    );

  private readonly _menuTheme$ = new BehaviorSubject<IMenuTheme | null>(null);
  readonly menuTheme$ = this._menuTheme$.asObservable();

  setMenuTheme(t: IMenuTheme) {
    this._menuTheme$.next(t);
  }
}
