import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RxUtil } from 'utility/rx.util';

import { Signals } from 'services/signals.service';

@Injectable({
  providedIn: 'root'
})
export class TableRefreshStore {
  private _events$: BehaviorSubject<Signals | null> =
    new BehaviorSubject<Signals | null>(null);

  public readonly events$: Observable<Signals> = this._events$.pipe(
    filter(RxUtil.inputIsNotNullOrUndefined)
  );

  public publishEvent(event: Signals) {
    this._events$.next(event);
  }
}
