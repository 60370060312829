import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ChatAssistantStore } from 'stores/chat-assistant.store';
import { ThemeStore } from 'stores/theme.store';
import { TableStore } from 'stores/table.store';

@Component({
  selector: 'chat-assistant-icon',
  templateUrl: './chat-assistant-icon.html',
  styleUrls: ['./chat-assistant-icon.scss']
})
export class ChatAssistantIconComponent implements OnInit {
  primaryColor$: Observable<string>;
  primaryColor: string = '';
  chatFeatureEnabled: boolean = false;
  private subscriptions: Subscription = new Subscription();
  private serverFlowConfig$ = this.tableStore.serverFlowConfig$;

  constructor(
    public chatAssistantStore: ChatAssistantStore,
    public themeStore: ThemeStore,
    public tableStore: TableStore
  ) {
    this.primaryColor$ = this.themeStore.primaryColor$;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.primaryColor$.subscribe((color) => {
        this.primaryColor = color;
      })
    );
    this.subscriptions.add(
      this.serverFlowConfig$.subscribe((config) => {
        this.chatFeatureEnabled =
          (config?.serverFlowFeatureEnabled &&
            config?.chatAssistantFeatureEnabled) ||
          false;
      })
    );    
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
