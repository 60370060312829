import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UiService } from '../../../../services/ui.service';
import { V2BottomSheetRef } from '../../../../v2-components/helpers/v2-bottom-sheet-ref';
import { UserService } from '../../../../services/user.service';
import { RaygunService } from '../../../../services/raygun.service';
import { FullScreenLoadingService } from '../../../../services/full-screen-loading.service';
import { UserStore } from '../../../../stores/user.store';
import { unawaited } from '../../../../utility/unawaited';
import { AnalyticsService } from '../../../../services/analytics.service';
import { AsyncUtil } from 'utility/async.util';

@Component({
  selector: 'phone-verification-2',
  templateUrl: 'phone-verification-2.sheet.html',
  styleUrls: ['phone-verification-2.sheet.scss']
})
export class PhoneVerification2Sheet implements OnInit {
  readonly verificationCodeInputControl = new FormControl(
    '',
    Validators.compose([Validators.required])
  );
  readonly resendWaitTimeSeconds = 25;

  isSubmitting = false;
  isAwaitingResend = false;
  currentResendIntervalSecondsElapsed = 0;

  constructor(
    private uiService: UiService,
    public bottomSheetRef: V2BottomSheetRef<boolean>,
    private userService: UserService,
    private raygunService: RaygunService,
    private fullScreenLoadingService: FullScreenLoadingService,
    public userStore: UserStore,
    private analyticsProvider: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.analyticsProvider.log('Phone verification sheet 2 shown');
  }

  async onSubmit(): Promise<void> {
    this.verificationCodeInputControl.markAsTouched();
    if (!this.verificationCodeInputControl.valid) return;

    this.verificationCodeInputControl.setErrors(null);
    this.isSubmitting = true;
    this.fullScreenLoadingService.showFullScreenLoader();
    try {
      await this.userService.verifyPhoneNumber(
        this.verificationCodeInputControl.value
      );
      this.bottomSheetRef.close(true);
    } catch (e) {
      this.verificationCodeInputControl.setErrors({ incorrect: true });
      this.raygunService.logToRayGun(e);
    } finally {
      this.isSubmitting = false;
      this.fullScreenLoadingService.hideFullScreenLoader();
    }
  }

  async resendCode(): Promise<void> {
    this.isAwaitingResend = true;

    unawaited(
      (async () => {
        try {
          await this.userService.submitPhoneNumber(
            this.userStore.currentUser?.phone!
          );
        } catch (e) {
          this.raygunService.logToRayGun(e);
        }
      })()
    );

    while (
      this.currentResendIntervalSecondsElapsed++ < this.resendWaitTimeSeconds
    ) {
      await AsyncUtil.sleep(1000);
    }

    this.isAwaitingResend = false;
    this.currentResendIntervalSecondsElapsed = 0;
  }
}
