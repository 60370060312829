import { ErrorHandler, Injectable } from '@angular/core';
import { RaygunService } from './services/raygun.service';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private raygunProvider: RaygunService) {}

  handleError(e: Error) {
    this.raygunProvider.logToRayGun(e);
  }
}
