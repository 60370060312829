import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  HostListener,
  Injector,
  OnDestroy,
  Type,
  ViewChild
} from '@angular/core';
import { ComponentInsertionDirective } from '../../directives/component-insertion.directive';
import {
  V2BottomSheetRef,
  V2ScrollableContentBottomSheetRef
} from '../../helpers/v2-bottom-sheet-ref';
import { V2BottomSheetContainer } from '../../helpers/v2-bottom-sheet-container';
import { AsyncUtil } from 'utility/async.util';

@Component({
  selector: 'v2-bottom-sheet',
  templateUrl: 'v2-bottom-sheet.component.html',
  styleUrls: ['v2-bottom-sheet.component.scss']
})
export class V2BottomSheet
  extends V2BottomSheetContainer<unknown>
  implements AfterViewInit, OnDestroy
{
  @ViewChild(ComponentInsertionDirective, { static: true })
  private insertionPoint?: ComponentInsertionDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private bottomSheetRef: V2ScrollableContentBottomSheetRef
  ) {
    super();
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  @HostListener('click') onClick() {
    if (this.bottomSheetRef.closeOnBackdropClick) {
      this.bottomSheetRef.close();
    }
  }

  onContentScroll(e: Event) {
    this.bottomSheetRef.registerContentScroll(
      (e.target as HTMLElement).scrollTop
    );
  }

  private loadChildComponent(componentType?: Type<unknown>) {
    if (!componentType) return;
    const viewContainerRef = this.insertionPoint?.viewContainerRef;
    viewContainerRef?.clear();

    this.componentRef = viewContainerRef?.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(componentType),
      undefined,
      Injector.create({
        providers: [
          {
            provide: V2BottomSheetRef,
            useValue: this.bottomSheetRef
          },
          {
            provide: V2ScrollableContentBottomSheetRef,
            useValue: this.bottomSheetRef
          }
        ]
      })
    );
  }

  // The slide-in animation happens automatically, but the slide-out must be
  // called explicitly (this is managed by the bottom-sheet.service)
  async slideOut(): Promise<void> {
    this.slideOutCommenced = true;
    await AsyncUtil.sleep(300);
  }
}
