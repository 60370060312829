import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationStore } from './location.store';
import { FeaturesStore } from './features.store';
import { OrderLocationGroupTarget } from '../models/order-location-group.model';
import { ICustomerField } from '../models/customer-field.model';
import { ICustomerFieldResponse } from '../models';
import { ISubmittedCustomerField } from '../models/submitted-customer-field';
import { Location2Store } from './location2.store';
import { OrderAheadStore } from './order-ahead.store';

@Injectable({
  providedIn: 'root'
})
export class OrderStore {
  constructor(
    private locationStore: LocationStore,
    private location2Store: Location2Store,
    private featuresStore: FeaturesStore,
    private orderAheadStore: OrderAheadStore
  ) {}

  // default list
  private readonly _orderLocationGroupTargets$: BehaviorSubject<
    OrderLocationGroupTarget[]
  > = new BehaviorSubject<OrderLocationGroupTarget[]>([]);
  readonly orderLocationGroupTargets$: Observable<OrderLocationGroupTarget[]> =
    this._orderLocationGroupTargets$.asObservable();

  setOrderLocationGroupTargets(targets: OrderLocationGroupTarget[]) {
    this._orderLocationGroupTargets$.next(targets);
  }

  // closest locations
  private readonly _v2OrderLocationGroupTargets$: BehaviorSubject<
    OrderLocationGroupTarget[]
  > = new BehaviorSubject<OrderLocationGroupTarget[]>([]);
  readonly v2OrderLocationGroupTargets$: Observable<
    OrderLocationGroupTarget[]
  > = this._v2OrderLocationGroupTargets$.asObservable();

  setV2OrderLocationGroupTargets(targets: OrderLocationGroupTarget[]) {
    this._v2OrderLocationGroupTargets$.next(targets);
  }

  private readonly _customerFieldResponses$: BehaviorSubject<
    ICustomerFieldResponse[]
  > = new BehaviorSubject<ICustomerFieldResponse[]>([]);

  get customerFieldResponses(): ICustomerFieldResponse[] {
    return this._customerFieldResponses$.getValue();
  }

  setCustomerFieldResponses(v: ICustomerFieldResponse[]) {
    this._customerFieldResponses$.next(v);
  }

  private readonly _submitOrderErrorMessage$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  get submitOrderErrorMessage(): string {
    return this._submitOrderErrorMessage$.getValue();
  }

  setSubmitOrderErrorMessage(v: string) {
    this._submitOrderErrorMessage$.next(v);
  }

  readonly doesOrderAheadAllowOrdering$: Observable<boolean> = combineLatest([
    this.featuresStore.isOrderingAheadEnabled$,
    this.orderAheadStore.orderAheadTimes$,
    this.orderAheadStore.isOrderAheadForFutureDaysEnabled$,
    this.orderAheadStore.isCurrentLocationOpenNow$,
  ]).pipe(
    map(
      ([
        isOrderingAheadEnabled,
        orderAheadTimes,
        isOrderAheadForFutureDaysEnabled,
        isCurrentLocationOpenNow
      ]) => {
        // if order ahead is enabled, users can only order for same-day only, user should be able to order
        // if the current time is within the configured time range, otherwise, they shouldn't.
        if (isOrderingAheadEnabled && !isOrderAheadForFutureDaysEnabled) {
          return !!orderAheadTimes?.canOrder;
        }

        // if order ahead is enabled, users are allowed to order for future days, and there are actually time slots
        // configured, then we allow ordering even if we are outside the time range.
        if (isOrderingAheadEnabled && isOrderAheadForFutureDaysEnabled) {
          return !!orderAheadTimes?.timeSlots?.length;
        }
       
        // if order ahead is disabled and if the location is not open now (based on store hours), then we don't allow ordering.
        if (!isCurrentLocationOpenNow && !isOrderingAheadEnabled) {
          return false;
        }

        // if there are any other cases, we allow ordering.
        return true;

      }
    )
  );

  // Emits true when location setting is enabled, and if ordering ahead is enabled
  // then this also takes whether the user can place an order at the current time
  // into account.
  readonly isOrderingEnabled$: Observable<boolean> = combineLatest([
    this.locationStore.currentLocation$,
    this.doesOrderAheadAllowOrdering$
  ]).pipe(
    map(
      ([location, doesOrderAheadAllowOrdering]) =>
        location.orderingEnabled && doesOrderAheadAllowOrdering
    )
  );

  readonly customerFields$: Observable<ICustomerField[]> =
    this.locationStore.currentLocation$.pipe(
      map((location) =>
        Array.isArray(location.customerFields) ? location.customerFields : []
      )
    );

  readonly customerFieldResponsesWithConfig$: Observable<
    Array<ISubmittedCustomerField>
  > = combineLatest([this.customerFields$, this._customerFieldResponses$]).pipe(
    map(([customerFields, customerFieldResponses]) =>
      customerFieldResponses
        .filter((r) => r.value && r.value.trim().length > 0)
        .map(
          (r) =>
            ({
              value: r.value,
              ...customerFields.find((f) => f.inputLabel === r.id)
            } as ISubmittedCustomerField)
        )
    )
  );

  // if there are customer fields there is always one primary field
  readonly primaryFieldToDisplay$: Observable<ISubmittedCustomerField> =
    this.customerFieldResponsesWithConfig$.pipe(
      map((responses) => {
        const properPrimaryField = responses.find(
          (response) => response.isPrimaryOnPaymentConfirmationScreen
        );
        return properPrimaryField && properPrimaryField.inputIsRequired
          ? properPrimaryField
          : responses.find((response) => response.value)!;
      })
    );

  readonly additionalCustomerFieldsToDisplay$: Observable<
    ISubmittedCustomerField[]
  > = combineLatest([
    this.primaryFieldToDisplay$,
    this.customerFieldResponsesWithConfig$
  ]).pipe(
    map(([primaryField, responses]) => {
      responses.splice(
        responses.findIndex(
          (response) => primaryField.value === response.value
        ),
        1
      );
      return responses;
    })
  );

  readonly arePickupDetailsRequired$: Observable<boolean> = combineLatest([
    this.featuresStore.isOrderingAheadEnabled$,
    this.orderAheadStore.orderAheadTimes$,
    this.customerFields$
  ]).pipe(
    map(
      ([isOrderingAheadEnabled, orderAheadTimes, customerFields]) =>
        (isOrderingAheadEnabled && !!orderAheadTimes) ||
        customerFields.length > 0
    )
  );

  readonly finalNavigationText$: Observable<string> =
    this.location2Store.currentLocation$.pipe(
      map((location) => location.finalNavigationText)
    );
}
