import { NgModule } from '@angular/core';
import { TableScanGuard } from './route-guards/table-scan.guard';
import { Router, RouterModule, Routes } from '@angular/router';
import { AppRoutingPreloaderService } from './services/app-routing-preloader.service';
import { markRoutesAsPreloadable } from './utility/markRoutesAsPreloadable';
import { RaygunService } from './services/raygun.service';
import { OrderLocationSelectorGuard } from './route-guards/order-location-selector.guard';
import { BeaconIdGuard } from './route-guards/beaconId.guard';

const routes: Routes = [
  { path: '', redirectTo: 'web-home', pathMatch: 'full' },
  {
    path: 'account', // account is really "transaction-history"
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountPageModule),
    data: { name: 'Account' }
  },
  {
    path: ':mode/:beaconid/code-not-found',
    loadChildren: () =>
      import('./pages/code-not-found/code-not-found.module').then(
        (m) => m.CodeNotFoundPageModule
      ),
    data: { name: 'Code Not Found' }
  },
  {
    path: 'report-unmapped-table',
    loadChildren: () =>
      import('./pages/report-unmapped-table/report-unmapped-table.module').then(
        (m) => m.ReportUnmappedTablePageModule
      ),
    data: { name: 'Report Unmapped Table' },
    canActivateChild: [BeaconIdGuard]
  },
  {
    path: 'unmapped-table-report-submitted',
    loadChildren: () =>
      import(
        './pages/unmapped-table-report-submitted/unmapped-table-report-submitted.module'
      ).then((m) => m.UnmappedTableReportSubmittedModule),
    data: { name: 'Unmapped Table Report Submitted' }
  },
  {
    path: 'enter-table-code',
    loadChildren: () =>
      import('./pages/enter-table-code/enter-table-code.module').then(
        (m) => m.EnterTableCodePageModule
      ),
    data: { name: 'Enter Table Code' }
  },
  {
    path: 'scan/:beaconid',
    loadChildren: () =>
      import('./pages/scan/scan.module').then((m) => m.ScanPageModule),
    data: { name: 'All Scans' }
  },
  {
    path: 'scan-barcode',
    loadChildren: () =>
      import('./pages/scan-barcode/scan-barcode.module').then(
        (m) => m.ScanBarcodePageModule
      ),
    data: { name: 'Scan QR Code' }
  },
  {
    path: 'scanner-error',
    loadChildren: () =>
      import('./pages/scanner-error/scanner-error.module').then(
        (m) => m.ScannerErrorPageModule
      ),
    data: { name: 'QR Code Scanning Error' }
  },
  {
    path: 'transaction-details/:invoiceid',
    loadChildren: () =>
      import('./pages/transaction-details/transaction-details.module').then(
        (m) => m.TransactionDetailsPageModule
      ),
    data: { name: 'Transaction Details' }
  },
  {
    path: 'v2-order-location-selector',
    loadChildren: () =>
      import(
        './pages/v2-order-location-selector/v2-order-location-selector.module'
      ).then((m) => m.V2OrderLocationSelectorModule),
    data: { name: 'V2 Order Location Selector' },
    canActivate: [OrderLocationSelectorGuard]
  },
  {
    path: 'web-help',
    loadChildren: () =>
      import('./pages/web-help/web-help.module').then(
        (m) => m.WebHelpPageModule
      ),
    data: { name: 'Help' }
  },
  {
    path: 'web-home',
    loadChildren: () =>
      import('./pages/web-home/web-home.module').then(
        (m) => m.WebHomePageModule
      ),
    data: { name: 'Home' }
  },
  {
    path: ':beaconid',
    canActivateChild: [TableScanGuard],
    children: [
      // v2 is synonymous with the merged order pay flow
      {
        path: 'v2',
        loadChildren: () =>
          import('./pages/v2-order-pay/v2-order-pay.module').then(
            (m) => m.V2OrderPayModule
          )
      },
      { path: '**', redirectTo: 'v2' }
    ]
  }
];

// make all top level routes eligible for preloading
markRoutesAsPreloadable(routes);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: AppRoutingPreloaderService,
      useHash: false,
      // TODO migrate to new method
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule],
  providers: [TableScanGuard]
})
export class AppRoutingModule {
  constructor(private router: Router, private raygunService: RaygunService) {
    this.router.errorHandler = (e: any) => {
      this.router.navigate(['web-home']);
      this.raygunService.logToRayGun(e);
    };
  }
}
