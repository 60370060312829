import { Observable } from 'rxjs';
import { NgControl } from '@angular/forms';
import { V2InputErrorStateMatcher } from './v2-input-error-state-matcher';
import { nil } from './nil.helper';

export abstract class V2FormFieldControl<T> {
  value: T | nil;

  /**
   * Stream that emits whenever the state of the control changes such that the parent `V2FormField`
   * needs to run change detection.
   */
  readonly stateChanges!: Observable<void>;

  // The element ID for this control
  readonly id!: string;

  // Gets the NgControl for this control
  readonly ngControl: NgControl | nil;

  // Whether the control is focused
  readonly focused!: boolean;

  // Whether the control is required
  readonly required!: boolean;

  // Whether the control is disabled
  readonly disabled!: boolean;

  // A class used to control when error messages are shown
  readonly errorStateMatcher?: V2InputErrorStateMatcher;

  // Whether the control is in an error state
  readonly errorState!: boolean;
}
