import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector
} from '@angular/core';
import { FullScreenLoading } from '../components/full-screen-loading.component';

@Injectable({
  providedIn: 'root'
})
export class FullScreenLoadingService {
  private fullScreenLoadingComponentRef:
    | ComponentRef<FullScreenLoading>
    | undefined;
  private isLoaderVisible: boolean = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector
  ) {}

  showFullScreenLoader() {
    if (this.isLoaderVisible) return;

    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(FullScreenLoading)
      .create(this.injector);
    this.applicationRef.attachView(componentRef.hostView);

    document.body.appendChild(
      (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement
    );

    this.fullScreenLoadingComponentRef = componentRef;
    this.isLoaderVisible = true;
  }

  hideFullScreenLoader() {
    if (this.fullScreenLoadingComponentRef) {
      this.applicationRef.detachView(
        this.fullScreenLoadingComponentRef.hostView
      );
      this.fullScreenLoadingComponentRef.destroy();
    }
    this.isLoaderVisible = false;
  }
}
