import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { nil } from '../../helpers/nil.helper';

// TODO replace ripple effect with same solution used in the v2Ripple refactor
@Component({
  selector: 'v2-list-tile',
  templateUrl: 'v2-list-tile.component.html',
  styleUrls: ['v2-list-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2ListTile implements AfterViewInit, OnDestroy {
  @Input() @HostBinding('class.ion-activatable') ripple: boolean | nil = false;
  @Input() @HostBinding('class.dense') dense: boolean | nil = false;

  @ViewChild('leadingEl', { read: ElementRef })
  private leadingEl?: ElementRef;
  private leadingElResizeObserver?: ResizeObserver;
  private leadingElResizeSubject$ = new Subject<void>();
  @ViewChild('titleEl', { read: ElementRef })
  private titleEl?: ElementRef;
  private titleElResizeObserver?: ResizeObserver;
  private titleElResizeSubject$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.leadingElResizeObserver = new ResizeObserver(() =>
      this.leadingElResizeSubject$.next()
    );
    this.leadingElResizeObserver.observe(this.leadingEl!.nativeElement);
    this.titleElResizeObserver = new ResizeObserver(() =>
      this.titleElResizeSubject$.next()
    );
    this.titleElResizeObserver.observe(this.titleEl!.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.leadingElResizeObserver) this.leadingElResizeObserver.disconnect();
    if (this.titleElResizeObserver) this.titleElResizeObserver.disconnect();
  }

  // This is used to align the subtitle with the title.
  // This could also be accomplished using css grid.
  readonly subtitleMarginLeft$: Observable<string> =
    this.leadingElResizeSubject$.pipe(
      map(() => {
        const leadingEl: HTMLElement = this.leadingEl?.nativeElement;
        const leadingStyle = window.getComputedStyle(leadingEl);

        if (leadingStyle.getPropertyValue('display') === 'none') {
          return '0px';
        }

        return (
          leadingEl.offsetWidth +
          parseInt(leadingStyle.getPropertyValue('margin-right')) +
          'px'
        );
      })
    );

  // This is used to ensure there is always exactly 10px distance between
  // the title and the subtitle. This is necessary for two reasons:
  //   1. There can be variable height items in the `leading` slot.
  //   2. Certain items need to be in the top row due a design constraint
  //      that requires them to be center aligned on the x-axis.
  readonly subtitleMarginTop$: Observable<string | null> = combineLatest([
    this.leadingElResizeSubject$,
    this.titleElResizeSubject$
  ]).pipe(
    map(() => {
      const leadingEl: HTMLElement = this.leadingEl?.nativeElement;
      const titleEl: HTMLElement = this.titleEl?.nativeElement;
      const leadingRect = leadingEl.getBoundingClientRect();
      const titleRect = titleEl.getBoundingClientRect();

      if (!leadingRect.height || !titleRect.height) {
        // use the default value set in css
        return null;
      }

      let difference = 0;
      if (leadingRect.height > titleRect.height) {
        difference = (leadingRect.height - titleRect.height) / 2;
      }

      const defaultMarginTop = this.dense ? 8 : 10;
      return `${defaultMarginTop - difference}px`;
    })
  );
}
