import { Injectable } from '@angular/core';
import { add, getUnixTime } from 'date-fns';

interface StorageObject<T> {
  value: T;
  expiry?: number;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly canUseStorage: boolean;

  constructor() {
    this.canUseStorage = this.isLocalStorageAvailable();
  }

  set<T>(key: string, value: T, ttl?: Duration) {
    if (!this.canUseStorage) return;

    const so: StorageObject<T> = {
      value
    };
    if (ttl) {
      so.expiry = getUnixTime(add(new Date(), ttl));
    }
    localStorage.setItem(this.getInternalKey(key), JSON.stringify(so));
  }

  get<T>(key: string): T | null {
    if (!this.canUseStorage) return null;

    const internalKey = this.getInternalKey(key);
    const rawString = localStorage.getItem(internalKey);
    if (rawString !== null) {
      const so: StorageObject<T> = JSON.parse(rawString);
      if (typeof so.expiry === 'number') {
        const nowSeconds = Date.now() / 1000;
        if (nowSeconds > so.expiry) {
          localStorage.removeItem(internalKey);
          return null;
        }
      }
      return so.value;
    }
    return null;
  }

  clear() {
    if (!this.canUseStorage) return;

    localStorage.clear();
  }

  private getInternalKey(key: string): string {
    return `storage-service-${key}`;
  }

  private isLocalStorageAvailable(): boolean {
    try {
      const key = this.getInternalKey('ls-test');
      localStorage.setItem(key, 'foo');
      localStorage.getItem(key);
      localStorage.removeItem(key);
      return true;
    } catch {
      return false;
    }
  }
}
