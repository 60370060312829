import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OperatingSystem } from 'detect-browser';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PlatformStore {
  private readonly _isDesktop$ = new BehaviorSubject(false);
  readonly isDesktop$ = this._isDesktop$.asObservable();

  setIsDesktop(v: boolean) {
    this._isDesktop$.next(v);
  }

  private readonly _operatingSystem$ =
    new BehaviorSubject<OperatingSystem | null>(null);

  get operatingSystem() {
    return this._operatingSystem$.getValue();
  }

  setOperatingSystem(os: OperatingSystem | null) {
    this._operatingSystem$.next(os);
  }

  readonly isAndroidOS$ = this._operatingSystem$.pipe(
    map((os) => os === 'Android OS')
  );
}
