import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnDestroy,
  Type,
  ViewChild
} from '@angular/core';
import { ComponentInsertionDirective } from '../../directives/component-insertion.directive';
import { V2ToastRef } from '../../helpers/v2-toast-ref';
import { Subscription } from 'rxjs';
import { RxUtil } from 'utility/rx.util';

@Component({
  selector: 'v2-toast',
  templateUrl: 'v2-toast.component.html',
  styleUrls: ['v2-toast.component.scss']
})
export class V2Toast implements AfterViewInit, OnDestroy {
  childComponentType?: Type<unknown>;

  @ViewChild(ComponentInsertionDirective, { static: true })
  private insertionPoint?: ComponentInsertionDirective;
  private componentRef?: ComponentRef<unknown>;
  private bottomPageOffsetSub?: Subscription;

  constructor(
    private toastRef: V2ToastRef,
    private cd: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private hostElement: ElementRef
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();

    this.bottomPageOffsetSub = this.toastRef.bottomPageOffset$.subscribe(
      (bottomPageOffset) =>
        (this.hostElement.nativeElement.style.top = `calc(100% - ${bottomPageOffset}px)`)
    );

    this.animateHost([
      { transform: 'translateY(0)' },
      { transform: 'translateY(-100%)' }
    ]);
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    RxUtil.cleanupSubscription(this.bottomPageOffsetSub);
  }

  async slideOut(): Promise<void> {
    await this.animateHost([
      { transform: 'translateY(-100%)' },
      { transform: 'translateY(0)' }
    ])?.finished;
  }

  private animateHost(keyframes: Keyframe[]): Animation | undefined {
    return this.hostElement.nativeElement?.animate(keyframes, {
      duration: 300,
      easing: 'cubic-bezier(0.45, 0, 0.55, 1)',
      fill: 'forwards'
    });
  }

  private loadChildComponent(componentType?: Type<unknown>) {
    if (!componentType) return;
    const viewContainerRef = this.insertionPoint?.viewContainerRef;
    viewContainerRef?.clear();

    this.componentRef = viewContainerRef?.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(componentType)
    );
  }
}
