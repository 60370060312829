import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  GiftCardProvider,
  IGiftCard,
  IGiftCardOptions
} from '../pages/v2-order-pay/models/gift-card.model';
import { filter, map } from 'rxjs/operators';
import { CheckoutStore } from './checkout.store';
import { RxUtil } from 'utility/rx.util';
import { Invoice2Store } from 'stores/invoice2.store';

@Injectable({
  providedIn: 'root'
})
export class GiftCardStore {
  constructor(
    private checkoutStore: CheckoutStore,
    private invoice2Store: Invoice2Store
  ) {}

  private _giftCardOptions$ = new BehaviorSubject<IGiftCardOptions | null>(
    null
  );
  readonly giftCardOptions$ = this._giftCardOptions$.asObservable();

  setGiftCardConfigs(v: IGiftCardOptions) {
    this._giftCardOptions$.next(v);
  }

  private _currentGiftCard$ = new BehaviorSubject<IGiftCard | null>(null);

  readonly currentGiftCard$ = this._currentGiftCard$.asObservable();

  get currentGiftCard(): IGiftCard | null {
    return this._currentGiftCard$.getValue();
  }

  set currentGiftCard(giftCard: IGiftCard | null) {
    this._currentGiftCard$.next(
      giftCard
        ? {
            ...giftCard,
            balance: Math.round(giftCard.balance)
          }
        : null
    );
  }

  private _giftCardAmount$: BehaviorSubject<number> = new BehaviorSubject(0);

  readonly giftCardAmount$: Observable<number> =
    this._giftCardAmount$.asObservable();

  get giftCardAmount(): number {
    return this._giftCardAmount$.getValue();
  }

  set giftCardAmount(value: number) {
    this._giftCardAmount$.next(value);
  }

  private _isCustomAmount$ = new BehaviorSubject(false);

  get isCustomAmount(): boolean {
    return this._isCustomAmount$.getValue();
  }

  set isCustomAmount(value: boolean) {
    this._isCustomAmount$.next(value);
  }

  private _hasNetworkError$ = new BehaviorSubject(false);

  readonly hasNetworkError$: Observable<boolean> =
    this._hasNetworkError$.asObservable();

  set hasNetworkError(hasError: boolean) {
    this._hasNetworkError$.next(hasError);
  }

  readonly giftCardAmountDueOnBill$: Observable<number> = combineLatest([
    this.invoice2Store.invoiceTotals$,
    this.checkoutStore.totalDue$,
    this._giftCardOptions$
  ]).pipe(
    map(([invoiceTotals, totalDue, giftCardOptions]) => {
      if (giftCardOptions?.taxExempt) {
        return totalDue - invoiceTotals.tax;
      }

      return totalDue;
    })
  );

  readonly giftCardBalanceUsed$: Observable<number> = combineLatest([
    this.giftCardAmount$,
    this.giftCardAmountDueOnBill$
  ]).pipe(
    map(([giftCardAmount, giftCardAmountDueOnBill]) =>
      giftCardAmount ? Math.min(giftCardAmount, giftCardAmountDueOnBill) : 0
    )
  );

  readonly giftCardBalanceRemaining$: Observable<number> = combineLatest([
    this.currentGiftCard$,
    this.giftCardBalanceUsed$
  ]).pipe(
    map(([currentGiftCard, giftCardBalanceUsed]) =>
      currentGiftCard && currentGiftCard.balance && giftCardBalanceUsed
        ? Math.max(currentGiftCard.balance - giftCardBalanceUsed, 0)
        : 0
    )
  );

  readonly totalDueWithDeductedGiftCardAmount$: Observable<number> =
    combineLatest([
      this.checkoutStore.totalDue$,
      this.giftCardBalanceUsed$
    ]).pipe(
      map(([totalDue, giftCardBalanceUsed]) => totalDue - giftCardBalanceUsed)
    );

  readonly giftCardCoversTotal$: Observable<boolean> =
    this.totalDueWithDeductedGiftCardAmount$.pipe(
      map((giftCardTotalRemaining) => giftCardTotalRemaining === 0)
    );

  readonly giftCardProvider$: Observable<GiftCardProvider> =
    this._giftCardOptions$.pipe(
      map((options) => options?.provider),
      filter(RxUtil.inputIsNotNullOrUndefined)
    );

  readonly isGiftCardsEnabled$: Observable<boolean> =
    this.giftCardProvider$.pipe(map(([provider]) => provider !== undefined));

  readonly isGiftCardPinRequired$: Observable<boolean> = combineLatest([
    this._giftCardOptions$,
    this.isGiftCardsEnabled$
  ]).pipe(
    map(
      ([options, isGiftCardsEnabled]) =>
        isGiftCardsEnabled && !!options?.requiresPin
    )
  );

  readonly giftCardLabel$: Observable<string> = this.giftCardProvider$.pipe(
    map((provider) => {
      switch (provider) {
        case 'parx':
          return 'Membership Card';
        default:
          return 'Gift Card';
      }
    })
  );

  clearGiftCard() {
    this.currentGiftCard = null;
    this.isCustomAmount = false;
    this.giftCardAmount = 0;
  }

  setHasNetworkError(hasError: boolean) {
    this._hasNetworkError$.next(hasError);
  }
}
