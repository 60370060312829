import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { V2Color } from '../../models/v2-colors.model';
import { nil } from '../../helpers/nil.helper';

@Component({
  selector: 'v2-announcement',
  templateUrl: 'v2-announcement.component.html',
  styleUrls: ['v2-announcement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2Announcement {
  @Input() backgroundColor: V2Color | nil;
  @Input() textColor: V2Color | nil;
  @Input() text: string = '';
}
