import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { OrderStore } from '../stores/order.store';
import { map } from 'rxjs/operators';

// Only allow routing to the order location selector when there are location targets to select from,
// otherwise route back to the home page.
@Injectable({
  providedIn: 'root'
})
export class OrderLocationSelectorGuard implements CanActivate {
  constructor(private orderStore: OrderStore, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.orderStore.orderLocationGroupTargets$.pipe(
      map((targets) => (targets.length > 0 ? true : this.router.parseUrl('')))
    );
  }
}