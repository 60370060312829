import { Injectable } from '@angular/core';
import { format, isDate, isToday, isTomorrow, parseISO } from 'date-fns';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  readonly humanReadableTimeFormat: string = 'h:mm a';

  constructor(private datePipe: DatePipe) {}

  getReadableTime(time?: Date | string | null): string {
    return time
      ? format(
          this.coerceDateOrStringToDate(time),
          this.humanReadableTimeFormat
        )
      : '';
  }

  getReadableDate(date?: Date | string | null): string {
    return date ? this.datePipe.transform(date, 'mediumDate') ?? '' : '';
  }

  getReadableDateTime(
    dateTime: Date | string | null,
    todayText: string = 'Today'
  ): string {
    if (!dateTime) {
      return '';
    }

    const parsedInvoiceOrderAheadTime = this.coerceDateOrStringToDate(dateTime);
    let stringLeading: string;
    if (isToday(parsedInvoiceOrderAheadTime)) {
      stringLeading = todayText;
    } else if (isTomorrow(parsedInvoiceOrderAheadTime)) {
      stringLeading = 'Tomorrow';
    } else {
      stringLeading = this.getReadableDate(parsedInvoiceOrderAheadTime);
    }

    if (stringLeading) {
      return `${stringLeading} - ${this.getReadableTime(dateTime)}`;
    }

    return this.getReadableTime(dateTime);
  }

  private coerceDateOrStringToDate(dateOrISOString: Date | string): Date {
    return isDate(dateOrISOString)
      ? (dateOrISOString as Date)
      : parseISO(dateOrISOString as string);
  }
}
