import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Injectable,
  Injector
} from '@angular/core';
import { V2ToastType } from '../models/v2-toast-type.model';
import { V2Toast } from '../core/v2-toast/v2-toast.component';
import { V2ToastSuccess } from '../core/v2-toast/v2-toast-success/v2-toast-success.component';
import { V2ToastInfo } from '../core/v2-toast/v2-toast-info/v2-toast-info.component';
import { V2ToastError } from '../core/v2-toast/v2-toast-error/v2-toast-error.component';
import { V2ToastRef } from '../helpers/v2-toast-ref';
import { race, Subject, timer } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';
import { RxUtil } from 'utility/rx.util';
import { nil } from '../helpers/nil.helper';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private readonly autoDismissTimeMS = 3500;
  private readonly toastClosed$ = new Subject<void>();
  private targetPageContainer?: HTMLElement;
  private currentToastRef?: V2ToastRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef
  ) {}

  setCurrentPageElement(el: HTMLElement) {
    this.targetPageContainer = el;
  }

  async showSuccessToast(
    primaryText: string,
    secondaryText: string
  ): Promise<void> {
    await this.showToast(V2ToastType.Success, primaryText, secondaryText);
  }

  async showInfoToast(text: string): Promise<void> {
    await this.showToast(V2ToastType.Info, text);
  }

  async showErrorToast(text: string): Promise<void> {
    await this.showToast(V2ToastType.Error, text);
  }

  private async showToast(
    type: V2ToastType,
    primaryText: string,
    secondaryText?: string
  ): Promise<void> {
    // TODO better visual support for unrestrained series of calls
    if (this.currentToastRef) {
      this.currentToastRef.close();
      await RxUtil.takeOne(this.toastClosed$);
    }

    this.currentToastRef = new V2ToastRef(primaryText, secondaryText);
    const toastComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(V2Toast)
      .create(
        Injector.create({
          providers: [
            {
              provide: V2ToastRef,
              useValue: this.currentToastRef
            }
          ]
        })
      );

    const footerResizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0 && entries[0].borderBoxSize.length > 0) {
        this.currentToastRef?.bottomPageOffset$.next(
          entries[0].borderBoxSize[0].blockSize
        );
      }
    });

    // TODO support custom times
    const s = race(
      this.currentToastRef.onClose$,
      timer(this.autoDismissTimeMS)
    ).subscribe(async () => {
      await toastComponentRef.instance.slideOut();
      this.applicationRef.detachView(toastComponentRef.hostView);
      toastComponentRef.destroy();
      footerResizeObserver.disconnect();
      this.currentToastRef = undefined;
      this.toastClosed$.next();
      s.unsubscribe();
    });

    if (type === V2ToastType.Success) {
      toastComponentRef.instance.childComponentType = V2ToastSuccess;
    } else if (type === V2ToastType.Info) {
      toastComponentRef.instance.childComponentType = V2ToastInfo;
    } else if (type === V2ToastType.Error) {
      toastComponentRef.instance.childComponentType = V2ToastError;
    }

    this.applicationRef.attachView(toastComponentRef.hostView);

    // check if there is a page footer and determine its height
    // add class 'page-footer' to custom footers that don't rely on prefab components
    // TODO support other types of footers, including future std footer
    const pageFooter = this.targetPageContainer?.querySelector(
      'ion-footer, .page-footer'
    ) as HTMLElement | nil;
    if (pageFooter && pageFooter.style?.display !== 'none') {
      this.currentToastRef.bottomPageOffset$.next(pageFooter.offsetHeight);
      footerResizeObserver.observe(pageFooter);
    }

    this.targetPageContainer?.appendChild(
      (toastComponentRef.hostView as EmbeddedViewRef<unknown>)
        .rootNodes[0] as HTMLElement
    );
  }
}
