/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  Directive,
  DoCheck,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';
import { V2FormFieldControl } from '../helpers/v2-form-field-control';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import {
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm
} from '@angular/forms';
import { V2InputErrorStateMatcher } from '../helpers/v2-input-error-state-matcher';

@Directive({
  selector: `input[v2Input]`,
  exportAs: 'v2Input',
  host: {
    class: 'v2-input-element',
    '[attr.id]': 'id',
    '[disabled]': 'disabled',
    '[required]': 'required'
  },
  providers: [{ provide: V2FormFieldControl, useExisting: V2InputDirective }]
})
export class V2InputDirective
  implements V2FormFieldControl<unknown>, OnChanges, DoCheck, OnDestroy
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() private parentForm: NgForm,
    @Optional() private parentFormGroup: FormGroupDirective,
    private defaultErrorStateMatcher: V2InputErrorStateMatcher
  ) {
    // force setter to be called in case id was not specified
    this.id = this.id;
  }

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  private uid = `v2-input-${uuid()}`;

  errorState: boolean = false;

  @Input()
  get value(): unknown {
    return this._value;
  }
  set value(value: unknown) {
    if (value !== this.value) {
      this._value = value;
      this.stateChanges.next();
    }
  }
  private _value: unknown;

  readonly stateChanges: Subject<void> = new Subject<void>();

  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this.uid;
  }
  protected _id: string = '';

  focused: boolean = false;

  // TODO move this into `host` once Ivy is turned on by default.
  @HostListener('focus', ['true'])
  @HostListener('blur', ['false'])
  onFocusChange(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = this.coerceBooleanProperty(value);
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = this.coerceBooleanProperty(value);

    // browsers may not fire the blur event if the input is disabled too quickly
    // reset from here to ensure that the element doesn't become stuck
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  private _disabled = false;

  @Input() errorStateMatcher?: V2InputErrorStateMatcher;

  private coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== 'false';
  }

  private updateErrorState() {
    const oldState = this.errorState;
    const parent = this.parentFormGroup || this.parentForm;
    const matcher = this.errorStateMatcher || this.defaultErrorStateMatcher;
    const control = this.ngControl
      ? (this.ngControl.control as FormControl)
      : null;
    const newState = matcher.isErrorState(control, parent);

    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }
}
