import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

// TODO replace ionic imp with @material/ripple
// TODO support on/off ripple toggle with input

/**
 * Apply ripple effect on an element.
 * Use [v2RippleCondtional]="condition" to apply ripple based on a condition.
 */
@Directive({
  selector: '[v2Ripple],[v2RippleConditional]'
})
export class V2RippleDirective implements OnInit {
  constructor(
    private hostElement: ElementRef<HTMLElement>,
    private renderer2: Renderer2
  ) {}

  @Input() v2RippleConditional?: boolean = true;

  ngOnInit(): void {
    if (!this.v2RippleConditional) return;

    const el = this.hostElement.nativeElement;
    const computedStyle = getComputedStyle(el);

    // default ripple elements to have pointer cursor
    // TODO enable configuring this globally as well as at directive level
    if (computedStyle.cursor === 'auto') {
      this.renderer2.setStyle(el, 'cursor', 'pointer');
    }

    // ionic recommends relative position but other non-static positions work as well
    if (computedStyle.position === 'static') {
      this.renderer2.setStyle(el, 'position', 'relative');
    }

    // safari ripple fix
    // if the user has already set the transform prop they will have to add this themselves
    if (computedStyle.transform === 'none') {
      this.renderer2.setStyle(el, 'transform', 'translate3d(0, 0, 0)');
    }

    this.renderer2.setStyle(el, 'overflow', 'hidden');
    this.renderer2.addClass(el, 'ion-activatable');
    this.renderer2.appendChild(
      el,
      this.renderer2.createElement('ion-ripple-effect')
    );
  }
}
