import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  StaticProvider,
  Type
} from '@angular/core';
import {
  V2BottomSheetRef,
  V2ScrollableContentBottomSheetRef
} from '../helpers/v2-bottom-sheet-ref';
import { V2BottomSheet } from '../core/v2-bottom-sheet/v2-bottom-sheet.component';
import { V2ScrollableBottomSheetVariationHeader } from '../core/v2-scrollable-bottom-sheet-variation-header/v2-scrollable-bottom-sheet-variation-header.component';
import { V2BottomSheetContainer } from '../helpers/v2-bottom-sheet-container';
import { V2ScrollableBottomSheetVariationFooter } from '../core/v2-scrollable-bottom-sheet-variation-footer/v2-scrollable-bottom-sheet-variation-footer.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { unawaited } from '../../utility/unawaited';
import { race } from 'rxjs';
import { filter } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { RxUtil } from 'utility/rx.util';

@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {
  private readonly bottomSheetContainerId = 'bottom-sheet-container';
  private readonly queryParamName = 'bs';
  private readonly openBottomSheets = new Map<string, V2BottomSheetRef<any>>();

  // Applies to V2BottomSheet and V2ScrollableBottomSheetVariationHeader
  readonly componentMaxHeightPixelDifferenceToWindow = 50;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  // Show the bottom sheet component with a custom component injected within.
  // Type T is the return type for the onClosed$ stream.
  showBottomSheet<T>(
    component: Type<unknown>,
    closeOnBackdropClick: boolean = true
  ): V2BottomSheetRef<T> {
    return this.initializeBottomSheet<T, unknown, V2BottomSheet>(
      component,
      V2BottomSheet,
      closeOnBackdropClick,
      new V2ScrollableContentBottomSheetRef<T>(closeOnBackdropClick)
    )[1];
  }

  // Show the scrollable bottom sheet (header variation) component with a custom component injected within.
  // Type T is the return type for the onClosed$ stream.
  showScrollableBottomSheetVariationHeader<T>(
    component: Type<unknown>,
    titleText?: string,
    descriptionText?: string,
    closeOnBackdropClick: boolean = true
  ): V2BottomSheetRef<T> {
    const [bottomSheetComponentRef, bottomSheetRef] =
      this.initializeBottomSheet<
        T,
        unknown,
        V2ScrollableBottomSheetVariationHeader
      >(
        component,
        V2ScrollableBottomSheetVariationHeader,
        closeOnBackdropClick
      );

    bottomSheetComponentRef.instance.titleText = titleText;
    bottomSheetComponentRef.instance.descriptionText = descriptionText;

    return bottomSheetRef;
  }

  // Show the scrollable bottom sheet (footer variation) component with
  // custom content & footer components injected within.
  // Type T is the return type for the onClosed$ stream.
  showScrollableBottomSheetVariationFooter<T>(
    contentComponent: Type<unknown>,
    footerComponent: Type<unknown>,
    closeOnBackdropClick: boolean = true
  ): V2BottomSheetRef<T> {
    const [bottomSheetComponentRef, bottomSheetRef] =
      this.initializeBottomSheet<
        T,
        unknown,
        V2ScrollableBottomSheetVariationFooter
      >(
        contentComponent,
        V2ScrollableBottomSheetVariationFooter,
        closeOnBackdropClick
      );

    bottomSheetComponentRef.instance.footerComponentType = footerComponent;

    return bottomSheetRef;
  }

  closeAllBottomSheets() {
    this.openBottomSheets.forEach((sheet) => sheet.close());
  }

  private initializeBottomSheet<T, S, U extends V2BottomSheetContainer<S>>(
    component: Type<S>,
    containerComponent: Type<U>,
    closeOnBackdropClick: boolean,
    bottomSheetRef: V2BottomSheetRef<T> = new V2BottomSheetRef<T>(
      closeOnBackdropClick
    )
  ): [ComponentRef<U>, V2BottomSheetRef<T>] {
    let providers: StaticProvider[] = [
      { provide: V2BottomSheetRef, useValue: bottomSheetRef }
    ];
    if (bottomSheetRef instanceof V2ScrollableContentBottomSheetRef) {
      providers.push({
        provide: V2ScrollableContentBottomSheetRef,
        useValue: bottomSheetRef
      });
    }
    const bottomSheetComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(containerComponent)
      .create(Injector.create({ providers }));
    bottomSheetComponentRef.instance.childComponentType = component;

    unawaited(
      (async () => {
        const sheetId = uuid();
        this.openBottomSheets.set(sheetId, bottomSheetRef);

        // navigate to our temp route to handle back button presses
        await this.removeCustomQueryFromUrl();
        await this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { [this.queryParamName]: sheetId },
          queryParamsHandling: 'merge'
        });

        const s = race([
          bottomSheetRef.onClose$,
          this.router.events.pipe(
            filter(
              (e) =>
                e instanceof NavigationStart &&
                e.navigationTrigger === 'popstate'
            )
          )
        ]).subscribe(async () => {
          s.unsubscribe();
          this.openBottomSheets.delete(sheetId);
          setTimeout(() => this.removeCustomQueryFromUrl(sheetId));
          await bottomSheetComponentRef.instance.slideOut();
          this.applicationRef.detachView(bottomSheetComponentRef.hostView);
          bottomSheetComponentRef.destroy();
        });
      })()
    );

    this.applicationRef.attachView(bottomSheetComponentRef.hostView);

    const container =
      document.getElementById(this.bottomSheetContainerId) ||
      document.createElement('div');
    container.id = this.bottomSheetContainerId;
    container.appendChild(
      (bottomSheetComponentRef.hostView as EmbeddedViewRef<unknown>)
        .rootNodes[0] as HTMLElement
    );
    document.body.appendChild(container);

    return [bottomSheetComponentRef, bottomSheetRef];
  }

  private async removeCustomQueryFromUrl(sheetId?: string): Promise<void> {
    let currentQueryParams = Object.assign(
      {},
      await RxUtil.takeOne(this.activatedRoute.queryParams)
    );
    if (
      currentQueryParams[this.queryParamName] &&
      (!sheetId || sheetId === currentQueryParams[this.queryParamName])
    ) {
      delete currentQueryParams[this.queryParamName];

      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: currentQueryParams,
        replaceUrl: true
      });
    }
  }
}
