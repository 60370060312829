import { ReadyHttpClient } from './ready-http-client';
import { Injectable } from '@angular/core';
const host = `/graphql`;

@Injectable({
  providedIn: 'root'
})
export class GraphQLApi {
  constructor(private http: ReadyHttpClient) {}

  post(query: string, variables?: any): Promise<any> {
    return this.http.post<any>(host, {
      query,
      variables
    });
  }

  postKeepalive<T>(query: string, variables?: unknown): Promise<T> {
    return this.http.postKeepalive<T>(host, {
      query,
      variables
    });
  }
}
