import { Routes } from '@angular/router';

// recursively mark the data object within angular routes with `preload: true`
// this will not override routes with `preload: false`
export function markRoutesAsPreloadable(routes: Routes) {
  routes.forEach((r) => {
    if (r.data && r.data.preload !== false) {
      r.data.preload = true;
    }

    if (r.children) {
      markRoutesAsPreloadable(r.children);
    }
  });
}
