import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface I18nPayBundle {
  check: string;
  seat: string;
  item: string;
  yourCheck: string;
  yourOrder: string;
  addedByServer: string;
  guest: string;
}

export interface I18nNoCheckBundle {
  withOrderingTitle: string;
  withOrderingBody: string;
  withoutOrderingTitle: string;
  withoutOrderingBody: string;
}

export interface I18nPayFooterBundle {
  inTotal: string;
  guestIsPaying: string;
  payRemainder: string;
  payCheck: string;
  selected: string;
  remaining: string;
  paySelected: string;
  payAllChecks: string;
  checkout: string;
}

export interface I18nTipSelectorBundle {
  autoGratuity: string;
  autoGratuityPretax: string;
  pretax: string;
  fallback: string;
}

export interface I18nPaymentConfirmationEmailBundle {
  newsletterPrompt: string;
  newsletterSuccess: string;
}

export interface I18nPaymentConfirmationBundle {
  unpaidChecksTitleOne: string;
  unpaidChecksTitleTwo: string;
  unpaidChecksBodyOne: string;
  unpaidChecksBodyTwo: string;
}

export interface I18nMenuItemsUnavailableBundle {
  alcoholOrderingDisabled: string;
  currentlyUnavailable: string;
  soldOut: string;
}

export interface I18nMenuModifierBundle {
  option: string;
  chooseYourOptions: string;
  choose: string;
  chooseUpTo: string;
  chooseAtLeast: string;
  andUpTo: string;
}

export type I18nPluralizationBundle = Record<
  string,
  I18nPluralizationBundleRecordValue
>;

export interface I18nPluralizationBundleRecordValue {
  Singular: string;
  Plural: string;
}

@Injectable({
  providedIn: 'root'
})
export class I18nStore {
  constructor(private translateService: TranslateService) {}

  readonly payBundle$: Observable<I18nPayBundle> = combineLatest([
    this.translateService.stream('Pay.Check'),
    this.translateService.stream('Pay.Seat'),
    this.translateService.stream('Pay.Item'),
    this.translateService.stream('Pay.YourCheck'),
    this.translateService.stream('Pay.YourOrder'),
    this.translateService.stream('Pay.AddedByServer'),
    this.translateService.stream('Pay.Guest')
  ]).pipe(
    map(([check, seat, item, yourCheck, yourOrder, addedByServer, guest]) => ({
      check,
      seat,
      item,
      yourCheck,
      yourOrder,
      addedByServer,
      guest
    }))
  );

  readonly noCheckAvailableBundle$: Observable<I18nNoCheckBundle> =
    combineLatest([
      this.translateService.stream('Pay.NoCheckAvailable.WithOrdering.Title'),
      this.translateService.stream('Pay.NoCheckAvailable.WithOrdering.Body'),
      this.translateService.stream(
        'Pay.NoCheckAvailable.WithoutOrdering.Title'
      ),
      this.translateService.stream('Pay.NoCheckAvailable.WithoutOrdering.Body')
    ]).pipe(
      map(
        ([
          withOrderingTitle,
          withOrderingBody,
          withoutOrderingTitle,
          withoutOrderingBody
        ]) => ({
          withOrderingTitle,
          withOrderingBody,
          withoutOrderingTitle,
          withoutOrderingBody
        })
      )
    );

  readonly payFooterBundle$: Observable<I18nPayFooterBundle> = combineLatest([
    this.translateService.stream('Pay.Footer.InTotal'),
    this.translateService.stream('Pay.Footer.GuestIsPaying'),
    this.translateService.stream('Pay.Footer.PayRemainder'),
    this.translateService.stream('Pay.Footer.PayCheck'),
    this.translateService.stream('Pay.Footer.Selected'),
    this.translateService.stream('Pay.Footer.Remaining'),
    this.translateService.stream('Pay.Footer.PaySelected'),
    this.translateService.stream('Pay.Footer.PayAllChecks'),
    this.translateService.stream('Pay.Footer.Checkout')
  ]).pipe(
    map(
      ([
        inTotal,
        guestIsPaying,
        payRemainder,
        payCheck,
        selected,
        remaining,
        paySelected,
        payAllChecks,
        checkout
      ]) => ({
        inTotal,
        guestIsPaying,
        payRemainder,
        payCheck,
        selected,
        remaining,
        paySelected,
        payAllChecks,
        checkout
      })
    )
  );

  readonly tipSelectorBundle$: Observable<I18nTipSelectorBundle> =
    combineLatest([
      this.translateService.stream('YourBill.TipSelector.Presets.AutoGratuity'),
      this.translateService.stream(
        'YourBill.TipSelector.Presets.AutoGratuityPretax'
      ),
      this.translateService.stream('YourBill.TipSelector.Presets.Pretax'),
      this.translateService.stream('YourBill.TipSelector.Presets.Fallback')
    ]).pipe(
      map(([autoGratuity, autoGratuityPretax, pretax, fallback]) => ({
        autoGratuity,
        autoGratuityPretax,
        pretax,
        fallback
      }))
    );

  readonly paymentConfirmationEmailBundle$: Observable<I18nPaymentConfirmationEmailBundle> =
    combineLatest([
      this.translateService.stream(
        'PaymentConfirmation.SendEmail.NewsletterPrompt'
      ),
      this.translateService.stream(
        'PaymentConfirmation.SendEmail.NewsletterSuccess'
      )
    ]).pipe(
      map(([newsletterPrompt, newsletterSuccess]) => ({
        newsletterPrompt,
        newsletterSuccess
      }))
    );

  readonly paymentConfirmationBundle$: Observable<I18nPaymentConfirmationBundle> =
    combineLatest([
      this.translateService.stream('PaymentConfirmation.UnpaidChecks.TitleOne'),
      this.translateService.stream('PaymentConfirmation.UnpaidChecks.TitleTwo'),
      this.translateService.stream('PaymentConfirmation.UnpaidChecks.BodyOne'),
      this.translateService.stream('PaymentConfirmation.UnpaidChecks.BodyTwo')
    ]).pipe(
      map(
        ([
          unpaidChecksTitleOne,
          unpaidChecksTitleTwo,
          unpaidChecksBodyOne,
          unpaidChecksBodyTwo
        ]) => ({
          unpaidChecksTitleOne,
          unpaidChecksTitleTwo,
          unpaidChecksBodyOne,
          unpaidChecksBodyTwo
        })
      )
    );

  readonly menuItemsUnavailableBundle$: Observable<I18nMenuItemsUnavailableBundle> =
    combineLatest([
      this.translateService.stream('Menu.Items.AlcoholOrderingDisabled'),
      this.translateService.stream('Menu.Items.CurrentlyUnavailable'),
      this.translateService.stream('Menu.Items.SoldOut')
    ]).pipe(
      map(([alcoholOrderingDisabled, currentlyUnavailable, soldOut]) => ({
        alcoholOrderingDisabled,
        currentlyUnavailable,
        soldOut
      }))
    );

  readonly menuModifierBundle$: Observable<I18nMenuModifierBundle> =
    combineLatest([
      this.translateService.stream('Menu.Modifiers.Option'),
      this.translateService.stream('Menu.Modifiers.ChooseYourOptions'),
      this.translateService.stream('Menu.Modifiers.Choose'),
      this.translateService.stream('Menu.Modifiers.ChooseUpTo'),
      this.translateService.stream('Menu.Modifiers.ChooseAtLeast'),
      this.translateService.stream('Menu.Modifiers.AndUpTo')
    ]).pipe(
      map(
        ([
          option,
          chooseYourOptions,
          choose,
          chooseUpTo,
          chooseAtLeast,
          andUpTo
        ]) => ({
          option,
          chooseYourOptions,
          choose,
          chooseUpTo,
          chooseAtLeast,
          andUpTo
        })
      )
    );

  readonly pluralizationBundle$: Observable<I18nPluralizationBundle> =
    this.translateService.stream('Pluralization');
}
