import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationStore } from 'src/app/stores/location.store';
import {
  IAppliedOffer,
  ILoyaltyMilestone,
  ILoyaltyReward,
  LoyaltyMode,
  LoyaltyProgramType,
  LoyaltyVendor
} from '../pages/v2-order-pay/models/loyalty.model';
import { ChecksStore } from './checks.store';
import { ILocation } from '../models';
import { LoyaltyCompanyConfigUIModel } from '../ui-models/loyaltyCompanyConfig.ui-model';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyStore {
  readonly bankCurrencyDiscountRewardId = 'BankCurrencyDiscount';

  constructor(
    private locationStore: LocationStore,
    private checksStore: ChecksStore,
    private router: Router
  ) {}

  readonly loyaltyProvider$: Observable<LoyaltyVendor | null> =
    this.locationStore.currentLocation$.pipe(
      map(
        (currentLocation) => currentLocation?.loyaltyOptions?.provider ?? null
      )
    );

  readonly isBypass$: Observable<boolean> = this.loyaltyProvider$.pipe(
    map((loyaltyProvider) => loyaltyProvider === LoyaltyVendor.Bypass)
  );

  private readonly _points$ = new BehaviorSubject<number | null>(null);
  readonly points$ = this._points$.pipe(map((points) => points ?? 0));

  get points(): number | null {
    return this._points$.getValue();
  }

  set points(value: number | null) {
    this._points$.next(value);
  }

  private readonly _offers$ = new BehaviorSubject<ILoyaltyReward[]>([]);
  readonly offers$ = this._offers$.asObservable();

  get offers(): ILoyaltyReward[] {
    return this._offers$.getValue();
  }

  set offers(value: ILoyaltyReward[]) {
    this._offers$.next(value);
  }

  private readonly _programType$ =
    new BehaviorSubject<LoyaltyProgramType | null>(null);
  readonly programType$ = this._programType$.asObservable();

  get programType(): LoyaltyProgramType | null {
    return this._programType$.getValue();
  }

  set programType(value: LoyaltyProgramType | null) {
    this._programType$.next(value);
  }

  private readonly _upcomingRewards = new BehaviorSubject<ILoyaltyReward[]>([]);
  readonly upcomingRewards$ = this._upcomingRewards.asObservable();

  get upcomingRewards(): ILoyaltyReward[] {
    return this._upcomingRewards.getValue();
  }

  set upcomingRewards(value: ILoyaltyReward[]) {
    this._upcomingRewards.next(value);
  }

  private readonly _bankedRewards = new BehaviorSubject<number | undefined>(
    undefined
  );
  readonly bankedRewards$ = this._bankedRewards.asObservable();

  /** Value for credit reward for Punchh's currency loyalty program IN CENTS */
  get bankedRewards(): number | undefined {
    return this._bankedRewards.getValue();
  }

  set bankedRewards(value: number | undefined) {
    this._bankedRewards.next(value);
  }

  readonly userHasLoyaltyAccount$: Observable<boolean> = this.programType$.pipe(
    map((programType) => !!programType)
  );

  private readonly _milestone$ = new BehaviorSubject<ILoyaltyMilestone | null>(
    null
  );
  readonly milestone$ = this._milestone$.asObservable();

  get milestone(): ILoyaltyMilestone | null {
    return this._milestone$.getValue();
  }

  set milestone(value: ILoyaltyMilestone | null) {
    this._milestone$.next(value);
  }

  private readonly _selectedOfferId$ = new BehaviorSubject<string | null>(null);
  readonly selectedOfferId$ = this._selectedOfferId$.asObservable();

  get selectedOfferId(): string | null {
    return this._selectedOfferId$.getValue();
  }

  set selectedOfferId(value: string | null) {
    this._selectedOfferId$.next(value);
  }

  private readonly _appliedOffer$ = new BehaviorSubject<IAppliedOffer | null>(
    null
  );
  readonly appliedOffer$ = this._appliedOffer$.asObservable();

  get appliedOffer(): IAppliedOffer | null {
    return this._appliedOffer$.getValue();
  }

  set appliedOffer(value: IAppliedOffer | null) {
    this._appliedOffer$.next(value);
  }

  private readonly _mode$ = new BehaviorSubject<LoyaltyMode | null>(null);
  readonly mode$ = this._mode$.asObservable();

  get mode(): LoyaltyMode | null {
    return this._mode$.getValue();
  }

  set mode(value: LoyaltyMode | null) {
    this._mode$.next(value);
  }

  private readonly _isLoading$ = new BehaviorSubject(false);
  readonly isLoading$ = this._isLoading$.asObservable();

  get isLoading(): boolean {
    return this._isLoading$.getValue();
  }

  set isLoading(value: boolean) {
    this._isLoading$.next(value);
  }

  readonly isLoyaltyEnabled$: Observable<boolean> =
    this.locationStore.currentLocation$.pipe(
      map(
        (currentLocation) =>
          !!currentLocation.loyaltyOptions?.provider &&
          Object.values(LoyaltyVendor).includes(
            currentLocation.loyaltyOptions.provider
          )
      )
    );

  readonly isLoyaltyEnabledInOrderFlow$: Observable<boolean> = combineLatest([
    this.isLoyaltyEnabled$,
    this.locationStore.currentLocation$
  ]).pipe(
    map(
      ([isLoyaltyEnabled, currentLocation]) =>
        isLoyaltyEnabled && !currentLocation.orderDoesNotRequireImmediatePayment
    )
  );

  readonly isLoyaltyEnabledInPayFlow$: Observable<boolean> =
    this.isLoyaltyEnabled$;

  readonly offersReadonly$: Observable<boolean> = combineLatest([
    this.locationStore.isMenuReadonly$,
    this.mode$,
    this.checksStore.unclaimedUnpaidChecks$
  ]).pipe(
    map(([isMenuReadonly, mode, unclaimedUnpaidChecks]) => {
      const path = this.router.url.split('?')[0].split('#')[0];

      if (path.includes('/v2/pay/')) {
        return isMenuReadonly && unclaimedUnpaidChecks.length === 0;
      }

      return (isMenuReadonly && mode !== LoyaltyMode.Checkout) || false;
    })
  );

  readonly createAccountSubmitted = new Subject<void>();

  setAppliedOfferId(): void {
    if (this.selectedOfferId) {
      this.appliedOffer = {
        id: this.selectedOfferId
      };
    } else {
      this.removeOffer();
    }
  }

  setAppliedOfferIsValid(isValid?: boolean): void {
    if (this.appliedOffer) {
      this.appliedOffer = {
        ...this.appliedOffer,
        isValid,
        isInvalid: !isValid
      };
    }
  }

  removeOffer(): void {
    this.selectedOfferId = null;
    this.appliedOffer = null;
  }

  clearAccountData() {
    this.points = null;
    this.milestone = null;
    this.offers = [];
    this.programType = null;
  }

  readonly companyLoyaltyConfig$: Observable<LoyaltyCompanyConfigUIModel> =
    this.locationStore.currentLocation$.pipe(
      map((location: ILocation): LoyaltyCompanyConfigUIModel => {
        const companyLoyaltyConfig = location.companyLoyaltyConfig.options;

        return {
          firstNameRequired: companyLoyaltyConfig?.firstNameRequired ?? true,
          lastNameRequired: companyLoyaltyConfig?.lastNameRequired ?? true,
          birthdayRequired: companyLoyaltyConfig?.birthdayRequired ?? true,
          zipPostalCodeRequired: companyLoyaltyConfig?.zipCodeRequired ?? true,
          phoneNumberRequired: companyLoyaltyConfig?.phoneRequired ?? true,
          termsAndConditionsRequired:
            companyLoyaltyConfig?.termsAndConditionsRequired ?? true,
          termsAndConditionsUrl: companyLoyaltyConfig?.termsAndConditionsUrl
        };
      })
    );

  readonly isPunchhPointsBankingProgram$ = combineLatest([
    this.loyaltyProvider$,
    this.programType$
  ]).pipe(
    map(([loyaltyProvider, loyaltyProgramType]) => {
      return (
        loyaltyProvider === 'punchh' && loyaltyProgramType === 'pointsbanking'
      );
    })
  );
}
