import { PipesModule } from '../pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CheckItemsComponent } from './check-items/check-items';
import { ProcessOverlayComponent } from './process-overlay/process-overlay';
import { OrderHistoryPreviewComponent } from './order-history-preview/order-history-preview';
import { TotalsComponent } from './totals/totals';
import { ReadyCheckboxComponent } from './ready-checkbox/ready-checkbox';
import { CardDetailsComponent } from './card-details/card-details';
import { ReceiptEmailFormComponent } from './receipt-email-form/receipt-email-form';
import { AccountMenuIconComponent } from './account-menu-icon/account-menu-icon';
import { BadgeComponent } from './badge/badge';
import { ItemProgressComponent } from './item-progress/item-progress';
import { ReadyLogoComponent } from './ready-logo/ready-logo';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DividerComponent } from './divider.component';
import { V2ComponentsModule } from '../v2-components/v2-components.module';
import { OrderPageHeader } from './order-page-header/order-page-header.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { FullScreenLoading } from './full-screen-loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { UnbrandedHeader } from './unbranded-header/unbranded-header';
import { ChatAssistantIconComponent } from './chat-assistant-icon/chat-assistant-icon';
import { ChatAssistantDialogue } from './chat-assistant-dialogue/chat-assistant-dialogue.component';

@NgModule({
  declarations: [
    CheckItemsComponent,
    ProcessOverlayComponent,
    OrderHistoryPreviewComponent,
    TotalsComponent,
    ReadyCheckboxComponent,
    CardDetailsComponent,
    ReceiptEmailFormComponent,
    AccountMenuIconComponent,
    BadgeComponent,
    ItemProgressComponent,
    ReadyLogoComponent,
    DividerComponent,
    OrderPageHeader,
    FullScreenLoading,
    UnbrandedHeader,
    ChatAssistantIconComponent,
    ChatAssistantDialogue
  ],
  imports: [
    IonicModule,
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    V2ComponentsModule,
    NgxCurrencyModule,
    TranslateModule.forChild({ extend: true })
  ],
  exports: [
    CommonModule,
    FormsModule,
    CheckItemsComponent,
    ProcessOverlayComponent,
    PipesModule,
    OrderHistoryPreviewComponent,
    TotalsComponent,
    ReadyCheckboxComponent,
    CardDetailsComponent,
    ReceiptEmailFormComponent,
    AccountMenuIconComponent,
    BadgeComponent,
    ItemProgressComponent,
    ReadyLogoComponent,
    DividerComponent,
    OrderPageHeader,
    FullScreenLoading,
    UnbrandedHeader,
    ChatAssistantIconComponent,
    ChatAssistantDialogue
  ]
})
export class ComponentsModule {}
