import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatState, ChatMessage } from '../models/chat-assistant.model';

@Injectable({
  providedIn: 'root'
})
export class ChatAssistantStore {
  private readonly _chatState$ = new BehaviorSubject<ChatState>({
    chatSession: {
      externalId: '',
      messages: []
    },
    loading: false,
    sendingMessage: false,
    error: null,
    chatModalIsOpen: false
  });

  readonly chatState$: Observable<ChatState> = this._chatState$.asObservable();

  get chatState(): ChatState {
    return this._chatState$.getValue();
  }

  get loading$(): Observable<boolean> {
    return this._chatState$.pipe(map((state) => state.loading));
  }

  get sendingMessage$(): Observable<boolean> {
    return this._chatState$.pipe(map((state) => state.sendingMessage));
  }

  get chatModalIsOpen$(): Observable<boolean> {
    return this._chatState$.pipe(map((state) => state.chatModalIsOpen));
  }

  get chatHasMessages$(): Observable<boolean> {
    return this._chatState$.pipe(
      map((state) => state.chatSession.messages.length > 0)
    );
  }

  get chatError$(): Observable<string | null> {
    return this._chatState$.pipe(map((state) => state.error));
  }

  setChatInfo(externalId: string): void {
    const currentState = this.chatState;
    this._chatState$.next({
      ...currentState,
      chatSession: {
        ...currentState.chatSession,
        externalId
      }
    });
  }

  loadMessages(messages: ChatMessage[]): void {
    const currentState = this.chatState;
    this._chatState$.next({
      ...currentState,
      chatSession: {
        ...currentState.chatSession,
        messages
      }
    });
  }

  addMessage(message: ChatMessage): void {
    const currentState = this.chatState;
    this._chatState$.next({
      ...currentState,
      chatSession: {
        ...currentState.chatSession,
        messages: [...currentState.chatSession.messages, message]
      }
    });
  }

  setLoading(loading: boolean): void {
    const currentState = this.chatState;
    this._chatState$.next({
      ...currentState,
      loading
    });
  }

  setSendingMessage(sendingMessage: boolean): void {
    const currentState = this.chatState;
    this._chatState$.next({
      ...currentState,
      sendingMessage
    });
  }

  setError(error: string | null): void {
    const currentState = this.chatState;
    this._chatState$.next({
      ...currentState,
      error
    });
  }

  toggleChatModal() {
    const currentState = this.chatState;
    this._chatState$.next({
      ...currentState,
      chatModalIsOpen: !currentState.chatModalIsOpen
    });
  }
}
