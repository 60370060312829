import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'order-page-header',
  templateUrl: 'order-page-header.component.html',
  styleUrls: ['order-page-header.component.scss']
})
export class OrderPageHeader {
  @Input() showBackButton: boolean = false;
  @Input() showAccountButton: boolean = false;

  constructor(private navController: NavController) {}

  goToTransactionPage() {
    this.navController.navigateForward('account');
  }

  goBack() {
    this.navController.pop();
  }
}
