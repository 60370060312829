import { Injectable } from '@angular/core';
import { RaygunService } from './raygun.service';
import { AlertMessageType, AlertService } from './alert.service';
import { AnalyticsService } from './analytics.service';
import { hasKey, isError } from 'utility/unknown.util';
import { BottomSheetService } from '../v2-components/services/bottom-sheet.service';
import { SubmitOrderErrorSheet } from '../pages/v2-order-pay/bottom-sheets/submit-order-error-sheet/submit-order-error.sheet';
import { OrderStore } from 'stores/order.store';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor(
    private raygunService: RaygunService,
    private alertService: AlertService,
    private analyticsProvider: AnalyticsService,
    private bottomSheetService: BottomSheetService,
    private orderStore: OrderStore
  ) {}

  async performDefaultErrorHandling(
    e: unknown,
    message?: string,
    loggingData?: object
  ): Promise<void> {
    let d: { message?: string } & object = {};
    if (loggingData) {
      d = loggingData;
    }
    if (message) {
      d.message = message;
    }
    this.raygunService.logToRayGun(e, d);
    this.trackErrorShown();

    const errorMessage: AlertMessageType | null =
      message ??
      (hasKey(e, 'message') && typeof e.message === 'string'
        ? e.message
        : null) ??
      (isError(e) ? e : null);

    if (errorMessage) {
      await this.alertService.showErrorAlert(errorMessage);
    }
  }

  async performSubmitOrderErrorHandling(message: string) {
    this.orderStore.setSubmitOrderErrorMessage(message);
    this.bottomSheetService.showBottomSheet(SubmitOrderErrorSheet);
  }

  trackErrorShown() {
    this.analyticsProvider.log('Show an error popup');
  }
}
