import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { OrderStore } from 'stores/order.store';
import { V2BottomSheetRef } from '../../../../v2-components/helpers/v2-bottom-sheet-ref';

@Component({
  selector: 'submit-order-error-sheet',
  template: `
    <v2-column id="top-column">
      <v2-row alignItems="center">
        <v2-icon
          id="error-icon"
          icon="error-warning"
          color="alert-orange"
        ></v2-icon>
        <v2-body-header-0-text color="black">
          Could not submit your order
        </v2-body-header-0-text>
      </v2-row>

      <v2-body-large-text id="description-text" color="black">
        {{
          this.orderStore.submitOrderErrorMessage ||
            'There was a problem submitting your order.'
        }}
      </v2-body-large-text>
    </v2-column>

    <v2-button color="primary" [outline]="true" (click)="onDismiss()">
      CLOSE
    </v2-button>
  `,
  styles: [
    `
      #top-column {
        padding: 30px 16px 12px;
      }

      #error-icon {
        font-size: 24px;
        margin-right: 10px;
      }

      #description-text {
        margin-top: 12px;
      }

      v2-button {
        margin: 0 8px 8px 8px;
      }
    `
  ]
})
export class SubmitOrderErrorSheet {
  constructor(
    public bottomSheetRef: V2BottomSheetRef,
    public navController: NavController,
    public orderStore: OrderStore
  ) {}

  onDismiss() {
    this.bottomSheetRef.close();
  }
}
