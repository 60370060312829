import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InvoiceStore } from 'stores/invoice.store';
import { CustomTipType } from '../pages/v2-order-pay/models/custom-tip-type.model';
import { LocationStore } from 'stores/location.store';
import { TotalsUiModel } from '../ui-models/totals.ui-model';
import { IItem } from '../models';
import { IDiscount } from '../models/discount';
import { FeaturesStore } from 'stores/features.store';
import { Invoice2Store } from 'stores/invoice2.store';
import { Location2Store } from './location2.store';

@Injectable({
  providedIn: 'root'
})
export class TipStore {
  private readonly tipSelectorDefaults = {
    values: {
      autoGratuity: [0, 2, 5],
      fallback: [15, 18, 20]
    }
  };
  readonly customTipIndex = 3;

  constructor(
    private invoiceStore: InvoiceStore,
    private locationStore: LocationStore,
    private featuresStore: FeaturesStore,
    private invoice2Store: Invoice2Store,
    private location2Store: Location2Store
  ) {}

  private readonly _userSelectedTipIndex$ = new BehaviorSubject<number | null>(
    null
  );

  setUserSelectedTipIndex(i: number) {
    this._userSelectedTipIndex$.next(i);
  }

  // returns first or middle tip option index based on service charge.
  // currently, we only support 3 custom / default tip options.
  private readonly tipPresetIndex$: Observable<0 | 1> =
    this.invoiceStore.hasServiceCharge$.pipe(
      map((hasServiceCharge) => (hasServiceCharge ? 0 : 1))
    );

  readonly selectedTipIndex$: Observable<number> = combineLatest([
    this._userSelectedTipIndex$,
    this.tipPresetIndex$
  ]).pipe(
    map(([userSelectedTipIndex, tipPresetIndex]) =>
      userSelectedTipIndex !== null ? userSelectedTipIndex : tipPresetIndex
    )
  );

  readonly tipPresets$: Observable<number[]> = combineLatest([
    this.invoiceStore.isAutoGratuityEnabled$,
    this.invoiceStore.hasServiceCharge$,
    this.locationStore.currentLocation$
  ]).pipe(
    map(([isAutoGratuityEnabled, hasServiceCharge, location]) => {
      if (isAutoGratuityEnabled || hasServiceCharge) {
        return location.tipPresetsForServiceCharges &&
          location.tipPresetsForServiceCharges.length > 0
          ? location.tipPresetsForServiceCharges
          : this.tipSelectorDefaults.values.autoGratuity;
      }

      return location.tipPresets && location.tipPresets.length > 0
        ? location.tipPresets
        : this.tipSelectorDefaults.values.fallback;
    })
  );

  private readonly _userSelectedCustomTipType$ =
    new BehaviorSubject<CustomTipType | null>(null);

  setUserSelectedCustomTipType(customTipType: CustomTipType) {
    this._userSelectedCustomTipType$.next(customTipType);
  }

  readonly customTipType$: Observable<CustomTipType> = combineLatest([
    this._userSelectedCustomTipType$,
    this.location2Store.currentLocation$
  ]).pipe(
    map(([userSelectedCustomTipType, location]) =>
      userSelectedCustomTipType !== null
        ? userSelectedCustomTipType
        : location.defaultCustomTipType || CustomTipType.percent
    )
  );

  readonly tipBase$: Observable<number> = combineLatest([
    this.featuresStore.isPretaxTipEnabled$,
    this.locationStore.isPreDiscountTipEnabled$,
    this.locationStore.isPreItemDiscountTipEnabled$,
    this.locationStore.isPreNonReadyPaymentsTipEnabled$,
    this.invoiceStore.currentInvoice$,
    this.invoice2Store.invoiceTotals$
  ]).pipe(
    map(
      ([
        isPretax,
        isPreDiscount,
        isPreItemDiscount,
        isPreNonReadyPayments,
        invoice,
        invoiceTotals
      ]) =>
        invoice && invoiceTotals
          ? this.getTipBase(
              isPretax,
              isPreDiscount,
              isPreItemDiscount,
              isPreNonReadyPayments,
              invoiceTotals,
              invoice.items
            )
          : 0
    )
  );

  private getTipBase(
    isPretax: boolean,
    isPreDiscount: boolean,
    isPreItemDiscount: boolean,
    isPreNonReadyPayments: boolean,
    invoiceTotals: TotalsUiModel,
    invoiceItems: IItem[] = []
  ): number {
    let base = isPretax
      ? invoiceTotals.subtotal + invoiceTotals.serviceCharge
      : invoiceTotals.due;
    if (isPreDiscount) base += invoiceTotals.discounts;
    if (isPreItemDiscount) {
      base += invoiceItems.reduce(
        (itemsDiscount: number, item: IItem): number => {
          if (item.discounts && item.discounts.length > 0) {
            return (
              itemsDiscount +
              item.discounts.reduce(
                (itemDiscount: number, discount: IDiscount): number =>
                  itemDiscount + discount.value,
                0
              )
            );
          } else if (item.price < 0) {
            return itemsDiscount - item.price;
          }
          return itemsDiscount;
        },
        0
      );
    }
    if (isPreNonReadyPayments) base += invoiceTotals.otherPayments;
    return base;
  }

  private readonly _customTipDollarValue$ = new BehaviorSubject<number | null>(
    null
  );

  readonly customTipDollarValue$ = this._customTipDollarValue$.pipe(
    map(this.mapPercentAndDollarValues)
  );

  setCustomTipDollarValue(v: number | null) {
    this._customTipDollarValue$.next(v);
  }

  private readonly _customTipPercentValue$ = new BehaviorSubject<number | null>(
    null
  );

  readonly customTipPercentValue$ = this._customTipPercentValue$.pipe(
    map(this.mapPercentAndDollarValues)
  );

  setCustomTipPercentValue(v: number | null) {
    this._customTipPercentValue$.next(v);
  }

  // we use this to remove trailing 0's after the decimal and
  // flip negative values
  private mapPercentAndDollarValues(v: number | null): number | null {
    return v === null ? null : Math.abs(parseFloat(v.toFixed(2)));
  }

  readonly tipAmountCents$: Observable<number> = combineLatest([
    this.selectedTipIndex$,
    this.tipPresets$,
    this.customTipType$,
    this.tipBase$,
    this.customTipDollarValue$,
    this.customTipPercentValue$,
    this.locationStore.currentLocation$
  ]).pipe(
    map(
      ([
        selectedTipIndex,
        tipPresets,
        customTipType,
        tipBase,
        customTipDollarValue,
        customTipPercentValue,
        currentLocation
      ]) => {
        if (currentLocation.tipsDisallowed) {
          return 0;
        }

        return Math.trunc(
          this.calculateTipAmount(
            selectedTipIndex,
            tipPresets,
            customTipType,
            tipBase,
            customTipDollarValue || 0,
            customTipPercentValue || 0
          )
        );
      }
    )
  );

  private calculateTipAmount(
    selectedTipIndex: number,
    tipPresets: number[],
    customTipType: CustomTipType,
    tipBase: number,
    customTipDollarValue: number,
    customTipPercentValue: number
  ): number {
    let tipPercentage = tipPresets[selectedTipIndex];

    if (selectedTipIndex === this.customTipIndex) {
      // custom tip
      if (customTipType === CustomTipType.dollars) {
        return customTipDollarValue * 100;
      } else {
        tipPercentage = customTipPercentValue;
      }
    }

    return (tipBase * tipPercentage) / 100;
  }

  readonly tipAmountDollars$: Observable<number> = this.tipAmountCents$.pipe(
    map((tipAmountCents) => tipAmountCents / 100)
  );
}
