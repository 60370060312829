import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUser } from '../models';
import { filter } from 'rxjs/operators';
import { RxUtil } from 'utility/rx.util';

@Injectable({
  providedIn: 'root'
})
export class UserStore {
  private _currentUser$ = new BehaviorSubject<IUser | null>(null);
  readonly currentUser$ = this._currentUser$.asObservable();

  get currentUser(): IUser | null {
    return this._currentUser$.getValue();
  }

  setCurrentUser(user: IUser) {
    this._currentUser$.next(user);
  }

  patchCurrentUser<K extends keyof IUser>(key: K, value: IUser[K]) {
    let user = this.currentUser;
    if (user) {
      user[key] = value;
      this.setCurrentUser(user);
    }
  }

  private readonly _firebaseUser$ = new BehaviorSubject<User | null>(null);

  readonly firebaseUser$: Observable<User> = this._firebaseUser$.pipe(
    filter(RxUtil.inputIsNotNullOrUndefined)
  );

  get firebaseUser(): User | null {
    return this._firebaseUser$.getValue();
  }

  set firebaseUser(v: User | null) {
    this._firebaseUser$.next(v);
  }
}
