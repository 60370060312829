import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ready-checkbox',
  templateUrl: 'ready-checkbox.html',
  styleUrls: ['ready-checkbox.scss']
})
export class ReadyCheckboxComponent {
  @Input() id: string | undefined;
  @Input() checked: boolean = false;
  // this input will be replaced when the features store is up and running
  @Input() payBySeatItems: boolean | undefined;
  @Input() payBySeatSeats: boolean | undefined;
  @Output() checkedChanged: EventEmitter<any> = new EventEmitter<any>();

  // TODO: plug into the features store and determine if pay by seat is
  // enabled to style the checkbox differently.
}
