import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { OrderPayStore } from 'stores/order-pay.store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BeaconIdGuard implements CanActivateChild {
  constructor(private orderPayStore: OrderPayStore) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return !!this.orderPayStore.beaconId;
  }
}
