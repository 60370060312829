import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { IInvoice } from '../models';
import {
  StandardPaymentMethod,
  MonerisPaymentMethod
} from '../pages/v2-order-pay/models/payment.model';
import { LocationStore } from '../stores/location.store';
import { InvoiceStore } from '../stores/invoice.store';
import { RxUtil } from 'utility/rx.util';
import { Invoice2Store } from 'stores/invoice2.store';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private locationStore: LocationStore,
    private invoiceStore: InvoiceStore,
    private invoice2Store: Invoice2Store
  ) {}

  init() {
    ga('create', environment.GoogleAnalyticsId, 'auto');
    ga('require', 'ecommerce');
  }

  log(eventName: string) {
    ga('send', 'event', 'User Interaction', eventName);
  }

  logTransaction(
    invoice: IInvoice,
    locationName: string,
    subtotal: number,
    tipAmountPaid: number,
    tax: number
  ) {
    ga('ecommerce:addTransaction', {
      id: invoice.id ? invoice.id : invoice._id,
      affiliation: locationName,
      revenue: subtotal,
      shipping: tipAmountPaid,
      tax
    });
    this.logItems(invoice);
    ga('ecommerce:send');
  }

  async trackInitiateCheckout() {
    const invoice = this.invoiceStore.currentInvoice;
    if (invoice) {
      const invoiceTotals = await RxUtil.takeOne(
        this.invoice2Store.invoiceTotals$
      );
    }
  }

  private logItems(check: IInvoice) {
    check.items.map((item) => {
      ga('ecommerce:addItem', {
        id: check.id ? check.id : check._id,
        name: item.name,
        sku: item.id ? item.id : item._id,
        price: item.price / 100,
        quantity: item.quantity
      });
    });
  }

  logSplitSeat() {
    ga('send', 'event', 'User Interaction', 'Ticket split by Seat');
  }

  trackPage(pageName: string) {
    ga('set', 'page', pageName);
    ga('send', 'pageview');
    ga('send', 'event', 'Navigation', 'Page Entered', pageName);
  }

  setUserId(userId: string) {
    ga('set', 'userId', userId);
  }

  setRestaurant(restaurantName: string) {
    ga('set', 'dimension1', restaurantName);
  }

  setPaymentMethodAvailable(
    method: StandardPaymentMethod | MonerisPaymentMethod | string
  ) {
    ga('set', 'dimension2', method);
  }

  setTableNumber(number: string) {
    ga('set', 'dimension3', number);
  }

  setPaymentMethodUsed(method: StandardPaymentMethod | MonerisPaymentMethod) {
    ga('set', 'dimension4', method);
  }

  setTargetId(targetId: string) {
    ga('set', 'dimension5', targetId);
  }

  setCompany(companyName: string) {
    ga('set', 'dimension6', companyName);
  }
}
