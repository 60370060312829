import { Injectable } from '@angular/core';
import { detectOS, OperatingSystem } from 'detect-browser';
import { PlatformStore } from 'stores/platform.store';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private readonly isDesktopMediaQuery = window.matchMedia(
    '(hover: hover) and (pointer: fine)'
  );

  constructor(private platformStore: PlatformStore) {
    this.platformStore.setIsDesktop(this.isDesktopMediaQuery.matches);
    this.isDesktopMediaQuery.addEventListener('change', (e) =>
      this.platformStore.setIsDesktop(e.matches)
    );
  }

  detectOperatingSystem() {
    this.platformStore.setOperatingSystem(detectOS(navigator.userAgent));
  }

  async getScanPermission(): Promise<boolean> {
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    try {
      if (this.platformStore.operatingSystem === 'iOS') {
        await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { exact: 'environment' } }
        });
        return true;
      } else if (this.platformStore.operatingSystem === 'Android OS') {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          if (isFirefox) {
            return true;
          } else {
            await navigator.mediaDevices.getUserMedia({
              video: { facingMode: { exact: 'environment' } }
            });
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
}
