import { Component, Input } from '@angular/core';

@Component({
  selector: 'card-details',
  templateUrl: 'card-details.html',
  styleUrls: ['card-details.scss']
})
export class CardDetailsComponent {
  @Input() card: any;
  @Input() amountPaid: number | undefined;
  @Input() paidDate: Date | undefined;
  @Input() currency: string | undefined;
}
